import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components';
import TableToolbar from '../components/add-user-button/TableToolbar'
import PropTypes from 'prop-types';
import UILoader from "../../../components/ui-loader/UILoader";

import { Styles, ActionBar } from './styles'
import {
  ThemeProvider,
  Table,
  Alert,
  getAlertUniqueId,
  WARNING_ALERT_CONTEXTUAL,
  Container,
  DropdownItem,
  DropdownButton,
  Radio,
  SIZE_SMALL
} from '@mds/mds-reactjs-library';
import { deleteDefaultUser, setLoader } from '../../../store/actions';
import RemoveReportDialog from '../components/delete-user/delete-user'


const DropdownButtonStyled = styled(DropdownButton)(() => ({
  marginLeft: 'auto',
  marginTop: '0.5rem'
}))

const CheckboxDiv = styled.div`
  justify-content: center;
  cursor: pointer;
  flex: 1;
  display: flex;
  margin-top: 10px;
`

const ActionBarStyled = styled(ActionBar)`
  margin-bottom: 0
`

const TeamTable = (props) => {
  const [ischecked, setIsChecked] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [removeDialog, showRemoveDialog]=useState(false)
  const history = props.history;
  const dispatch = useDispatch()
  let rowData = [];
  /* useEffect(() => {
    
  }, []); */

  const RadioButtonChecked = (args) => {
    setIsChecked(true)
    setSelectedRow({...selectedRow, data: args });
  }

  const handleRemoveOk =()=>{
    dispatch(deleteDefaultUser(selectedRow.data.rowData.id, history))
    showRemoveDialog(false)
    dispatch(setLoader());
    setIsChecked(false)
  }

  const editUser = () => {
    props.history.push({
      pathname: `/admin/team/editUser`,
      state: selectedRow.data.rowData
    });
  }

  const CheckboxCellRenderer = (args) => {
    const { value } = args

    return (
      <CheckboxDiv>
        <Radio
          size={SIZE_SMALL}
          onChange={() => RadioButtonChecked(args)}
          checked={value}
          value="small"
          label="Small radio"
          name="RadioButtonSizeExample"
        />
      </CheckboxDiv>
    )
  }

  const column = React.useMemo(
    () => [
      {
        dataKey: 'checked',
        label: '',
        width: 40,
        CellRenderer: CheckboxCellRenderer,
      },
      {dataKey: 'last_name', label: 'Last Name',width: 200},
      {dataKey: 'first_name', label: 'First Name',width: 200},
      { dataKey: 'email', label: 'Email', width: 400},
      { dataKey: 'team_name', label: 'Role', width: 400},
    ],
    []
  )


  if (props.teamData && Array.isArray(props.teamData.defaultTeamInfo)) {
    rowData = props.teamData && props.teamData.defaultTeamInfo.map((row) => {
      return {
        "id": row.id,
        "last_name": row.last_name,
        "first_name": row.first_name,
        "email": row.email,
        "team_name": row.team_name,
      }
    })
  }

  return (
      <UILoader>
            <Styles>
            <ThemeProvider>
          <Container maxWidth="xl">
            <ActionBarStyled>
              <TableToolbar history={history} />
              <DropdownButtonStyled disabled={!ischecked} label="Actions">
                <DropdownItem onClick={() => editUser() }> Edit User</DropdownItem>
                <DropdownItem onClick={() => { showRemoveDialog(true) }}> Remove User</DropdownItem>
              </DropdownButtonStyled>
            </ActionBarStyled>
            {rowData.length > 0 &&
            <Table height={550} dark striped columns={column} rows={rowData.sort(( a, b) => {return a && a.last_name && a.last_name.localeCompare(b.last_name)})} width={1200}/>}
            {rowData.length === 0 &&
            <Alert id={getAlertUniqueId()} showCloseIcon={false} type={WARNING_ALERT_CONTEXTUAL}>
            <div>No entries to display</div>
            </Alert>}
          </Container>

          {removeDialog ?(<RemoveReportDialog  userName={selectedRow} onOk={()=> handleRemoveOk()} onCancel={()=>showRemoveDialog(false)} />):null}

        </ThemeProvider>
      </Styles>
      </UILoader>
  )
}

TeamTable.propTypes = {
  teamData: PropTypes.object,
  history: PropTypes.object
}

TeamTable.defaultProps = {
  teamData: {},
  history: {}
}

const mapStateToProps = (state) => ({
  teamData: state.reducer.teamData,
})


export default connect(mapStateToProps)(TeamTable)