import styled from '@emotion/styled';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types'

import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux'
import SideNav from '../../../components/SideNavBar';
import EditRole from '../../user-management/components/edit-role/EditRole';
import UIAlert from '../../../components/ui-alert/UIAlert';
import { setLoader, getRoleList, getUserRole } from '../../../store/actions';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;


const EditRoleMgmt = (props) => {
  const { logout, role, history} = props;
  const { useEffect } = React;
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
   dispatch(getRoleList(history));
   dispatch(getUserRole(history, state))
   dispatch(setLoader());
  }, []);
  const tenantName = JSON.parse(sessionStorage.getItem('_mid-tenant'))?.displayName
  return (
    <DIV>
    {tenantName.startsWith('Default') && <SideNav logout={logout} role={role} />}
        <Container maxWidth="xl">
            <ThemeProvider>
              <UIAlert />
              <ClientTableDiv>
                <EditRole history={history}></EditRole>
              </ClientTableDiv>
            </ThemeProvider>
          </Container>
      </DIV>
  );
  }

  EditRoleMgmt.propTypes = {
    history: PropTypes.object,
    logout: PropTypes.func,
    role: PropTypes.string
  }
  
  EditRoleMgmt.defaultProps = {
    history: {},
    logout: () => { }, 
    role: ""
  }
  
  const mapStateToProps = (state) => ({
    roleList: state.reducer.roleList,
    userRole: state.reducer.userRole,
    mid: state.mid,
    role: state.reducer.role
  })
  
  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout())
  })
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRoleMgmt))
