import styled from 'styled-components'


const ActivityContainer = styled.div `
    margin: 3rem 2rem 2rem 2rem;
    overflow: scroll;
    height: 86vh;
`

const ActivityBody = styled.div`
 margin-top: 2rem;
 display: flex;
`
const ActivityBodyTypography = styled.div`
margin-left: 1rem;
`
export { ActivityContainer, ActivityBody, ActivityBodyTypography };