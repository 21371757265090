import _intersection from 'lodash/intersection'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getUserFromLocalStorage } from './helpers/UserHelper'

const AuthorizeRoute = ({ roles: roles, component: Component, ...rest }) => {
  const userRoles = []
  userRoles.push(getUserFromLocalStorage())
  const isAuthorized = !!_intersection(roles, userRoles).length
  
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isAuthorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/error', state: { from: props.path } }} />
          )
        }
      />
    </>
  )
}

AuthorizeRoute.propTypes = {
  component: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  path: PropTypes.string.isRequired,
}

export default AuthorizeRoute
