import styled from '@emotion/styled';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types'

import React from 'react'

import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SideNav from '../../components/SideNavBar';
import ClientTable from './client-configuration-table/ClientTable'
import UIAlert from '../../components/ui-alert/UIAlert';
import { getData, setSelectedClient } from '../../store/actions'

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientConfiguration = (props) => {
  const { logout, role, cardData, history} = props;
  const dispatch = useDispatch();
  const handleSelectedClient=(data)=>{
    dispatch(setSelectedClient(data))
  }
  return (
    <DIV id="client-management-container">
        <SideNav logout={logout} role={role} />
        <Container maxWidth="xl">
            <ThemeProvider>
              <UIAlert />
              <ClientTable clientData={cardData} selectClient={handleSelectedClient} history={history}></ClientTable>
            </ThemeProvider>
          </Container>
      </DIV>
  );
  }

  ClientConfiguration.propTypes = {
    logout: PropTypes.func,
    role: PropTypes.string,
    cardData: PropTypes.array,
    history: PropTypes.object
  }
  
  ClientConfiguration.defaultProps = {
    logout: () => { },
    role: "",
    cardData: [],
    history: {}
  }
  
  const mapStateToProps = (state) => ({
    mid: state.mid,
    role: state.reducer.role,
    cardData: state.reducer.cardData
  })
  
  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout()),
    getData: ({history}) => dispatch(getData({history}))
  })
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientConfiguration))
