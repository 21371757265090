import React from 'react'
import styled from 'styled-components'
import Glyph24EAdd from '@mds/mds-icons/icons/svg/glyph-24-e-add.svg';
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types';
import {
    SIZE_SMALL,
    PRIMARY_BUTTON,
    TYPE_OUTLINE,
    Icon,
    Button,
  } from '@mds/mds-reactjs-library';
  import { ADD_DEFAULT_USER_PATH } from '../../../../constants';
 
const ButtonStyled = styled(Button)`
  margin-right: 12px;
  margin-bottom: 12px;
`;

const ToolbarStyled = styled(Toolbar)`
  padding-left: 0;
`;
 
const TableToolbar = (props) => {
  const history = props.history;
  
  return (
    <ToolbarStyled>
        <ButtonStyled id="core-team-adduser-btn" onClick={() => history.push(ADD_DEFAULT_USER_PATH)} size={SIZE_SMALL} appearance={PRIMARY_BUTTON}>
        <Icon size={16} type={TYPE_OUTLINE} src={Glyph24EAdd} style={{ padding: '5px' }} />
        Add User
        </ButtonStyled>
    </ToolbarStyled>
  )
}
 
TableToolbar.propTypes = {
  history: PropTypes.object
}

TableToolbar.defaultProps = {
  history: {}
}

export default TableToolbar
