// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2AILU ._13K0A{color:#fff}", "",{"version":3,"sources":["RemoveReport.css"],"names":[],"mappings":"AAAA,gBACE,UAAa","file":"RemoveReport.css","sourcesContent":[".remove-modal .mds-modal-body {\n  color: #fff; }\n"]}]);
// Exports
exports.locals = {
	"remove-modal": "_2AILU",
	"mds-modal-body": "_13K0A"
};
module.exports = exports;
