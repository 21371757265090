const routerBase = () => {
  const path = new URL(document.baseURI).pathname
  return path ? path : '/'
}
const autoLogin = window.AUTO_LOGIN || process.env.AUTO_LOGIN || 'true'
export default {
  API_SERVER: window.API_SERVER || '/api',
  BASE_PATH: routerBase(),
  AUTO_LOGIN: autoLogin !== 'false',
  ENABLE_COOKIE_AUTH: window.ENABLE_COOKIE_AUTH === 'true',
  MANAGE_USERS_LINK: window.DEPLOY_ENV === 'local'? 'http://localhost:3001/user-management/users/': document.baseURI + 'user-management/users',
  REQUEST_NEW_TENANT_LINK: window.DEPLOY_ENV === 'local'? 'http://localhost:3002/tenant-management/': document.baseURI + 'tenant-management/',
  MANAGE_USERTEAMS_PATH: window.DEPLOY_ENV === 'local'? 'http://localhost:8810/authorization/users': document.baseURI + 'authorization/users',
  DEPLOY_ENV: window.DEPLOY_ENV,
  DEFAULT_TENANT_ID: window.DEFAULT_TENANT_ID
}
