import styled from '@emotion/styled';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types'

import React from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SideNav from '../../../components/SideNavBar';
import AddUserRole from '../components/add-role/AddUserRole';
import UIAlert from '../../../components/ui-alert/UIAlert';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const AddUserRoleManagement = (props) => {
  const { logout, role, history} = props;
  const tenantName = JSON.parse(sessionStorage.getItem('_mid-tenant'))?.displayName
  return (
    <DIV>
    {tenantName.startsWith('Default') && <SideNav logout={logout} role={role} />}
        <Container maxWidth="xl">
            <ThemeProvider>
              <UIAlert />
              <ClientTableDiv>
                <AddUserRole history={history}/>
              </ClientTableDiv>
            </ThemeProvider>
          </Container>
      </DIV>
  );
  }

  AddUserRole.propTypes = {
    history: PropTypes.object,
    logout: PropTypes.func,
    role: PropTypes.string,
  }
  
  AddUserRole.defaultProps = {
    history: {},
    logout: () => { },
    role: ""
  }
  
  const mapStateToProps = (state) => ({
    mid: state.mid,
    role: state.reducer.role
  })
  
  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout())
  })
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserRoleManagement))
