import React from 'react'
import Glyph24EAdd from '@mds/mds-icons/icons/svg/glyph-24-e-add.svg';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import {
  Button,
  PRIMARY_BUTTON,
  SIZE_SMALL,
  Icon,
  TYPE_OUTLINE,
} from '@mds/mds-reactjs-library'
import {ADD_REPORTS_PATH} from '../../../../constants';


const AddReportButton = (props) => {
  const history = props.history;
  const ButtonStyled = styled(Button)`
    margin-right: 12px;
    margin-bottom: 12px;
  `;

  return (
    <div>
        <ButtonStyled id="report-mgmt-add-reports" onClick={() => history.push(ADD_REPORTS_PATH)} size={SIZE_SMALL} appearance={PRIMARY_BUTTON}>
        <Icon size={16} type={TYPE_OUTLINE} src={Glyph24EAdd} style={{ padding: '5px' }} />
        Add Reports
      </ButtonStyled>
    </div>
  )
}

AddReportButton.propTypes = {
  history: PropTypes.object
}

AddReportButton.defaultProps = {
  history: {}
}

export default AddReportButton