import React , { useState , useEffect }from 'react';
// import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Styles } from './styles';
import BackButton from '../../../../components/back-button/BackButton';
import UILoader from '../../../../components/ui-loader/UILoader';
import PropTypes from 'prop-types'
import {
    ThemeProvider,
    Container,
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    SIZE_LARGE,
    Select,
  } from '@mds/mds-reactjs-library';
import { hideLoader, addUserRoleAutorization, setLoader } from '../../../../store/actions';
import { ADMIN_CONFIGURATION, INTERNAL_USER, ADMIN_USER_MANAGEMENT, EXTERNAL_USER } from '../../../../constants';

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
    font-size: 24px;
    font-weight: 500;
    color: black;
    font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
    color: #000;
    margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
    display: flex;
    @media only screen and (max-width: 767px) { {
    flex-direction: column;
    }
  `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
  `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;
  const SelectStyled = styled(Select)`
  width: 500px;
  background: #fff;
  margin-bottom: 60px;
  .mds__menu {
    height: 150px;
    overflow: scroll;
  }
  @media only screen and (max-width: 767px) {
    width: 300px;
  }
`;
const Label = styled.div`
    margin-bottom:5px;
  `;
  
  const EditRole = (props) => {
    const { userRole, roleList, history } = props;
    const dispatch = useDispatch();
    const [role, setRole] = useState('');
    const [isValid, setIsValid] = useState(false);
    let internalDomains = ['mckinsey.com', 'external.mckinsey.com', 'admin.mckinsey.id', 'acp.clientco1.com'];
    let assignedRole = [];
    useEffect(() => {
    dispatch(hideLoader())
    setRole(assignedRole[0])
    },[userRole]);
    useEffect(() => {
      if(role!=='' && role !== undefined){
        setIsValid(true)
      } 
      else{
        setIsValid(false)
      }
      },[role]);
    const handleEdit = () => {
      dispatch(addUserRoleAutorization(history, JSON.stringify(props?.selectedUser), role))
      dispatch(setLoader())
    }
    let rolesList = [];
    let selectedUserDomain = props?.selectedUser?.email.substring(props?.selectedUser?.email.lastIndexOf("@") +1);
    if (roleList && Array.isArray(roleList.data)) {
      const filterTeams = internalDomains.includes(selectedUserDomain) ? roleList?.data?.filter(team => team.name !== EXTERNAL_USER) : roleList?.data?.filter(team => team.name !== ADMIN_USER_MANAGEMENT && team.name !== INTERNAL_USER && team.name !== ADMIN_CONFIGURATION);
      rolesList = filterTeams && filterTeams.map((data, index) => {
        return {
          "label": data.name,
          "value": data.id,
          "key": index
        }
      })
    }
    if (userRole && Array.isArray(userRole.teams)) {
      assignedRole = userRole && userRole.teams.map((data) => {
        return data.id
      })      
    }




  return (
    <Styles>
    <UILoader>
      <ThemeProvider>
        <Container>
          <BackButton id="edit-userrole-back-btn" text="Return to User Management" />
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>Edit {props?.selectedUser?.first_name} {props?.selectedUser?.last_name}</HeadingClientName></DIV>
            <DIV><DescriptionSpan>Role field is required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              <InputStyledContainer>    
              <Label>Role</Label>
                <SelectStyled
                      id='edit-role-select-options'
                      options={rolesList}
                      size={SIZE_LARGE}
                      placeholder="Select"
                      value={role}
                      onChange={(e) => setRole(e?.target?.value)}
                  />
              </InputStyledContainer>
              </Container>
          </FreeSpaceBackground>
          <ButtonStyled id="edit-role-save-btn" appearance={PRIMARY_BUTTON} onClick={handleEdit} disabled={!isValid}>Save</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/user`)}>Cancel</ButtonStyled>
        </Container>
      </ThemeProvider>
    </UILoader>
  </Styles>
  );
}

EditRole.propTypes = {
  history: PropTypes.object,
  roleList: PropTypes.object,
  userRole: PropTypes.object,
}

EditRole.defaultProps = {
  history: {},
  roleList: {},
  userRole: {}
}

const mapStateToProps = (state) => ({
  roleList: state.reducer.roleList,
  userRole: state.reducer.userRole,
  authUserList: state.reducer.authUserList,
  selectedUser: state.reducer.selectedUser
})

export default connect(mapStateToProps)(EditRole);
