import React from 'react'
import styled from 'styled-components'
import AddReportButton from '../actions-buttons/add-report-button/add-report-button'
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types';

const ToolbarStyled = styled(Toolbar)`
  padding-left: 0;
`;
 
const TableToolbar = (props) => {
  return (
    <ToolbarStyled>
      <AddReportButton history={props.history}/>
    </ToolbarStyled>
  )
}
 
TableToolbar.propTypes = {
  history: PropTypes.object
}

TableToolbar.defaultProps = {
  history: {}
}

export default TableToolbar