export const USER_INFO_KEY = 'userInfo'

// roles
export const ADMIN_CONFIGURATION = 'Admin-Configuration (Mckinsey)'
export const EXTERNAL_USER = 'User (Client)'
export const INTERNAL_USER = 'User (McKinsey)'
export const ADMIN_USER_MANAGEMENT = 'Admin-User Manager (McKinsey)'

//paths 

export const ADD_REPORTS_PATH = "/admin/report/addReports"
export const EDIT_REPORTS_PATH = "/admin/report/editReports"
export const EDIT_DATA_FILTER_PATH = "/admin/client/editFilter"

export const SUBSCRIPTION_REPORTS_PATH = "/admin/client/subscribeReport"
export const EDIT_CLIENT_PATH = "/admin/client/editClient"

export const ADD_DEFAULT_USER_PATH = "/admin/team/addUser"
export const EDIT_DEFAULT_USER_PATH = "/admin/team/editUser"

export const EDIT_USER_PATH = "/admin/user/editUser"
export const EDIT_ROLE_PATH = "/admin/user/editRole"
export const ADD_USER_PATH = '/admin/user/addUser'
export const ADD_USER_ROLE_PATH = '/admin/user/addUserRole'

//Vault 
export const VAULT_SECRET_PATH = '/admin/client/secret'
