import React from 'react';
import { ThemeProvider, InfoModal, INFO_MODAL, SECONDARY_BUTTON } from '@mds/mds-reactjs-library';
import './delete-user.css';



function DeleteUser ({dialog, userName, onOk, onCancel}){
    let text = (dialog === 'remove') ? `Are you sure you want to remove ${userName.data.rowData.first_name} ${userName.data.rowData.last_name} from the User List?` : 
    `An email will be sent to ${userName.data.rowData.first_name} ${userName.data.rowData.last_name} containing a link which will allow them to reset their password.`;
    let confirm = (dialog === 'remove') ? 'Yes, Remove' : 'Confirm';
    let cancel = (dialog === 'remove') ? 'No, Cancel' : 'Cancel';
  return (
    <ThemeProvider >
      <InfoModal
      id="remove-user-modal"
        type={INFO_MODAL}
        className="remove-modal"
        body={text}
        okButton={{
          label: confirm,
          action: onOk,
        }}
        cancelButton={{
            label: cancel,
            action: onCancel,
            appearance: SECONDARY_BUTTON
          }}
          onClose={onCancel}
      />
    </ThemeProvider>
  );
}
export default DeleteUser;