import React , { useState , useEffect }from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Styles } from '../../core-team-table/styles';
import BackButton from '../../../../components/back-button/BackButton';
import UILoader from '../../../../components/ui-loader/UILoader';
import PropTypes from 'prop-types';
import {
    ThemeProvider,
    Container,
    Button,
    Select,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    SIZE_LARGE,
    FormElementWrapper,
    Input
  } from '@mds/mds-reactjs-library';
  import {  ADMIN_USER_MANAGEMENT, INTERNAL_USER } from '../../../../constants'
  import { addDefaultUser, hideLoader } from '../../../../store/actions';

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `;
  const FormElementWrapperStyled = styled(FormElementWrapper)`
  .mds-input {
    width: 500px;
  }
 `;
 const FormElementWrapperStyle = styled(FormElementWrapper)`
  .mds-input {
    width: 400px;
  }
 `;
  const Label = styled.div`
    margin-bottom:5px;
  `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
  `;
  const InputStyledCntr = styled.div`
      width: 400px;
      @media (min-width: 768px) {
        margin: 60px 0 0 20px;
      }
      @media (max-width: 767px) {
        margin: 40px 0 40px 20px;
      }
  `;
  const SelectStyled = styled(Select)`
    width: 500px;
    background: #fff;
    margin-bottom: 60px;
    @media only screen and (max-width: 767px) {
      width: 300px;
    }
  `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;

  
  const AddDefaultUser = (props) => {
    let history = props.history;
    const dispatch = useDispatch();

    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [isValid,setIsValid] = useState(false);
    
    useEffect(() => {
      dispatch(hideLoader())
      if(first_name!=='' 
            && first_name.length > 0
            && last_name!=='' 
            && last_name.length > 0 
            && email !==''
            && email.length > 0
            && (email.indexOf('@mckinsey.com') > -1
            || email.indexOf('@external.mckinsey.com') > -1 
            || email.indexOf('@admin.mckinsey.id') > -1)
            && role!==''
            && role.length> 0
            ){
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    },[first_name,last_name,email,role]);


    const handleAdd = () => {
      let newUser = {history,userData:{
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "team_name": role
      }}
      dispatch(addDefaultUser(newUser))
      history.push({
        pathname: `/admin/team`
      });
    }

  return (
    <Styles>
    <UILoader>
      <ThemeProvider>
        <Container>
          <BackButton id="add-defaultuser-back-btn" text="Return to Core Team" />
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>Add user to Core Team</HeadingClientName></DIV>
            <DIV><DescriptionSpan>This team member will be added automatically to all newly created tenants.</DescriptionSpan></DIV>
            <DIV><DescriptionSpan>All fields are required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              <DIV>
              <InputStyledCntr>    
                <FormElementWrapperStyle
                  component={Input}
                  label="First Name"
                  inputProps={{
                      placeholder: 'Enter First Name',
                      name: 'first_name',
                      value: first_name,
                      onChange: e => setFirstName(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                      return !val || val.length < 1 ? 'Please enter the first name' : '';
                  }}
                />
              </InputStyledCntr>
              <InputStyledCntr>    
                <FormElementWrapperStyle
                  component={Input}
                  label="Last Name"
                  inputProps={{
                      placeholder: 'Enter Last Name',
                      name: 'last_name',
                      value: last_name,
                      onChange: e => setLastName(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                      return !val || val.length < 1 ? 'Please enter the last name' : '';
                  }}
                />
              </InputStyledCntr>
              </DIV>
              <InputStyledContainer>    
              <FormElementWrapperStyled
                  component={Input}
                  label="Email"
                  inputProps={{
                      placeholder: 'Enter Email',
                      name: 'email',
                      value: email,
                      onChange: e => setEmail(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                     return (!val || val.length < 1) ? 'Please enter the email' : 
                     (val.indexOf('@mckinsey.com') === -1 && val.indexOf('@external.mckinsey.com') === -1 && val.indexOf('@admin.mckinsey.id') === -1) 
                     ? 'Please enter the valid email id' : '';
                  }}
                />
              </InputStyledContainer>
              <InputStyledContainer>    
              <Label>Role</Label>
                <SelectStyled
                      id='core-team-select-options'
                      options={[
                        {
                            label: ADMIN_USER_MANAGEMENT,
                            value: ADMIN_USER_MANAGEMENT,
                        },
                        {
                          label: INTERNAL_USER,
                          value: INTERNAL_USER,
                        },
                      ]}
                      size={SIZE_LARGE}
                      placeholder="Select"
                      value={role}
                      onChange={(e) => setRole(e?.target?.value)}
                  />
              </InputStyledContainer>
              </Container>
          </FreeSpaceBackground>
          <ButtonStyled id='core-team-adduser-btn' appearance={PRIMARY_BUTTON} onClick={handleAdd} disabled={!isValid}>Add</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => props.history.push(`/admin/team`)}>Cancel</ButtonStyled>
        </Container>
      </ThemeProvider>
    </UILoader>
  </Styles>
  );
}

AddDefaultUser.propTypes = {
  history: PropTypes.object
}

AddDefaultUser.defaultProps = {
  history: {}
}

export default AddDefaultUser;