import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducerBuilder as midReducer } from '@nvt/mid-react-saga'
import { alertReducer, loaderReducer } from './alertReducer'
import * as constants from './constants'


function reducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_EMBED_TOKEN_SUCCESS: {
      return { ...state, embedDetails: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.GET_EMBED_TOKEN_FAILURE: {
      return { ...state, embedDetails: {}, apiError: {message: {}, apiError: true} }
    }
    case constants.GET_CSV_SUCCESS: {
      return { ...state, csvFile: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_DATA_SUCCESS: {
      return { ...state, cardData: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.FILTER_DATA_SUCCESS: {
      return { ...state, embedDetails: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.ANTIVIRUS_CHECK: {
      return { ...state, payload: action.payload , apiError: {message: {}, apiError: false}}
    }
    case constants.ANTIVIRUS_CHECK_SUCCESS: {
      return { ...state, antivirusResult: action.payload.res.av_status, apiError: {message: {}, apiError: false} }
    }
    case constants.GET_REPORT_SUCCESS: {
      return { ...state, reports: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.ADD_REPORT_SUCCESS: {
      return { ...state, report: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.DELETE_REPORT_SUCCESS: {
      return { ...state, report: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_REPORT_SUCCESS: {
      return { ...state, report: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CLIENT_SUCCESS: {
      return { ...state, clients: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.ADD_CLIENT_SUCCESS: {
      return { ...state, client: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.DELETE_CLIENT_SUCCESS: {
      return { ...state, client: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_CLIENT_SUCCESS: {
      return { ...state, client: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.GET_TENANT_DETAILS_SUCCESS: {
      return { ...state, tenants: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CLIENT_REPORT_SUCCESS: {
      return { ...state, clientReport: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CLIENT_RANKING_REPORT_SUCCESS: {
      return { ...state, reportRanking: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_SUBSCRIBE_REPORT_SUCCESS: {
      return { ...state, clientSubscribedReport: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CUSTOM_EMBED_FILTERS_SUCCESS: {
      return { ...state, customEmbedFilters: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.POST_CUSTOM_EMBED_FILTERS_SUCCESS: {
      return { ...state, customEmbedFilters: action.payload.res , loading:action.payload.isLoading, apiError: {message: {}, apiError: false}}
    }
    case constants.SUBSCRIBE_REPORT_SUCCESS: {
      return { ...state, SubStatus: action.payload.res, apiError: {message: {}, apiError: false}}
    }
    case constants.GET_USER_SUBSCRIBE_REPORT_SUCCESS: {
      return { ...state, userReport: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_USER_SUBSCRIBE_REPORT_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.UNSUBSCRIBE_REPORT_SUCCESS: {
      return { ...state, SubStatus: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_ROLES_SUCCESS: {
      return { ...state, role: action.payload.res[0], apiError: {message: {}, apiError: false} }
    }
    case constants.GET_DEFAULT_TEAM_SUCCESS: {
      return { ...state, teamData: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CLIENT_DATA_FILTER_SUCCESS: {
      return { ...state, offers: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_CLIENT_DATA_FILTER_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_USER_DATA_FILTER_SUCCESS: {
      return { ...state, offers: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_USER_DATA_FILTER_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_CLIENT_USER_DATA_SUCCESS: {
      return { ...state, userList: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.ADD_DEFAULT_TEAM_USER_SUCCESS: {
      return { ...state, userData: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_DEFAULT_TEAM_USER_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.DELETE_DEFAULT_TEAM_USER_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_USER_MGMT_LIST_SUCCESS: {
      return { ...state, userList: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_ROLES_LIST_SUCCESS: {
      return { ...state, roleList: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_USER_ROLE_SUCCESS: {
      return { ...state, userRole: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_AUTORIZATION_USER_SUCCESS: {
      return { ...state, authUserList: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.ADD_USER_ROLE_AUTORIZATION_SUCCESS: {
      return { ...state, authUserRole: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.ADD_USER_SUCCESS: {
      return { ...state, userAdd: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.EDIT_USER_SUCCESS: {
      return { ...state, editStatus: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.USER_RESET_PWD_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.DELETE_USER_SUCCESS: {
      return { ...state, status: action.payload.res , apiError: {message: {}, apiError: false}}
    }
    case constants.GET_VAULT_SECRET_SUCCESS: {
      return { ...state, vault: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.DELETE_VAULT_SECRET_SUCCESS: {
      return { ...state, vault: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.SAVE_VAULT_SECRET_SUCCESS: {
      return { ...state, vault: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.GET_VAULT_HISTORY_SUCCESS: {
      return { ...state, vaultHistory: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.GET_USER_INFO_SUCCESS: {
      return { ...state, userInfo: action.payload.res, apiError: {message: {}, apiError: false} }
    }
    case constants.API_FAILED: {
      return { ...state, apiError: action.payload }
    }
    case constants.SET_SELECTED_REPORT: {
      return { ...state, selectedReport: action.payload }
    }
    case constants.SET_SELECTED_USER: {
      return { ...state, selectedUser: action.payload }
    }
    case constants.CLEAR_ADD_EDIT_REPORT_STATE:{
      return { ...state, report: null }
    }
    case constants.SET_SELECTED_CLIENT: {
      return { ...state, selectedClient: action.payload }
    }
    case constants.SET_NOTIFICATION: {
      return {
        ...state,
        notification: {
          willTimeout: true,
          ...action.payload,
        },
      }
    }
    case constants.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: {
          type: '',
          message: '',
          display: false,
        },
      }
    }
    default:
      return state
  }
}

export default function createReducer(history, mid) {
  return combineReducers({
    mid: midReducer(mid),
    router: connectRouter(history),
    alert: alertReducer,
    loader: loaderReducer,
    reducer,
  })
}