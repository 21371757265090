import styled from 'styled-components'

const SelectFieldContainer = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`
const CustomDropdownMenu = styled.div`
width: 100%;
position: absolute;
z-index: 3;
background-color: white;
border-radius: 0px;
margin-top: 1px;
box-shadow: rgb(5 28 44 / 20%) 0px 4px 8px -1px, rgb(5 28 44 / 15%) 0px 0px 1px 0px;
`

const CheckInput = styled.div `
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
position: relative;
z-index: 1;
width: 1.25rem;
height: 1.25rem;
margin-right: 0.5rem;
border: 1px solid;
color: #333333;
border-color: ${props => props.isSelected ? "#2251ff" : "#b3b3b3"};
background: ${props => props.isSelected ? "#2251ff" : ""};
`

const OptionsContainer = styled.div `
    display: flex;
`

const InputStyles = styled.div`
opacity: 0;
`
const LinkStyled = styled.a`
cursor: pointer;
color: #2251ff;
position: relative;
float: right;
margin: 0 1rem 1rem 0;
  &:hover {
    text-decoration: none;
  }
`

export { SelectFieldContainer, CustomDropdownMenu, CheckInput, OptionsContainer, InputStyles, LinkStyled };
