import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Container, ThemeProvider, Input, Button,
    PRIMARY_BUTTON, HEADING_SIX, Tooltip,
    SECONDARY_BUTTON, FormElementWrapper, Typography
} from '@mds/mds-reactjs-library';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SideNav from '../../../../components/SideNavBar';
import { style } from '@material-ui/system';
import { setLoader, editReport } from '../../../../store/actions';
import BackButton from '../../../../components/back-button/BackButton';
import UIAlert from '../../../../components/ui-alert/UIAlert';
import CustomToggle from '../../../../components/custom-toggle/CustomToggle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
.mds-form-el {
    margin-top:1rem!important;
}
`;

const ClientTableDiv = styled.div`
    background-color:#f6f6f6;
    padding:1rem;
    @media only screen and (max-width: 767px) { {
    margin-top: 50px;
    }
`;
const ClientTableHeader = styled.div`
    padding:10px;
    background-color:#e6e6e6;
    font-weight:bold;
    
`;
const SubDescriptionSpan = styled.div`
padding-top:10px;
font-weight:normal;
dispaly:block;
font-style: italic;
font-size: 16px;
`
const Label = styled.div`
  margin-bottom:5px;
`;

const StyledContainer = styled(Container)(() => (
    {
        padding: '4rem 10rem' 
    }
));
const useStyles = makeStyles(() => ({
    formcontrollabel: {
      marginLeft: 'auto',
      flexDirection: 'row',
    }
  }));

const EditReports = ({ role, selectedReport, history,updateReport, report}) => {
    const [info, setInfo] = useState('');
    const [domo_embed_id, setDOMO] = useState('')
    const [global_report_name,setGlobalReportName] = useState('');
    const [domo_report_name,setDomoReportName] = useState('');
    const [custom_dataset_id, setDomoDatasetId] = useState('');
    const [report_state,setReportState] = useState(true);
    const [isValid,setIsValid] = useState(false);
    const classes = useStyles();

    useEffect(()=>{        
        if(selectedReport){         
            setDOMO(selectedReport.domo_embed_id);
            setGlobalReportName(selectedReport.global_report_name);
            setDomoReportName(selectedReport.domo_report_name);
            setReportState(selectedReport.report_state);
            setInfo(selectedReport.metadata_program);
            setDomoDatasetId(selectedReport.custom_dataset_id);
        } 
    },[selectedReport]);
    
    const ButtonStyled = styled(Button)`
   
    margin-right: 12px;
    margin-bottom: 12px;
    `;

    useEffect(()=>{
        if(report && report!=='') {
            const rp = JSON.parse(report);
            if(rp && rp.id)
            { 
                history.push('/admin/report');
            }
        }
    },[report]);

    useEffect(()=>{
        if(global_report_name!=='' 
            && global_report_name.length >0 
            && domo_report_name !==''
            && domo_report_name.length >= 0
            && domo_embed_id!==''
            && domo_embed_id.length>=0
            && info!==''
            && info.length>=0){
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    },[global_report_name,domo_report_name,domo_embed_id,info]);

    const handleUpdate = () => {
        
        updateReport({history,reportData:{
            metadata_program:info,
            domo_embed_id,
            global_report_name,
            domo_report_name,
            is_visible:report_state,            
            //domo_embed_id: selectedReport.domo_embed_id,
            id:selectedReport.id,
            custom_dataset_id
        }});
    }

    return (
        <DIV>
            <SideNav role={role} />
            
            <ThemeProvider>
                <StyledContainer>
                    <UIAlert />
                    <BackButton id="edit-report-back-btn" text="Return to Report Management" />
                    <ClientTableHeader>
                    <Typography type={HEADING_SIX}> Edit Reports </Typography>
                        <SubDescriptionSpan>All fields are required.</SubDescriptionSpan>

                    </ClientTableHeader>
                    <ClientTableDiv>                        
                        <div className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                helperText="Required"
                                label="Global Report Name"                            
                                inputProps={{
                                    placeholder: 'Enter Global Report Name',
                                    name: 'global_report_name',
                                    value: global_report_name,
                                    onChange: e => setGlobalReportName(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Global Report Name' : '';
                                }}                            
                            />
                        </div>
                        <div className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                className={style}
                                label="Domo Report Name"
                                disabled                            
                                inputProps={{
                                    placeholder: 'Enter Domo Report Name',
                                    name: 'domo_report_name',
                                    value: domo_report_name,
                                    onChange: e => setDomoReportName(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Domo Report Name' : '';
                                }}                            
                            />                            
                        </div>
                        <div className="mt-4">
                        <FormElementWrapper
                            component={Input}
                            helperText="Required"
                            label="Domo Embed Id"
                            inputProps={{
                                placeholder: 'Add Domo Embed Id',
                                name: 'domo_embed_id',
                                value: domo_embed_id,
                                onChange: e => setDOMO(e.target.value),
                                htmlSize: 30
                            }}
                            validationFunc={val => {
                                return !val || val.length < 1 ? 'Please Enter Domo Embed Id' : '';
                            }}
                        />
                        </div>
                        <div className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                className={style}
                                label="Domo Dataset Id"                        
                                inputProps={{
                                    placeholder: 'Enter Domo Dataset Id',
                                    name: 'custom_dataset_id',
                                    value: custom_dataset_id,
                                    onChange: e => setDomoDatasetId(e.target.value),
                                    htmlSize: 30
                                }}
                            />                            
                        </div>
                        <div className="mt-4">
                        <FormElementWrapper
                            component={Input}
                            label="Info"
                            helperText="Required"
                            inputProps={{
                                placeholder: 'Info',
                                name: 'info',
                                value: info,
                                onChange: e => setInfo(e.target.value),
                                htmlSize: 30
                            }}
                            validationFunc={val => {
                                return !val || val.length < 1 ? 'Please Enter Info' : '';
                            }}
                        />
                        </div>
                        <div className="mt-4">
                            <Label>Report State</Label>
                            <Tooltip content={report_state ? "Visible" : "Not Visible"}>
                                <FormControlLabel
                                className={classes.formcontrollabel}
                                value="top"
                                control={<CustomToggle
                                checked={report_state}
                                onChange={()=> setReportState(!report_state)}
                                name="report_state"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />}
                                label="Visible"
                                labelPlacement="start"
                                />
                            </Tooltip>
                        </div>
                    </ClientTableDiv>
                    <div className="mds-form-el mt-4">
                        <ButtonStyled id="report-mgmt-save-btn" appearance={PRIMARY_BUTTON} onClick={handleUpdate} disabled={!isValid}>Save</ButtonStyled>
                        <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/report`)}>Cancel</ButtonStyled>
                        </div>
                </StyledContainer>
            </ThemeProvider>

        </DIV>
    );
}

EditReports.propTypes = {
    history: PropTypes.object,
    role: PropTypes.string,
}
EditReports.defaultProps = {
    history: {},
    role: ""
}
  
  const mapStateToProps = (state) => ({
   
    mid: state.mid,
    role: state.reducer.role,
    selectedReport:state.reducer.selectedReport,
    report:state.reducer.report,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    updateReport: ( payload ) => {        
        dispatch(setLoader())
        dispatch(editReport(payload))
        },
  })
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditReports))

