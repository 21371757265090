// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".JksRH ._2SA8o{color:#fff}", "",{"version":3,"sources":["delete-user.css"],"names":[],"mappings":"AAAA,eACE,UAAa","file":"delete-user.css","sourcesContent":[".remove-modal .mds-modal-body {\n  color: #fff; }\n"]}]);
// Exports
exports.locals = {
	"remove-modal": "JksRH",
	"mds-modal-body": "_2SA8o"
};
module.exports = exports;
