import styled from 'styled-components'

const TenantContainer = styled.div`
    padding: 70px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
`
const TenantInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 30px
`

const ErrorText = styled.div `
  color: #FF0000;
`

const LoginHeader = styled.div `
z-index: 1;
background: white;
}
  `

export {LoginHeader, TenantContainer, TenantInput, ErrorText};