export const TEST_FETCH = 'TEST_FETCH'
export const TEST_FETCH_SUCCESS = 'TEST_FETCH_SUCCESS'

export const ANTIVIRUS_CHECK = 'ANTIVIRUS_CHECK'
export const ANTIVIRUS_CHECK_SUCCESS = 'ANTIVIRUS_CHECK_SUCCESS'


export const GET_EMBED_TOKEN = 'GET_EMBED_TOKEN'
export const GET_EMBED_TOKEN_SUCCESS = 'GET_EMBED_TOKEN_SUCCESS'
export const GET_EMBED_TOKEN_FAILURE = 'GET_EMBED_TOKEN_FAILURE'

export const GET_DATA = 'GET_DATA'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'

export const GET_CSV = 'GET_CSV'
export const GET_CSV_SUCCESS = 'GET_CSV_SUCCESS'


export const FILTER_DATA = 'FILTER_DATA'
export const FILTER_DATA_SUCCESS = 'FILTER_DATA_SUCCESS'

export const GET_REPORT = 'GET_REPORT'
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'

export const ADD_REPORT = 'ADD_REPORT'
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS'

export const DELETE_REPORT = 'DELETE_REPORT'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'

export const EDIT_REPORT = 'EDIT_REPORT'
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS'

export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'

export const ADD_CLIENT = 'ADD_CLIENT'
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS'

export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS'

export const EDIT_CLIENT = 'EDIT_CLIENT'
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS'

export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'


export const GET_USER_DETAILS = 'GET_USER_DETAILS'

export const GET_TENANT_DETAILS = 'GET_TENANT_DETAILS'
export const GET_TENANT_DETAILS_SUCCESS = 'GET_TENANT_DETAILS_SUCCESS'

export const GET_CLIENT_SUBSCRIBED_REPORT = 'GET_SUBSCRIBE_REPORT'
export const GET_SUBSCRIBE_REPORT_SUCCESS = 'GET_SUBSCRIBE_REPORT_SUCCESS'


export const GET_CUSTOM_EMBED_FILTERS = 'GET_CUSTOM_EMBED_FILTERS'
export const POST_CUSTOM_EMBED_FILTERS = 'POST_CUSTOM_EMBED_FILTERS'
export const POST_CUSTOM_EMBED_FILTERS_SUCCESS = 'POST_CUSTOM_EMBED_FILTERS_SUCCESS'
export const GET_CUSTOM_EMBED_FILTERS_SUCCESS = 'GET_CUSTOM_EMBED_FILTERS_SUCCESS'

export const GET_CLIENT_REPORT = 'GET_CLIENT_REPORT'
export const GET_CLIENT_REPORT_SUCCESS = 'GET_CLIENT_REPORT_SUCCESS'

export const SUBSCRIBE_REPORT = 'SUBSCRIBE_REPORT'
export const SUBSCRIBE_REPORT_SUCCESS = 'SUBSCRIBE_REPORT_SUCCESS'

export const GET_USER_SUBSCRIBED_REPORT = 'GET_USER_SUBSCRIBED_REPORT'
export const GET_USER_SUBSCRIBE_REPORT_SUCCESS = 'GET_USER_SUBSCRIBE_REPORT_SUCCESS'

export const EDIT_USER_SUBSCRIBED_REPORT = 'EDIT_USER_SUBSCRIBED_REPORT'
export const EDIT_USER_SUBSCRIBE_REPORT_SUCCESS = 'EDIT_USER_SUBSCRIBE_REPORT_SUCCESS'

export const UNSUBSCRIBE_REPORT = 'UNSUBSCRIBE_REPORT'
export const UNSUBSCRIBE_REPORT_SUCCESS = 'UNSUBSCRIBE_REPORT_SUCCESS'

export const GET_CLIENT_DATA_FILTER = 'GET_CLIENT_DATA_FILTER'
export const GET_CLIENT_DATA_FILTER_SUCCESS = 'GET_CLIENT_DATA_FILTER_SUCCESS'

export const GET_USER_DATA_FILTER = 'GET_USER_DATA_FILTER'
export const GET_USER_DATA_FILTER_SUCCESS = 'GET_USER_DATA_FILTER_SUCCESS'

export const EDIT_CLIENT_DATA_FILTER = 'EDIT_CLIENT_DATA_FILTER'
export const EDIT_CLIENT_DATA_FILTER_SUCCESS = 'EDIT_CLIENT_DATA_FILTER_SUCCESS'

export const EDIT_USER_DATA_FILTER = 'EDIT_USER_DATA_FILTER'
export const EDIT_USER_DATA_FILTER_SUCCESS = 'EDIT_USER_DATA_FILTER_SUCCESS'

export const GET_CLIENT_USER_DATA = 'GET_CLIENT_USER_DATA'
export const GET_CLIENT_USER_DATA_SUCCESS = 'GET_CLIENT_USER_DATA_SUCCESS'

export const GET_CLIENT_RANKING_REPORT = 'GET_CLIENT_RANKING_REPORT'
export const GET_CLIENT_RANKING_REPORT_SUCCESS = 'GET_CLIENT_RANKING_REPORT_SUCCESS'

export const SAVE_CLIENT_RANKING_REPORT = 'SAVE_CLIENT_RANKING_REPORT'
export const SAVE_CLIENT_RANKING_REPORT_SUCCESS = 'SAVE_CLIENT_RANKING_REPORT_SUCCESS'

export const GET_DEFAULT_TEAM = 'GET_DEFAULT_TEAM'
export const GET_DEFAULT_TEAM_SUCCESS = 'GET_DEFAULT_TEAM_SUCCESS'

export const ADD_DEFAULT_TEAM_USER = 'ADD_DEFAULT_TEAM_USER'
export const ADD_DEFAULT_TEAM_USER_SUCCESS = 'ADD_DEFAULT_TEAM_USER_SUCCESS'

export const EDIT_DEFAULT_TEAM_USER = 'EDIT_DEFAULT_TEAM_USER'
export const EDIT_DEFAULT_TEAM_USER_SUCCESS = 'EDIT_DEFAULT_TEAM_USER_SUCCESS'

export const DELETE_DEFAULT_TEAM_USER = 'DELETE_DEFAULT_TEAM_USER'
export const DELETE_DEFAULT_TEAM_USER_SUCCESS = 'DELETE_DEFAULT_TEAM_USER_SUCCESS'

export const GET_USER_MGMT_LIST = 'GET_USER_MGMT_LIST'
export const GET_USER_MGMT_LIST_SUCCESS = 'GET_USER_MGMT_LIST_SUCCESS'
export const GET_AUTORIZATION_USER = 'GET_AUTORIZATION_USER'
export const GET_AUTORIZATION_USER_SUCCESS = 'GET_AUTORIZATION_USER_SUCCESS'
export const ADD_USER_ROLE_AUTORIZATION = 'ADD_USER_ROLE_AUTORIZATION'
export const ADD_USER_ROLE_AUTORIZATION_SUCCESS = 'ADD_USER_ROLE_AUTORIZATION_SUCCESS'
export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'

export const GET_ROLES_LIST = 'GET_ROLES_LIST'
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS'

export const GET_USER_ROLE = 'GET_USER_ROLE'
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS'

export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'

export const USER_RESET_PWD = 'USER_RESET_PWD'
export const USER_RESET_PWD_SUCCESS = 'USER_RESET_PWD_SUCCESS'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const API_FAILED = 'API_FAILED'

export const types = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  ALERT: 'ALERT',
  ACTIVITY: 'ACTIVITY',
}

export const SET_NOTIFICATION = `SET_NOTIFICATION`
export const CLEAR_NOTIFICATION = `CLEAR_NOTIFICATION`


export const SET_ALERT = 'SET_ALERT'
export const RESET_ALERT = 'RESET_ALERT'
export const SET_LOADER = 'SET_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

export const SUCCESS_ALERT_CONTEXTUAL = 'SUCCESS_ALERT_CONTEXTUAL'
export const ERROR_ALERT_CONTEXTUAL = 'ERROR_ALERT_CONTEXTUAL'


export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'
export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT'
export const CLEAR_ADD_EDIT_REPORT_STATE = 'CLEAR_ADD_EDIT_REPORT_STATE'

// vault

export const GET_VAULT_SECRET = 'GET_VAULT_SECRET'
export const GET_VAULT_SECRET_SUCCESS = 'GET_VAULT_SECRET_SUCCESS'

export const DELETE_VAULT_SECRET = 'DELETE_VAULT_SECRET'
export const DELETE_VAULT_SECRET_SUCCESS = 'DELETE_VAULT_SECRET_SUCCESS'


export const SAVE_VAULT_SECRET = 'SAVE_VAULT_SECRET'
export const SAVE_VAULT_SECRET_SUCCESS = 'SAVE_VAULT_SECRET_SUCCESS'

export const GET_VAULT_HISTORY = 'GET_VAULT_HISTORY'
export const GET_VAULT_HISTORY_SUCCESS = 'GET_VAULT_HISTORY_SUCCESS'


export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'