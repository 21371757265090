import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Styles } from '../../client-configuration-table/styles'
import PropTypes from 'prop-types';
import CustomTable from '../../client-configuration-table/CustomTable';
import CustomToggle from '../../../../components/custom-toggle/CustomToggle';
// Table
import {
  ThemeProvider,
  Button,
  Badge,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  SIZE_MEDIUM
} from '@mds/mds-reactjs-library'
import UILoader from '../../../../components/ui-loader/UILoader';
import { editDataFilter, setLoader } from '../../../../store/actions';


const ButtonStyled = styled(Button)`
   margin: 0 20px 20px 0;
   float: right;
`;

const ButtonStyle = styled(Button)`
   margin: 0 0 20px 0;
   float: right;
`;


const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const PARENTDIV = styled.div`

`;

const HEADDIV = styled.div`

`;

const ALTDIV = styled.div`
border : none;
padding : 0;
`;

const CDIV = styled.div`
  border: 3px solid blue;
  padding: 20px;
  height: 30rem;
  overflow: scroll;
`;

const Description = styled.div`
  width: 60%;
  font-size: 16px;
  padding-bottom: 30px;
`;

const ButtonDiv = styled.div`
  width: 40%;
`;

const CustomToggleStyled = styled(CustomToggle)`
  top: 12px;
  left: 10px;
`;

const BadgeStyled = styled(Badge)`
  margin: 12px 0 0 10px;
`;

const HeaderStyled = styled.h6`
  width: 73%;
`;

const HeaderMain = styled.h5`
  margin: 19px 0 4px 0;
`;

const EditDataFilter = (props) => {
  let history = props.history;
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [showButton, setShowButton] = useState(false)
  sessionStorage.setItem('fromUser', 0)
  let CONDIV = showButton ? CDIV  : ALTDIV

  useEffect(() => {
   if (props.offers && Array.isArray(props.offers.dataFilter)){  
    setOffers(props.offers.dataFilter)
    }
  }, [props.offers]);

 const handleChange = (event, offerId, i) => {
    let newArr = [...offers]; 
    newArr[0].offeringsAndPrograms.offerings[offerId].programs[i].enabled = event.target.checked;
    newArr[0].offeringsAndPrograms.offerings[offerId].programs[i].actioned = true;
    newArr[0].offeringsAndPrograms.offerings[offerId].actioned = true;
    let prgCount = newArr[0].offeringsAndPrograms.offerings[offerId].programs.length;
    let prgDisabledCount = 0
    newArr[0].offeringsAndPrograms.offerings[offerId].programs.map((row) => {
        if(!row.enabled) prgDisabledCount++
        return row
    })
    if(prgCount === prgDisabledCount) {
      newArr[0].offeringsAndPrograms.offerings[offerId].enabled = false;
      newArr[0].offeringsAndPrograms.offerings[offerId].actioned = true;
      newArr[0].offeringsAndPrograms.offerings[offerId].programs.map((row) => {
        row.locked = true;
        row.actioned = true;
        return row
      }) 
    }
    setOffers(newArr);
    setShowButton(true)
  }

  const handleOfferChange = (event, offerId) => {
    let newArr = [...offers]; 
    newArr[0].offeringsAndPrograms.offerings[offerId].enabled = event.target.checked;
    newArr[0].offeringsAndPrograms.offerings[offerId].actioned = true;
    newArr[0].offeringsAndPrograms.offerings[offerId].programs.map((row) => {
      row.enabled = event.target.checked;    
      row.locked = (!event.target.checked) ? true : false;
      row.actioned = true;
      return row
    }) 
    setOffers(newArr);
    setShowButton(true)
  }

  const handleEditFilter = () => {
    let editFilter = {history: history,filterData: {
      "dataFilter": offers,
    }}
    let clientId = props?.selectedClient ? props?.selectedClient.tenant_id : JSON.parse(sessionStorage.getItem('selectedClient'))?.tenant_id
    dispatch(editDataFilter(editFilter,clientId))
    dispatch(setLoader())
    setShowButton(false)
  }

  const handleCancel = () => {
    let initialValue = JSON.parse(sessionStorage.getItem('initialOffer'))
    setOffers(initialValue.dataFilter)
    setShowButton(false)
  }

  return (
  <Styles>
    <UILoader>
      <ThemeProvider>
      
        <PARENTDIV>
        <HEADDIV>
        <HeaderMain>Manage Data Permissions</HeaderMain>
           <DIV><Description>You can manage the permissions by enabling or disabling the offering or program IDs from the list below.
            The changes will have an impact on all the admin users for this tenant.</Description>
           {showButton && <ButtonDiv>
           <ButtonStyle appearance={PRIMARY_BUTTON} onClick={handleEditFilter}>Save</ButtonStyle>
           <ButtonStyled appearance={SECONDARY_BUTTON} onClick={handleCancel}>Cancel</ButtonStyled>
           </ButtonDiv>}</DIV>
        </HEADDIV>
        
          <CONDIV>
          {props.offers && offers.map((row, i) => {
              return (
                <div key={i}>
                {row.offeringsAndPrograms && row.offeringsAndPrograms.offerings.map((offer, index) => {
                  return (
                    <div key={index}>
                    <DIV><HeaderStyled>{offer.name}</HeaderStyled><DIV><BadgeStyled size={SIZE_MEDIUM}>{row.offeringsAndPrograms.name}</BadgeStyled><CustomToggleStyled key={index} disabled={offer.locked} checked={offer.enabled}
                        onChange={(event) => handleOfferChange(event, index)} name="offer" data-testid="offer" inputProps={{ 'aria-label': 'chkbox' }}/></DIV></DIV>
                    {offer.programs.length !== 0 && <CustomTable key={index} offerId={index} data={offer.programs} handleConfig={handleChange}/>}
                    </div>
                  )  
                })
                }
                </div>
              )
          }) 
          }
          </CONDIV>
          </PARENTDIV>

      </ThemeProvider>
    </UILoader>
  </Styles>
  )
}

EditDataFilter.propTypes = {
  offers: PropTypes.object,
  history: PropTypes.object
}

EditDataFilter.defaultProps = {
  offers: {},
  history: {}
}

const mapStateToProps = (state) => ({
  offers: state.reducer.offers,
  selectedClient: state.reducer.selectedClient
})

export default connect(mapStateToProps)(EditDataFilter)