import { actions as midActions } from '@nvt/mid-react-saga'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { Mid } from '@mid/sdk'
import { Provider } from 'react-redux'
import { makeMidConfig, LoginWrapper } from '@nvt/mid-react'
import BootUp from './bootup'
import Config from './config'
import createReducers from './store/reducers'
import Welcomepage from './containers/login/welcome-page/welcome-page'
import styled from '@emotion/styled'
import saga from './store/sagas'
import {
  Input,
  SIZE_LARGE,
  Button,
  PRIMARY_BUTTON,
  Container,
  ThemeProvider,
  Tabs,
  Tab,
  Select,
  Typography,
  DISPLAY_SIX,
  Header,
  CONDENSED_HEADER,
  CircularProgress,
  SIZE_EXTRA_LARGE
} from '@mds/mds-reactjs-library'
import '@mds/mds-bootstrap-theme/mck-bootstrap.css'
import '@nvt/mid-react/style.css'
import './stylesheets/main.scss'
import { TenantContainer, TenantInput, ErrorText } from './styles'
import AcademyLogo from './assets/img/mck-academy-rgb-black.svg'

const ButtonStyled = styled(Button)`
  margin-top: 12px;
`

const TabsStyled = styled(Tabs)`
  display: inline-block;
`

const SelectStyled = styled(Select)`
  margin-top: 20px;
  width: 200px;
`

const HeaderStyled = styled(Header)`
  z-index: 1;
  background-color: white;
`

const getIndex = (value, arr, prop) => {
  let i
  for (i = 0; i < arr.length; i++) {
    if (arr[i][prop].toLowerCase() === value.toLowerCase()) {
      return i
    }
  }
  return -1 //to handle the case where the value doesn't exist
}

const LoginTenants = ({ tenants, onSelectTenant }) => {
  const [tenantInput, setTenantInput] = useState('')
  const [validTenant, setValidTenant] = useState()
  const [selectedTab, setSelectedTab] = useState(0)
  const [notification, setNotification] = React.useState('')

  const onInputChange = (e) => {
    if (e.target.value.length > 0) {
      if (/^[\w\-\s]+$/u.test(e.target.value)) {
        let regex = /[^a-zA-Z0-9-_]+$/gu
        setNotification('')
        setTenantInput(e.target.value.replace(regex, '').trim())
      } else {
        setValidTenant(true)
        setValidTenant()
        setNotification('Tenant can only contain alphanumeric characters along with spaces, underscores and hyphens')
      }
    } else {
      setNotification('')
      setValidTenant()
    }
  }

  const tenantSelectOnlick = () => {
    if (tenants.some((tenant) => tenantInput.toLowerCase() === tenant.displayName.toLowerCase())) {
      let index = getIndex(tenantInput, tenants, 'displayName')
      onSelectTenant(tenants[parseInt(index, 10)])
    } else {
      setValidTenant(true)
      setNotification('No client match found')
    }
  }

  let tenantData = []
  if (tenants && Array.isArray(tenants)) {
    tenantData =
      tenants &&
      tenants.map((tenant, index) => {
        return {
          label: tenant.displayName,
          value: index,
          key: index,
        }
      })
  }
  const customTheme = {
    Tabs: {
      Tabs: {
        background: 'transparent',
        borderColor: '#E6E6E6',
        buttonColor: '#000',
        buttonHoverColor: '#2251FF',
        buttonActiveColor: '#2251FF',
        activeBorderColor: '#2251FF',
        flyOutBackground: '#fff',
        hoverFocusIconBackgroundColor: '#F0F0F0',
        activeIconBackgroundColor: '#D0D0D0',
        hamburgerIconColor: '#000',
        customLogoColor: '#051C2C',
        Tab: {
          background: '#fff',
          activeButtonBackground: '#E6E6E6',
          hoveredButtonBackground: '#F0F0F0',
          separatorColor: '#E6E6E6',
          selectedItemBackground: '#F0F0F0',
        },
      },
    },
  }

  const onTabSelect = (e) => {
    setSelectedTab(e)
  }

return (
    <>
      <Header type={CONDENSED_HEADER}  logoSection={<img src={AcademyLogo} alt="mck_logo" />}/>
      <TenantContainer>
        <Container maxWidth={850}>
          <Typography type={DISPLAY_SIX} tablet>
            {' '}
            Client Selection{' '}
          </Typography>
          <ThemeProvider customTheme={customTheme}>
            <TabsStyled selectedValue={selectedTab} onChange={(e) => onTabSelect(e)}>
              <Tab label="Enter Client Name">
                <TenantInput>
                  <Input
                    size={SIZE_LARGE}
                    hasError={validTenant}
                    autoComplete="off"
                    onChange={(e) => onInputChange(e)}
                    placeholder="Enter Client Name"
                  />
                  {notification && <ErrorText>{notification}</ErrorText>}
                  <ButtonStyled
                    disabled={tenantInput ? false : true}
                    onClick={(e) => tenantSelectOnlick(e)}
                    appearance={PRIMARY_BUTTON}
                  >
                    Continue
                  </ButtonStyled>
                </TenantInput>
              </Tab>
              <Tab label="Select a Client">
                <ThemeProvider>
                  <SelectStyled
                    options={tenantData.sort(( a, b) => {return a && a.label && a.label.localeCompare(b.label)})}
                    placeholder="Select a Client"
                    size={SIZE_LARGE}
                    onChange={(e) => onSelectTenant(tenants[parseInt(e.target.value, 10)])}
                  />
                </ThemeProvider>
              </Tab>
            </TabsStyled>
          </ThemeProvider>
        </Container>
      </TenantContainer>
    </>
  )
}

const headerTheme = {
  header: {
    header: {
      background: 'white',
    },
  },
}

const LoginWelcome = ({ onLogin, errorMsg }) => (
  <>
    <ThemeProvider customTheme={headerTheme}>
      <HeaderStyled type={CONDENSED_HEADER} logoSection={<img src={AcademyLogo} />} />
    </ThemeProvider>
    <Welcomepage onLoginHandler={onLogin} errorMessage={errorMsg} />
  </>
)

const LoaderContainer = styled.div`
display: block;
position: absolute;
z-index: 1031;
top: 50%;
left: 50%;
`

const LoginLoad = () => {
  return (
    <LoaderContainer>
    <CircularProgress size={SIZE_EXTRA_LARGE} indeterminate />
    </LoaderContainer>
  )
}

async function render() {
  const midConfig = await makeMidConfig({
    apiUrl: Config.API_SERVER,
    basePath: Config.BASE_PATH,
  })

  const mid = new Mid(midConfig)
  const history = createBrowserHistory({
    basename: Config.BASE_PATH,
  })

  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    createReducers(history, mid),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  )
  sagaMiddleware.run(saga)
  ReactDOM.render(
    <LoginWrapper
      mid={mid}
      apiUrl={Config.API_SERVER}
      autoLogin={Config.AUTO_LOGIN}
      onLogin={() => store.dispatch(midActions.login())}
      loadingNode={() => <LoginLoad />}
      loginNode={(props) => <LoginWelcome {...props} />}
      tenantSelectorNode={(props) => <LoginTenants {...props} />}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BootUp store={store} mid={mid} history={history} />
        </ConnectedRouter>
      </Provider>
    </LoginWrapper>,
    document.getElementById('index'),
  )
}

ReactDOM.render(<LoginLoad />, document.getElementById('index'))
render().catch((err) => {
  ReactDOM.render(<div>Error: {err.message}</div>, document.getElementById('index'))
})
