import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import UILoader from './components/ui-loader/UILoader'
import AuthorizeRoute from './AuthorizeRoutes'
import { ADMIN_CONFIGURATION, ADMIN_USER_MANAGEMENT, EXTERNAL_USER, INTERNAL_USER, EDIT_DATA_FILTER_PATH, EDIT_CLIENT_PATH, ADD_DEFAULT_USER_PATH, 
  EDIT_DEFAULT_USER_PATH, EDIT_USER_PATH, EDIT_ROLE_PATH, ADD_USER_PATH, ADD_USER_ROLE_PATH, VAULT_SECRET_PATH, SUBSCRIPTION_REPORTS_PATH } from './constants'
import ClientConfiguration from './containers/client-configuration/client-configuration'
import ReportManagement from './containers/report-management/report-management'
import DefaultTeamManagement from './containers/core-team/core-team'
import UserManagement from './containers/user-management/user-management'
import AddDefaultUserMgmt from './containers/core-team/actions-buttons/add-default-user-management'
import EditDefaultUserManagement from './containers/core-team/actions-buttons/edit-default-user-management'
import EditUserManagement from './containers/user-management/actions-buttons/edit-user-management'
import EditRoleMgmt from './containers/client-configuration/actions-buttons/edit-role-management'
import EditClientManagement from './containers/client-configuration/actions-buttons/edit-client-management/edit-client-management'
import VaultSecretManagement from './containers/client-configuration/actions-buttons/vault-secret-management/vault-secret-management'
import ErrorPage from './components/error-page/error-page'
import AddReports from './containers/report-management/actions-buttons/add-report/add-report'
import EditReports from './containers/report-management/actions-buttons/edit-report/edit-report'
import ReportsIFrame from './containers/reports-iframe'
import AddUserManagement from './containers/user-management/actions-buttons/add-user-management'
import AddUserRoleManagement from './containers/user-management/actions-buttons/add-user-role-management'
import DataFilterManagement from './containers/client-configuration/data-filters/data-filter-management'
import UserFilterManagement from './containers/client-configuration/data-filters/user-filter-management'
import ClientSubscriptionManagement from './containers/client-configuration/subscription-reports/client-subscription-management'
import UserSubscriptionManagement from './containers/client-configuration/subscription-reports/user-subscription-management'


const CustomRouter = ({ store, history, role, apiError, userDetails, mid }) => {
  if (role === ADMIN_CONFIGURATION) {

    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to={{ pathname: '/admin/report' }} />} /> 
        
        <Route path="/auth" render={() => <Redirect to={{ pathname: '/admin/report' }} />} />
        <Route path="/reports" render={() => <Redirect to={{ pathname: '/admin/report' }} />} />
        <Route path="/admin/report/addReports" component={AddReports} history={history} />
        <Route path="/admin/report/editReports" component={EditReports} history={history} />
      
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/report"
          component={ReportManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          exact
          path="/admin/client"
          component={ClientConfiguration}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/team"
          component={DefaultTeamManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/user"
          component={UserManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/client/userFilter"
          component={UserFilterManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={ADD_DEFAULT_USER_PATH}
          component={AddDefaultUserMgmt}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={EDIT_DEFAULT_USER_PATH}
          component={EditDefaultUserManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={EDIT_USER_PATH}
          component={EditUserManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={EDIT_ROLE_PATH}
          component={EditRoleMgmt}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
          <AuthorizeRoute
          store={store}
          exact
          path={ADD_USER_PATH}
          component={AddUserManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
          <AuthorizeRoute
          store={store}
          exact
          path={ADD_USER_ROLE_PATH}
          component={AddUserRoleManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          exact
          path={EDIT_CLIENT_PATH}
          component={EditClientManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          exact
          path={EDIT_DATA_FILTER_PATH}
          component={DataFilterManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          exact
          path={SUBSCRIPTION_REPORTS_PATH}
          component={ClientSubscriptionManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/client/userReport"
          component={UserSubscriptionManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          exact
          path={VAULT_SECRET_PATH}
          component={VaultSecretManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <Route exact path="/error" history={history} component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    )
  } else if (role === ADMIN_USER_MANAGEMENT || role === EXTERNAL_USER || role === INTERNAL_USER) {
    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to={{ pathname: '/reports' }} />} />
        <Route path="/auth" render={() => <Redirect to={{ pathname: '/reports' }} />} />
        <Route path="/reports" render={() => <ReportsIFrame mid={mid} role={role} history={history} userDetails={userDetails}/>} />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/report"
          component={ReportManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          exact
          path="/admin/client"
          component={ClientConfiguration}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/user"
          component={UserManagement}
          roles={[ADMIN_USER_MANAGEMENT]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/client/userFilter"
          component={UserFilterManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={EDIT_USER_PATH}
          component={EditUserManagement}
          roles={[ADMIN_USER_MANAGEMENT]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={EDIT_ROLE_PATH}
          component={EditRoleMgmt}
          roles={[ADMIN_USER_MANAGEMENT]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={ADD_USER_ROLE_PATH}
          component={AddUserRoleManagement}
          roles={[ADMIN_USER_MANAGEMENT]}
          history={history}
        />
        <AuthorizeRoute
          store={store}
          exact
          path={ADD_USER_PATH}
          component={AddUserManagement}
          roles={[ADMIN_USER_MANAGEMENT]}
          history={history}
        />
        <AuthorizeRoute
          exact
          path={EDIT_CLIENT_PATH}
          component={EditClientManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          exact
          path={EDIT_DATA_FILTER_PATH}
          component={DataFilterManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          exact
          path={SUBSCRIPTION_REPORTS_PATH}
          component={ClientSubscriptionManagement}
          history={history}
          roles={[ADMIN_CONFIGURATION]}
        />
        <AuthorizeRoute
          store={store}
          exact
          path="/admin/client/userReport"
          component={UserSubscriptionManagement}
          roles={[ADMIN_CONFIGURATION]}
          history={history}
        />
        <Route exact path="/error" history={history} component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    )
  } else if (apiError && apiError.apiError) {
    return (
      <Switch>
         <Route exact path="/error" history={history} component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    )
  }
  return <UILoader />
}

CustomRouter.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
}

const mapStateToProps = (state) => ({
  apiError: state.reducer.apiError,
})

const mapDispatchToProps = () => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomRouter))