import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, Footer, CONDENSED_FOOTER } from '@mds/mds-reactjs-library';
function CustomFooter() {
  const DIV = styled.div`
  padding: 10px 10px 0px 10px
`;

  return (
    <ThemeProvider>
      <DIV>
      <Footer type={CONDENSED_FOOTER} />
      </DIV>
    </ThemeProvider>
  );
}
export default CustomFooter;