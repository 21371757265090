import React , { useState , useEffect }from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Styles } from '../../client-configuration-table/styles';
import BackButton from '../../../../components/back-button/BackButton';
import CustomToggle from '../../../../components/custom-toggle/CustomToggle';
import UILoader from '../../../../components/ui-loader/UILoader';
import PropTypes from 'prop-types';
import {
    ThemeProvider,
    Container,
    SIZE_LARGE,
    Select,
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    TextArea,
    Tooltip,
    WARNING_ALERT_CONTEXTUAL,
    Alert,
    getAlertUniqueId
  } from '@mds/mds-reactjs-library';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import { makeStyles } from '@material-ui/core/styles';
  import { editClient, getData, setLoader } from '../../../../store/actions';

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `;
  const InputContainer = styled.div`
    margin: 40px 0 0 20px;
  `;
  const SelectStyled = styled(Select)`
    width: 500px;
    background: #fff;
    @media only screen and (max-width: 767px) {
      width: 300px;
    }
  `;
  const Label = styled.div`
    margin-bottom:5px;
  `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
  `;
  const TextAreaStyled = styled(TextArea)`
    width: 500px;
    @media only screen and (max-width: 767px) {
      width: 300px;
    }
  `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;
  const useStyles = makeStyles(() => ({
    formcontrollabel: {
      marginLeft: 'auto',
      flexDirection: 'row',
    }
  }));
  
  const EditClient = (props) => {
    const { state } = useLocation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const initialClient = {
      id: state.data.rowData.id,
      domo_client_name: state.data.rowData.domo_client_name,
      org_domains: state.data.rowData.org_domains,
    }

    const [client, setClient] = useState(initialClient);
    const [clientNameChange, setClientNameChange] = useState(false);
    const [pdpEnabled, setpdpEnabled] = useState({
      is_pdp_enabled: state.data.rowData.is_pdp_enabled
    });
    const [clientData, setClientData] = useState([]);
    
    useEffect(() => {
      dispatch(getData({history}))
      dispatch(setLoader())
    }, []);

    useEffect(() => {
      let clientDatas = [];
      if (props.cardData && Array.isArray(props.cardData)) {
        clientDatas = props.cardData && props.cardData.map((clientInfo, index) => {
          return {
            "label": clientInfo.client_name,
            "value": clientInfo.client_name,
            "key": index
          }
        })
        let preValue = {
          "label": state.data.rowData.domo_client_name,
          "value": state.data.rowData.domo_client_name,
          "key": clientDatas.length
        }
        clientDatas.push(preValue);
        setClientData(clientDatas)
      }
    },[props.cardData]) 

    const handletoggle = (event) => {
      setpdpEnabled({ ...pdpEnabled, [event.target.name]: event.target.checked });
    };

    const handleChange = name => ({
      target: { value } }) => {
      setClient({ ...client, [name]: value }
      )
    }

    const handleSelect = (event) => {
      setClientNameChange(true)
      setClient({ ...client, ['domo_client_name']: event?.target?.value })
    }

    const handleEdit = () => {
      client.is_pdp_enabled = pdpEnabled.is_pdp_enabled
      dispatch(editClient(client))
      props.history.push({
        pathname: `/admin/client`
      });
    }    


  return (
    <Styles>
    <UILoader>
      <ThemeProvider>
        <Container>
          <BackButton id="edit-client-back-btn" text="Return to Client Configuration" />
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>Edit {state.data.rowData.tenant_name}</HeadingClientName></DIV>
            <DIV><DescriptionSpan>All fields are required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              {clientNameChange && <Alert id={getAlertUniqueId()} type={WARNING_ALERT_CONTEXTUAL} onCloseClick={()=>setClientNameChange(false)} showCloseIcon={true} style={{ margin: '10px 20px 0 20px' }}>
              Any change in the Domo Client Name will reset all the Data Filter Permissions to default.
              </Alert>}
              <InputContainer>
              <Label>Domo Client Name</Label>
              <SelectStyled
                    id={client?.domo_client_name}
                    options={clientData}
                    size={SIZE_LARGE}
                    placeholder=""
                    value={client?.domo_client_name}
                    onChange={(e) => handleSelect(e)}
                />
              </InputContainer>
              <InputContainer>
                <Label>PDP Requirement</Label>
                <Tooltip content={pdpEnabled.is_pdp_enabled ? "Enabled" : "Disabled"}>
                <FormControlLabel
                className={classes.formcontrollabel}
                value="top"
                control={<CustomToggle
                  checked={pdpEnabled.is_pdp_enabled}
                  onChange={handletoggle}
                  name="is_pdp_enabled"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />}
                label=""
                labelPlacement="start"
                />
                </Tooltip>
              </InputContainer>
              <InputStyledContainer>    
              <Label>Org Domain Name</Label>    
              <TextAreaStyled data-testid="org-input" value={client.org_domains} onChange={handleChange('org_domains')} placeholder="Enter Org Domain Name" />
              <DIV>{(client.org_domains) === '' ? "Org Domain Name should not be empty" : ''}</DIV>
              </InputStyledContainer>
              </Container>
          </FreeSpaceBackground>
          <ButtonStyled id='edit-client-save-btn' appearance={PRIMARY_BUTTON} onClick={handleEdit}>Save</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => props.history.push(`/admin/client`)}>Cancel</ButtonStyled>
        </Container>
      </ThemeProvider>
    </UILoader>
  </Styles>
  );
}

EditClient.propTypes = {
  cardData: PropTypes.array,
  history: PropTypes.object
}

EditClient.defaultProps = {
  cardData: [],
  history: {}
}

const mapStateToProps = (state) => ({
  cardData: state.reducer.cardData
})

export default connect(mapStateToProps)(EditClient);