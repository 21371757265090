import React, { useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { withStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import { useHistory } from 'react-router-dom'
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Glyph32Logout from '@mds/mds-icons/icons/svg/glyph-32-logout.svg';
import { getUserInfo } from '../../store/actions';

import { connect, useDispatch } from 'react-redux'
const styles = {
  fullList: {
    width: 300
  }
};

export const SwipeableTemporaryDrawer = (props) => {
    const { mid, userInfo } = props;
    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getUserInfo(history))
    }, []);

      const fullList = () => {
        const { classes, onClose } = props;
        const userName = userInfo && userInfo?.first_name + " " + userInfo?.last_name
        return (
          <div
            className={classes.fullList}
            role="presentation"
            onClick={onClose}
            onKeyDown={onClose}
          >
            <List>
            <ListItem>
                  <ListItemIcon>
                  </ListItemIcon>
                  {userName && <ListItemText primary={userName}/>}
                </ListItem>
                <Divider />
              {["Logout"].map((text) => (
                <ListItem button key={text} onClick={() => mid.logout()}>
                  <ListItemIcon>
                  <img src={Glyph32Logout} alt="logout-icon"/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </div>
        );
      };

        const { open, onOpen, onClose } = props;
        const iOS = process.browser && /iPad|iPhone|iPod/u.test(navigator.userAgent);
        return (
          <SwipeableDrawer
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            anchor="right"
          >
            {fullList()}
          </SwipeableDrawer>
        );
}

// export default withStyles(styles)(SwipeableTemporaryDrawer);

const mapStateToProps = (state) => ({
    reports: state.reducer.reports,
    roles: state.reducer.roles,
    userInfo: state.reducer.userInfo
  })
  


export default withStyles(styles)(connect(mapStateToProps)(SwipeableTemporaryDrawer))