import React from 'react';
import { ThemeProvider, InfoModal, INFO_MODAL, SECONDARY_BUTTON } from '@mds/mds-reactjs-library';
import './delete-user.css';



function DeleteUser ({userName, onOk, onCancel}){
    let text = `Are you sure you want to remove ${userName.data.rowData.user_name} from the Core Team?
    Removal means that they will no longer be added by default to new Clients, but will not remove them as a current user from any existing Clients.`;
  return (
    <ThemeProvider >
      <InfoModal
        id="core-team-removeuser-modal"
        type={INFO_MODAL}
        className="remove-modal"
        body={text}
        okButton={{
          label: 'Yes, Remove',
          action: onOk,
        }}
        cancelButton={{
            label: 'No, Cancel',
            action: onCancel,
            appearance: SECONDARY_BUTTON
          }}
          onClose={onCancel}
      />
    </ThemeProvider>
  );
}
export default DeleteUser;