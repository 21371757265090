import React , { useState }from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Styles } from '../../client-configuration-table/styles';
import BackButton from '../../../../components/back-button/BackButton';
import UIAlert from '../../../../components/ui-alert/UIAlert'

import PropTypes from 'prop-types';
import {
    ThemeProvider,
    Container,
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    FormElementWrapper,
    Input
  } from '@mds/mds-reactjs-library';
  import { setLoader, deleteVaultSecret, saveVaultSecret } from '../../../../store/actions';
  import RemoveSecretModal from '../remove-secret-modal/remove-secret-modal'

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `;
  const InputContainer = styled.div`
    margin: 2rem;
  `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const ClearStyledButtonStyled = styled(Button)`
  margin : 2rem 0;
 `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;
  
  const VaultSecretForm = (props) => {
const { vault, history } = props
    const { state } = useLocation();
    const dispatch = useDispatch();

    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [showModal, setShowModal] = useState(false);
    

    const handleSave = () => {
        dispatch(saveVaultSecret(history, state?.data?.rowData?.tenant_id, clientId, clientSecret))
        dispatch(setLoader());
    }

    const deleteSecret = () => {
      dispatch(deleteVaultSecret(history, state?.data?.rowData?.tenant_id))
      setShowModal(false)
      setClientId('')
      setClientSecret('')
      dispatch(setLoader());
  }

  return (
    <Styles>
      <ThemeProvider>
        <Container responsive={true}>
          <BackButton id="edit-client-back-btn" text="Return to Client Configuration" />
          <UIAlert />
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>{state.data.rowData.domo_client_name} Data Policy Details</HeadingClientName></DIV>
            <DIV><DescriptionSpan>All fields are required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              <InputContainer>
              <div className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                label="Data Provider Client ID"
                                disabled= {vault?.domoClientId}
                                inputProps={{
                                    placeholder: 'Data Provider Client ID',
                                    name: 'data_provider_client_id',
                                    value: vault?.domoClientId ? vault?.domoClientId : clientId,
                                    onChange: e => setClientId(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Data Provider Client ID' : '';
                                }}

                            />
                        </div>
              </InputContainer>
              <InputContainer>
              <div className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                label="Data Provider Client Secret"
                                disabled= {vault?.domoClientSecret}
                                inputProps={{
                                    placeholder: 'Data Provider Client Secret',
                                    name: 'data_provider_client_secret',
                                    value: vault?.domoClientSecret ? vault?.domoClientSecret : clientSecret,
                                    onChange: e => setClientSecret(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Data Provider Client Secret' : '';
                                }}

                            />
                        </div>
              </InputContainer>
              </Container>
          </FreeSpaceBackground>
          {!vault?.domoClientId || !vault?.domoClientSecret ? 
          <>
          <ButtonStyled id='vault-save-btn' appearance={PRIMARY_BUTTON} disabled={!clientId || !clientSecret} onClick={handleSave}>Save</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/client`)}>Cancel</ButtonStyled>
          </> :
          <ClearStyledButtonStyled appearance={PRIMARY_BUTTON} onClick={() => { setShowModal(true) }}>Clear Values</ClearStyledButtonStyled>
          }
        </Container>
        {showModal ?(<RemoveSecretModal dialog="remove" onOk={()=> deleteSecret()} onCancel={()=>setShowModal(false)} />):null}
      </ThemeProvider>
  </Styles>
  );
}

VaultSecretForm.propTypes = {
  history: PropTypes.object
}

VaultSecretForm.defaultProps = {
  history: {}
}

const mapStateToProps = (state) => ({
  vault: state.reducer.vault
})

export default connect(mapStateToProps)(VaultSecretForm);