import Switch from '@material-ui/core/Switch';
import { withStyles } from "@material-ui/core/styles";

export const CustomToggle = withStyles((theme) => ({
    root: {
      width: 42,
      height: 20,
      padding: 0,
      display: "flex"
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(22px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#2251ff",
          borderColor: "#2251ff"
        }
      }
    },
    thumb: {
      width: 15,
      height: 15,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 12,
      opacity: 1,
      backgroundColor: theme.palette.grey[500]
    },
    checked: {}
  }))(Switch);

export default CustomToggle;