import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import SideNav from '../../components/SideNavBar';
import ReportTable from './report-table/ReportTable';
import UIAlert from '../../components/ui-alert/UIAlert';
import { getReports,setSelectedRecord ,clearReportResult, deleteReport} from '../../store/actions';


const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const ReportManagement = (props) => {
  const { logout, role, history,selectRecord ,clearReportResultAction,deleteReportAction} = props;
  const [selectedRec,setSelectedRec ]= useState(null);

  useEffect(() => {
    props.getReportData({history});
    clearReportResultAction();
  }, []);

  const removeReport=()=>{
    if(selectedRec!==null){
      deleteReportAction(selectedRec,{history});
    }
  }
  const handleSelectRecord=(data)=>{
  if(data && data.id){
    selectRecord(data);
    setSelectedRec(data.id);
  }
  }

  return (
    <DIV id="report-management-container">
        <SideNav logout={logout} role={role} />
        <ThemeProvider>
          <Container maxWidth={1250}>
          <UIAlert />
          <ClientTableDiv>
            <ReportTable reports={props.reports} history={history} selectRecord={handleSelectRecord} removeReport={removeReport}></ReportTable>
          </ClientTableDiv>
          </Container>
        </ThemeProvider>
    </DIV>
  );
}

ReportManagement.propTypes = {
  history: PropTypes.object,
  reports: PropTypes.array,
  getReportData: PropTypes.func,
  clearReportResultAction: PropTypes.func,
  deleteReportAction: PropTypes.func,
  logout: PropTypes.func,
  role: PropTypes.string
}

ReportManagement.defaultProps = {
  history: {},
  reports: [],
  getReportData: () => { },
  logout: () => { },
  role: "",
  clearReportResultAction:()=>{},
  deleteReportAction:()=>{}
}

const mapStateToProps = (state) => ({
  reports: state.reducer.reports,
  mid: state.mid,
  role: state.reducer.role
})

const mapDispatchToProps = (dispatch) => ({
  clearReportResultAction:()=> dispatch(clearReportResult()),
  selectRecord: ( payload ) => dispatch(setSelectedRecord(payload)),
  getReportData: ({history}) => dispatch(getReports({history})),
  deleteReportAction: (id,{history}) => dispatch(deleteReport(id,{history})),
  logout: () => dispatch(midActions.logout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportManagement))