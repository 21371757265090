import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  ThemeProvider,
  Grid,
  Divider,
  LIGHT_DIVIDER,
  ARTICLE_LEAD,
  Typography,
  Icon,
  TYPE_OUTLINE,
  BODY_DEFAULT,
  BODY_LARGE,
} from '@mds/mds-reactjs-library'
import { actions as midActions } from '@nvt/mid-react-saga'
import SideNav from '../../../../components/SideNavBar'
import VaultSecretForm from '../../components/vault-secret-form/vault-secret-form'

import { ActivityContainer, ActivityBody, ActivityBodyTypography } from './vault-secret-management.styles'
import { setLoader, getVaultSecret, getVaultHistory } from '../../../../store/actions'
import dateFormat from 'dateformat';
import UILoader from '../../../../components/ui-loader/UILoader';

const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `

const ClientTableDiv = styled.div`
  margin: 2rem;
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`
const TypographyStyled = styled(Typography)`
  margin-bottom: 1rem;
`
const DividerStyled = styled(Divider)`
  z-index: -1;
`

const VaultSecretManagement = (props) => {
  const { logout, role, history, vaultLogs } = props
  const { state } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoader());
    dispatch(getVaultHistory(history, state?.data?.rowData?.tenant_id))
    dispatch(getVaultSecret(history, state?.data?.rowData?.tenant_id))
  }, [])

  const formatDate = (date) => {
    return dateFormat(date,"mmmm dS, yyyy")
  }

  const formatUserName = (email) => {
    const username = email?.substring(0, email?.lastIndexOf("@"));
    return username?.replace(/[^a-zA-Z ]/gu, " ");
  }
  

  return (
    <DIV>
      <SideNav logout={logout} role={role} />
      <UILoader>
      <ThemeProvider>
        <Grid container>
          <Grid item xxs={{ span: 12 }}  lg={{ span: 8 }}>
            <ClientTableDiv>
              <VaultSecretForm history={history} />
            </ClientTableDiv>
          </Grid>
          {vaultLogs?.vaultHistory?.length > 0 && <>
          <DividerStyled type={LIGHT_DIVIDER} />
           <Grid item span={true}>
            <ActivityContainer>
              <Typography type={ARTICLE_LEAD}>Activity Log</Typography>
              {vaultLogs?.vaultHistory?.map((historyItem) => (
                <>
                  <ActivityBody>
                    <Icon size={32} type={TYPE_OUTLINE} name="calendar" />
                    <ActivityBodyTypography>
                      <TypographyStyled type={BODY_DEFAULT}>
                      {formatDate(historyItem?.updated_at)} by {formatUserName(historyItem?.updated_by)}
                      </TypographyStyled>
                      <TypographyStyled type={BODY_LARGE}>
                        Data Policy Details {historyItem?.action_type} successfully
                      </TypographyStyled>
                    </ActivityBodyTypography>
                  </ActivityBody>
                  <DividerStyled horizontal />
                </>
              ))}
            </ActivityContainer>
          </Grid>
          </>}
        </Grid>
      </ThemeProvider>
      </UILoader>
    </DIV>
  )
}

const mapStateToProps = (state) => ({
  vaultLogs: state.reducer.vaultHistory,
  vault: state.reducer.vault,
  role: state.reducer.role
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(midActions.logout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VaultSecretManagement))
