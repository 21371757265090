import Config from '../config/index'
import {
  USER_INFO_KEY
} from '../constants'
export const getDefaultHeaders = () => {
  const headers = {
    'x-tenant': sessionStorage.getItem('_mid-tenant') && JSON.parse(sessionStorage.getItem('_mid-tenant')).tenantId || Config.DEFAULT_TENANT_ID,
    Authorization: `Bearer ${sessionStorage.getItem('_mid-access-token')}`,
  };
  return headers;
};

export const setInLocalStorage = (key, payload) => {
  localStorage.setItem(key, payload.toString());
};

export const getUserFromLocalStorage = () => {
  const sample = [];
  try {
    return localStorage.getItem(USER_INFO_KEY) || sample;
  } catch (e) {
    return sample;
  }
};

const getNames = (name) => {
  let initials = name?.split(' ');
  
  if(initials?.length > 1) {
    initials = initials?.shift()?.charAt(0) + ' ' + initials?.pop()?.charAt(0);
  } else {
    initials = name?.substring(0, 2);
  }
  return initials?.toUpperCase();
}

export const getInitials = () => {
  let name = ''
  let splitUserName = ''
  const userInfo = JSON.parse(localStorage.getItem('_mid-user-info'));
  if(userInfo && userInfo.name) {
    const given_name = userInfo.given_name;
    const family_name = userInfo.family_name;
    const username = userInfo.name;
    const usernameInitials = given_name[0] +' '+ family_name[0];
  return {usernameInitials, username};
  } 
  else {
    name = userInfo?.email?.substring(0, userInfo?.email?.lastIndexOf("@"));
    splitUserName = name?.replace(/[^a-zA-Z ]/gu, " ")
    const username = splitUserName;
    const usernameInitials = getNames(username) ;
    return {usernameInitials, username};
  }
};