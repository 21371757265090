import React , { useState , useEffect }from 'react';
import { useDispatch, connect } from 'react-redux';
import styled from 'styled-components';
import { Styles } from './styles';
import BackButton from '../../../../components/back-button/BackButton';
import UILoader from '../../../../components/ui-loader/UILoader';
import { withRouter,useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import {
    ThemeProvider,
    Container,
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    SIZE_LARGE,
    Select,
  } from '@mds/mds-reactjs-library';
import { setLoader, autorizationUser, addUserRoleAutorization, getRoleList } from '../../../../store/actions';
import { ADMIN_CONFIGURATION, INTERNAL_USER, ADMIN_USER_MANAGEMENT, EXTERNAL_USER } from '../../../../constants';

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
    font-size: 24px;
    font-weight: 500;
    color: black;
    font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
    color: #000;
    margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
    display: flex;
    @media only screen and (max-width: 767px) { {
    flex-direction: column;
    }
  `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
  `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;
  const SelectStyled = styled(Select)`
  width: 500px;
  background: #fff;
  margin-bottom: 60px;
  .mds__menu {
    height: 98px;
    overflow-y: auto;
  }
  @media only screen and (max-width: 767px) {
    width: 300px;
  }
`;
const Label = styled.div`
    margin-bottom:5px;
  `;
  
  const AddUserRole = (props) => {
      const {history, authUserList, userAdd} = props
      const location = useLocation();
    const dispatch = useDispatch();
    let internalDomains = ['mckinsey.com', 'external.mckinsey.com', 'admin.mckinsey.id', 'acp.clientco1.com'];
    const [role, setRole] = useState();
    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
      dispatch(setLoader())
    userAdd && dispatch(autorizationUser(history, userAdd))
    userAdd && dispatch(getRoleList(history));
    },[userAdd]);
    useEffect(() => {
      if(role!=='' && role !== undefined){
        setIsValid(true)
      } 
      else{
        setIsValid(false)
      }
      },[role]);
    const handleSave = () => {
        dispatch(addUserRoleAutorization(history, userAdd, role))
        dispatch(setLoader())
    }

    let rolesList = [];
    let selectedUserDomain = location?.userInfo?.email.substring(location?.userInfo?.email.lastIndexOf("@") +1);
    if (props.roleList && Array.isArray(props.roleList.data)) {
      const filterTeams = internalDomains.includes(selectedUserDomain) ? props?.roleList?.data?.filter(team => team.name !== EXTERNAL_USER) : props?.roleList?.data?.filter(team => team.name !== ADMIN_USER_MANAGEMENT && team.name !== INTERNAL_USER && team.name !== ADMIN_CONFIGURATION);
      rolesList = filterTeams && filterTeams.map((data, index) => {
        return {
          "label": data.name,
          "value": data.id,
          "key": index
        }
      })
    }

  return (
    <Styles>
    <UILoader>
      <ThemeProvider>
       {userAdd && <Container>
          <BackButton id="add-userrole-back-btn" text="Return to User Management" backTo={'/admin/user'}/>
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>Role Assignment: {authUserList?.name}</HeadingClientName></DIV>
            <DIV><DescriptionSpan>Role field is required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              <InputStyledContainer>    
              <Label>Role</Label>
                <SelectStyled
                      id={role}
                      options={rolesList}
                      size={SIZE_LARGE}
                      placeholder="Select"
                      value={role}
                      onChange={(e) => setRole(e?.target?.value)}
                  />
              </InputStyledContainer>
              </Container>
          </FreeSpaceBackground>
          <ButtonStyled appearance={PRIMARY_BUTTON} onClick={handleSave} disabled={!isValid}>Save</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/user`)}>Cancel</ButtonStyled>
        </Container>}
      </ThemeProvider>
    </UILoader>
  </Styles>
  );
}

AddUserRole.propTypes = {
  history: PropTypes.object,
  roleList: PropTypes.object,
}

AddUserRole.defaultProps = {
  history: {},
  roleList: {}
}

const mapStateToProps = (state) => (
  {
    authUserList: state.reducer.authUserList,
    userAdd: state.reducer.userAdd,
    roleList: state.reducer.roleList
})

export default withRouter(connect(mapStateToProps)(AddUserRole))

