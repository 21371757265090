import React from 'react'
import styled from '@emotion/styled'
import AcademyLogo from '../../assets/img/mck-academy-rgb-black.svg'
import { getInitials } from '../../helpers/UserHelper'
import { withRouter, useHistory } from 'react-router-dom'
import Glyph32Logout from '@mds/mds-icons/icons/svg/glyph-32-logout.svg'
import PropTypes from 'prop-types';
import appConfig from '../../config'
import { connect } from 'react-redux'
import {
  Avatar,
  Header,
  HeaderIconButton,
  HeaderSideMenuItem,
  HeaderRightSectionItemWrapper,
  Container,
  FlyOutLink,
  ThemeProvider,
  Icon,
  TYPE_OUTLINE,
  Grid,
  enrichNavigationNodesWithIds,
} from '@mds/mds-reactjs-library'
import { actions as midActions } from '@nvt/mid-react-saga'
import { ADMIN_CONFIGURATION, ADMIN_USER_MANAGEMENT, INTERNAL_USER, EXTERNAL_USER } from '../../constants'
import { useMediaQuery } from 'react-responsive'

export const AppHeader = ({ logout, role, onSidebarOpen, userInfo }) => {
  const { useEffect } = React
  const [path, setPath] = React.useState('')
  const history = useHistory()


  useEffect(() => {
    const pathname = window?.location?.pathname
    const pathnameSplit = pathname?.split("/")
    if(pathname.startsWith('/acp')) {
      if(pathnameSplit[2] === 'admin') {
        setPath('Admin')
      }
      else {
        setPath('Reports')
      }
    }
    else if(pathnameSplit[1] === 'admin') {
      setPath('Admin')
    }
    else {
      setPath('Reports')
    }
  }, [role])

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  const HeaderStyled = styled(Header)`
    background: '#E6E6E6';
  `
  const AvatarIconButton = styled(HeaderIconButton)`
    width: auto;
    height: auto;
    margin-left: 14px !important;
    &:hover,
    &:focus {
      background-color: transparent;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  `
  const ButtonStyled = styled.button`
    font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
    color: #000;
    font-size: 15px;
    border: 0;
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    margin: 1rem;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  `
  const ButtonStyledMobile = styled.button`
    font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
    color: #000;
    font-size: 15px;
    border: 0;
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    padding: 0;
    position: absolute;
    left: 266px;
    top: 15px;
    @media only screen and (max-width: 767px) {
      width: 100px;
      padding-top: 20px;
      left: 250px;
    }
  `
  const SideNavMobile = styled.div`
    display: none;
  `

  const HeaderUserName = styled.h6`
    font-size: 20px;
    font-weight: 500;
    color: black;
    font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
    color: #000;
  `

  const customTheme = {
    header: {
      header: {
        background: 'transparent',
        borderColor: '#E6E6E6',
        buttonColor: '#000',
        buttonHoverColor: '#2251FF',
        buttonActiveColor: '#2251FF',
        activeBorderColor: '#2251FF',
        flyOutBackground: '#fff',
        hoverFocusIconBackgroundColor: '#F0F0F0',
        activeIconBackgroundColor: '#D0D0D0',
        hamburgerIconColor: '#000',
        customLogoColor: '#051C2C',
        sideMenu: {
          background: '#fff',
          activeButtonBackground: '#E6E6E6',
          hoveredButtonBackground: '#F0F0F0',
          separatorColor: '#E6E6E6',
          selectedItemBackground: '#F0F0F0',
        },
      },
    },
  }
  const tenantName = JSON.parse(sessionStorage.getItem('_mid-tenant'))?.displayName
  let navConfig = []
  // let sortReports = []
  // if (clientSubscribedReport && Array.isArray(clientSubscribedReport.reports)) {
  //   sortReports =
  //     clientSubscribedReport?.reports &&
  //     clientSubscribedReport.reports.sort((a, b) => {
  //       return a && a.global_report_name && a.global_report_name.localeCompare(b.global_report_name)
  //     })
  // }
  if (!isMobile) {
    if (
      (role && role === INTERNAL_USER) ||
      (role && role === EXTERNAL_USER) ||
      (role && role === ADMIN_USER_MANAGEMENT)
    ) {
      navConfig.push({
        url: '/reports',
        label: 'Reports',
        topGroupLevel: true,
        locator: () => path === 'Reports',
        onClick: () => setPath('Reports', history.push('/auth')),
        // flyOut: clientSubscribedReport?.reports.length > 1 && (
        //   <div style={{ maxWidth: 700 }}>
        //     {clientSubscribedReport.reports &&
        //       clientSubscribedReport.reports &&
        //       clientSubscribedReport.reports.map((report) => (
        //         <FlyOutLink
        //           onClick={() =>
        //             setPath(report.global_report_name, history.push(`/reports/${report.global_report_name}/${report.id}`))
        //           }
        //         >
        //           {report.global_report_name}
        //         </FlyOutLink>
        //       ))}
        //   </div>
        // ),
        // items:
        //   sortReports &&
        //   sortReports?.map((report) => {
        //     return {
        //       label: report.global_report_name,
        //       locator: () => path === report.global_report_name,
        //       onClick: () => setPath(report.global_report_name, history.push(`/reports/${report.global_report_name}/${report.id}`)),
        //     }
        //   }),
      })
    }

    if (role && role === ADMIN_CONFIGURATION) {
      navConfig.push({
        label: 'Admin',
        topGroupLevel: true,
        locator: () => path === 'Admin',
        onClick: () => setPath('Admin', history.push('/admin/report')),
      })
    }
    if (role && role === ADMIN_USER_MANAGEMENT) {
      navConfig.push({
        label: 'User Management',
        locator: () => path === 'User Management' || 'Admin',
        onClick: () => setPath('User Management', history.push('/admin/user')),
      })
    }
    if (role === '') {
      navConfig = []
    }
  } else {
    if (
      (role && role === INTERNAL_USER) ||
      (role && role === EXTERNAL_USER) ||
      (role && role === ADMIN_USER_MANAGEMENT)
    ) {
      navConfig.push({
        label: 'Reports',
        topGroupLevel: true,
        locator: () => path === 'Reports',
        onClick: () => setPath('Reports', history.push('/admin/report')),
        // flyOut: clientSubscribedReport?.reports.length > 1 && (
        //   <div style={{ maxWidth: 700 }}>
        //     {clientSubscribedReport.reports &&
        //       clientSubscribedReport.reports &&
        //       clientSubscribedReport.reports.map((report) => (
        //         <FlyOutLink
        //           onClick={() =>
        //             setPath(report.global_report_name, history.push(`/reports/${report.global_report_name}/${report.id}`))
        //           }
        //         >
        //           {report.global_report_name}
        //         </FlyOutLink>
        //       ))}
        //   </div>
        // ),
        // items:
        //   sortReports &&
        //   sortReports?.map((report) => {
        //     return {
        //       label: report.global_report_name,
        //       locator: () => path === report.global_report_name,
        //       onClick: () => setPath(report.global_report_name, history.push(`/reports/${report.global_report_name}/${report.id}`)),
        //     }
        //   }),
      })
    }

    if (role && role === ADMIN_CONFIGURATION) {
      navConfig.push({
        label: 'Admin',
        topGroupLevel: true,
        locator: () => path === 'Admin',
        onClick: () => setPath('Admin', history.push('/admin/report')),
        flyOut: (
          <SideNavMobile style={{ maxWidth: 700 }}>
            <Grid container>
              <Grid container item direction="column" span={4}>
                <FlyOutLink> Report Management </FlyOutLink>
                <FlyOutLink> Client Configuration </FlyOutLink>
                <FlyOutLink> Core Team </FlyOutLink>
                <FlyOutLink> User Management </FlyOutLink>
                <FlyOutLink> Tenant Management </FlyOutLink>
              </Grid>
            </Grid>
          </SideNavMobile>
        ),
        items: [
          {
            label: 'Report Management',
            locator: () => path === 'Report Management',
            onClick: () => setPath('Report Management', history.push('/admin/report')),
          },
          {
            label: 'Client Configuration',
            locator: () => path === 'Client Configuration',
            onClick: () => setPath('Client Configuration', history.push('/admin/client')),
          },
          {
            label: 'Core Team',
            locator: () => path === 'Core Team',
            onClick: () => setPath('Core Team', history.push('/admin/team')),
          },
          {
            label: 'User Management',
            locator: () => path === 'User Management',
            onClick: () => setPath('User Management', history.push('/admin/user')),
          },
          {
            label: 'Tenant Management',
            locator: () => path === 'Tenant Management',
            onClick: () => setPath('Tenant Management', window.open(appConfig.REQUEST_NEW_TENANT_LINK, '_blank')),
          },
        ],
      })
    }
    if (role && role === ADMIN_USER_MANAGEMENT) {
      navConfig.push({
        label: 'User Management',
        locator: () => path === 'User Management',
        onClick: () => setPath('User Management', history.push('/admin/user')),
      })
    }
    if (role === '') {
      navConfig = []
    }
  }

  navConfig = enrichNavigationNodesWithIds(navConfig)
  const userName = userInfo && userInfo?.first_name + " " + userInfo?.last_name
  return (
    <div>
      <style> {'body { margin: 0px; padding: 0px !important; }'} </style>
      {isMobile && (
        <Container responsive maxWidth="850">
          <ButtonStyledMobile>{tenantName}</ButtonStyledMobile>
        </Container>
      )}
      <ThemeProvider customTheme={customTheme}>
        <HeaderStyled
          logoSection={<img onClick={() => setPath('Admin', history.push('/'))} src={AcademyLogo} />}
          navConfig={navConfig}
          rightSection={[
            <HeaderRightSectionItemWrapper
              key="alerts"
              label="Logout"
              tooltip={false}
              aria-label="Alerts"
              onClick={logout}
              verticalLayoutComponent={({ label, ...otherProps }) => (
                <>
                  <HeaderSideMenuItem
                    {...otherProps}
                    icon={<Avatar alt={getInitials() && getInitials().usernameInitials} />}
                  >
                    <HeaderUserName>{getInitials() && getInitials().username}</HeaderUserName>
                  </HeaderSideMenuItem>
                  <HeaderSideMenuItem {...otherProps} icon={<Icon type={TYPE_OUTLINE} src={Glyph32Logout} />}>
                    {label}
                  </HeaderSideMenuItem>
                </>
              )}
            />,
            <HeaderRightSectionItemWrapper
              key="avatar"
              aria-label="Profile"
              mobile
              order={1}
              onClick={onSidebarOpen}
              horizontalLayoutComponent={(props) => (
                <>
                <Container responsive maxWidth="850">
                <ButtonStyled>{tenantName}</ButtonStyled>
              </Container>
                <AvatarIconButton {...props}>
                  {userName && <Avatar alt={userName} />}
                </AvatarIconButton>
                </>
              )}
            />,
          ]}
        />
      </ThemeProvider>
      <div
        style={{
          fontFamily: '"McKinsey Sans","Helvetica Neue",Calibri,Corbel,Helvetica,Roboto,Droid,sans-serif',
          fontSize: '25px',
          width: '100%',
          height: '100%',
          // minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
    </div>
  )
}

AppHeader.propTypes = {
  logout: PropTypes.func,
  role: PropTypes.string
}

AppHeader.defaultProps = {
  logout: () => { },
  role: ""
}

const mapStateToProps = (state) => ({
  reports: state.reducer.reports,
  mid: state.mid,
  apiError: state.reducer.apiError,
  userInfo: state.reducer.userInfo
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(midActions.logout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader))