import * as constants from './constants'

export const getEmbedToken =  (reportName, clientId, userId, history) => ({
  type: constants.GET_EMBED_TOKEN,
  payload:{
    reportName,clientId, userId, history
  },
})

/* export const getEmbedToken =  payload => ({
  type: constants.GET_EMBED_TOKEN,
  payload,
}) */

export const getUserDetails = () => ({
  type: constants.GET_USER_DETAILS
})
export const getTenants = (payload) => ({
  type: constants.GET_TENANT_DETAILS,
  payload
})
export const getRoles = (payload) => ({
  type: constants.GET_ROLES,
  payload
})
export const getRolesSuccess = (payload) => ({
  type: constants.GET_ROLES_SUCCESS,
  payload
})

export const getData = (payload) => ({
  type: constants.GET_DATA,
  payload,
})

export const getCSV = (payload) => ({
  type: constants.GET_CSV,
  payload,
})

export const filterDashboard = (reportName,filtervalue) => ({
  type: constants.FILTER_DATA,
  payload:{
    reportName,filtervalue
  },
})
export const testFetch = (payload) => ({
  type: constants.TEST_FETCH,
  payload,
})

export const antivirusCheck = (payload) => ({
  type: constants.ANTIVIRUS_CHECK,
  payload,
})

// Admin/Report
export const getReports = (payload) => ({
  type: constants.GET_REPORT,
  payload,
})

export const addReport = (payload) => ({
  type: constants.ADD_REPORT,
  payload,
})

export const deleteReport = (id, payload) => ({
  type: constants.DELETE_REPORT,
  id, payload
})

export const editReport = (payload) => ({
  type: constants.EDIT_REPORT,
  payload,
})
export const clearReportResult = ()=>({
  type: constants.CLEAR_ADD_EDIT_REPORT_STATE
})

// Admin/Client
export const getClientDetails = (payload) => ({
  type: constants.GET_CLIENT,
  payload
})

export const getClientReport = (id, payload) => ({
  type: constants.GET_CLIENT_REPORT,
  id, payload
})

export const getClientSubscribedReport = (clientId, userId) => ({
  type: constants.GET_CLIENT_SUBSCRIBED_REPORT,
  clientId, userId
})

export const getCustomEmbedFilters = (selectedReportId, payload) => ({
  type: constants.GET_CUSTOM_EMBED_FILTERS,
  selectedReportId, payload
})

 export const postCustomEmbedFilters = (selectedReportId, selectedFilters) => ({
   type: constants.POST_CUSTOM_EMBED_FILTERS,
   selectedReportId,
   selectedFilters
 })

export const subscribeReport = (payload, id) => ({
  type: constants.SUBSCRIBE_REPORT,
  payload, id
})

export const userSubscribeReport = (clientId, userId, history) => ({
  type: constants.GET_USER_SUBSCRIBED_REPORT,
  clientId, userId, history
})

export const editUserSubscription = (payload, userId, clientId) => ({
  type: constants.EDIT_USER_SUBSCRIBED_REPORT,
  payload, userId, clientId
})

export const unSubscribeReport = (payload) => ({
  type: constants.UNSUBSCRIBE_REPORT,
  payload
})

export const addClient = (payload) => ({
  type: constants.ADD_CLIENT,
  payload,
})

export const deleteClient = (id, payload) => ({
  type: constants.DELETE_CLIENT,
  id, payload
})

export const editClient = (payload) => ({
  type: constants.EDIT_CLIENT,
  payload,
})

export const getDataFilter = (id, history) => ({
  type: constants.GET_CLIENT_DATA_FILTER,
  id, history
})

export const editDataFilter = (payload, clientId) => ({
  type: constants.EDIT_CLIENT_DATA_FILTER,
  payload, clientId
})

export const getUserFilter = (clientId, userId, history) => ({
  type: constants.GET_USER_DATA_FILTER,
  clientId, userId, history
})

export const editUserFilter = (payload, userId, clientId) => ({
  type: constants.EDIT_USER_DATA_FILTER,
  payload, userId, clientId
})

export const getClientUserList = (clientId, history) => ({
  type: constants.GET_CLIENT_USER_DATA,
  clientId, history
})

export const getClientReportRanking = (clientId) => ({
  type: constants.GET_CLIENT_RANKING_REPORT,
  clientId
})

export const saveClientReportRanking = (payload, clientId) => ({
  type: constants.SAVE_CLIENT_RANKING_REPORT,
  payload, clientId
})

//Admin/defaultTeam

export const getDefaultTeam = (payload) => ({
  type: constants.GET_DEFAULT_TEAM,
  payload
})

export const addDefaultUser = (payload) => ({
  type: constants.ADD_DEFAULT_TEAM_USER,
  payload
})

export const editDefaultUser = (payload) => ({
  type: constants.EDIT_DEFAULT_TEAM_USER,
  payload
})

export const deleteDefaultUser = (id, payload) => ({
  type: constants.DELETE_DEFAULT_TEAM_USER,
  id, payload
})

//Admin/userManagement

export const getUserList = (payload) => ({
  type: constants.GET_USER_MGMT_LIST,
  payload
})

export const getRoleList = (payload) => ({
  type: constants.GET_ROLES_LIST,
  payload
})

export const getUserRole = (payload, id) => ({
  type: constants.GET_USER_ROLE,
  payload, id
})

export const autorizationUser = (payload, userAdd) => ({
  type: constants.GET_AUTORIZATION_USER,
  payload,
  userAdd
})
export const addUserRoleAutorization = (payload, userAdd, role) => ({
  type: constants.ADD_USER_ROLE_AUTORIZATION,
  payload,
  userAdd,
  role
})
export const addUser = (payload, tenantName) => ({
  type: constants.ADD_USER,
  payload,
  tenantName
})
export const editUser = (payload) => ({
  type: constants.EDIT_USER,
  payload
})

export const resetPassword = (payload) => ({
  type: constants.USER_RESET_PWD,
  payload
})

export const deleteUser = (userInfo, payload) => ({
  type: constants.DELETE_USER,
  userInfo, payload
})

// Notifications
export const setNotification = payload => ({
  type: constants.SET_NOTIFICATION,
  payload,
})
export const setFailed = message => ({
  type: constants.SET_NOTIFICATION,
  payload: {
    type: constants.types.FAILED,
    display: true,
    message,
  },
})

export const setApiFailed= ({message, apiError}) => ({
  type: constants.API_FAILED,
  payload: {
    message,
    apiError: apiError
  },
})
export const setSuccess = message => ({
  type: constants.SET_NOTIFICATION,
  payload: {
    type: constants.types.SUCCESS,
    display: true,
    message,
  },
})
export const setActivity = message => ({
  type: constants.SET_NOTIFICATION,
  payload: {
    type: constants.types.ACTIVITY,
    display: true,
    message,
  },
})
export const setAlert = message => ({
  type: constants.SET_NOTIFICATION,
  payload: {
    type: constants.types.ACTIVITY,
    display: true,
    message,
  },
})
export const clearNotification = () => ({
  type: constants.CLEAR_NOTIFICATION,
})

export const setAlertAction = msg => {
  return {
      type: constants.SET_ALERT,
      msg
  }
}

export const resetAlertAction = () => {
  return {
      type: constants.RESET_ALERT
  }
}

export const setLoader = ()  => {
  return {
      type: constants.SET_LOADER,
      isLoading: true
  }
}

export const hideLoader = () => {
  return{
      type: constants.HIDE_LOADER,
      isLoading: false
  }
}

export const setSelectedRecord =(payload)=>{
  return {
    type: constants.SET_SELECTED_REPORT,
    payload
  }
}

export const setSelectedUser =(payload)=>{
  return {
    type: constants.SET_SELECTED_USER,
    payload
  }
}

export const setSelectedClient =(payload)=>{
  return {
    type: constants.SET_SELECTED_CLIENT,
    payload
  }
}

// vault

export const getVaultSecret = (history, tenantId) => ({
  type: constants.GET_VAULT_SECRET,
  history,
  tenantId
})

export const deleteVaultSecret = (history, tenantId) => ({
  type: constants.DELETE_VAULT_SECRET,
  history,
  tenantId
})

export const saveVaultSecret = (history, tenantId, clientId, clientSecret) => ({
  type: constants.SAVE_VAULT_SECRET,
  history,
  tenantId,
  clientId,
  clientSecret
})


export const getVaultHistory = (history, tenantId) => ({
  type: constants.GET_VAULT_HISTORY,
  history,
  tenantId
})

export const getUserInfo = (history) => ({
  type: constants.GET_USER_INFO,
  history
})