import styled from '@emotion/styled';
import { actions as midActions } from '@nvt/mid-react';
import PropTypes from 'prop-types'

import React from 'react'
import { useLocation , withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux'
import EditUserSubscription from '../components/edit-user-subscription/EditUserSubscription';
import UIAlert from '../../../components/ui-alert/UIAlert';
import { userSubscribeReport, setLoader } from '../../../store/actions';

import BackButton from '../../../components/back-button/BackButton';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import SideNav from '../../../components/SideNavBar';


const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const HeadingReportName = styled.h2`
  font-size: 30px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 26px 0 16px 0;
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const UserSubscriptionManagement = (props) => {
    const { history, logout, role } = props;
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { useEffect } = React;
    let selectedClient = props?.selectedClient ? props?.selectedClient : JSON.parse(sessionStorage.getItem('selectedClient'))
    let tenant_name = "Return to " + selectedClient.tenant_name + " user list"

    useEffect(() => {
      let id = selectedClient.tenant_id;
      dispatch(userSubscribeReport(id,state.email,history));
      dispatch(setLoader());  
    }, []);
    return (
         <DIV>
         <SideNav logout={logout} role={role} />
         <Container maxWidth="xl">
             <ThemeProvider>
             <BackButton text={tenant_name} />
                 <DIV>
                 <HeadingReportName>{state.first_name} {state.last_name} Reports</HeadingReportName>
                 </DIV>
                 <ClientTableDiv>
                 <UIAlert />
                 <EditUserSubscription userId={state.email} history={history}></EditUserSubscription>
                 </ClientTableDiv>    
             </ThemeProvider>
           </Container>
       </DIV>
      );
    }


    UserSubscriptionManagement.propTypes = {
    history: PropTypes.object,
    logout: PropTypes.func,
    role: PropTypes.string
    }

    UserSubscriptionManagement.defaultProps = {
    history: {},
    logout: () => { },
    role: ""
    }


  const mapStateToProps = (state) => ({
    userReport: state.reducer.userReport,
    mid: state.mid,
    role: state.reducer.role,
    loading: state.reducer.loading,
    selectedClient: state.reducer.selectedClient
  })

  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout())
  })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSubscriptionManagement))