import React, { useEffect, useState } from 'react'
import {
  WARNING_ALERT_CONTEXTUAL,
  Alert,
  getAlertUniqueId,
  Container,
  HEADING_SIX,
  Typography,
  SIZE_MEDIUM,
  Checkbox,
  Tooltip,
  DropdownButton,
  DropdownItem,
} from '@mds/mds-reactjs-library'
import chroma from 'chroma-js'
import { connect, useDispatch } from 'react-redux'
import { getDefaultHeaders } from '../../helpers/UserHelper'
import PropTypes from 'prop-types'
import Select, { components, createFilter } from 'react-select'
import styled from '@emotion/styled'
import UILoader from '../../components/ui-loader/UILoader'
import {
  getEmbedToken,
  getClientSubscribedReport,
  setLoader,
  postCustomEmbedFilters,
  getCustomEmbedFilters,
} from '../../store/actions'
import Loadergif from '../../assets/img/loading-icon-animated-gif.jpg'
import { ADMIN_USER_MANAGEMENT, INTERNAL_USER, EXTERNAL_USER } from '../../constants'
import { SelectFieldContainer, CustomDropdownMenu, OptionsContainer } from './styles'


const CustomSelectComponent = ({
  filterTitle,
  value,
  options,
  onSelect,
  selectMenuOpen,
  selectMenuHandler,
  onBlurHandler,
  Loading,
  applyFiltersHandler
}) => {
  const Wrapper = styled.div`
    width: fit-content;
  `
  const SelectStyled = styled(Select)`
    width: 16.5rem !important;
    margin: 1rem;
    @media only screen and (min-width: 1600px) {
      width: 20.5rem !important;
    }
    @media only screen and (max-width: 770px) {
      width: 100% !important;
      margin: 1rem 0;
    }
  `

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: 0 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color ?? 'black')
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
        },
      }
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'white',
        borderColor: '#2251FF',
        borderRadius: '1rem',
        border: '1px solid #2251FF',
        maxWidth: '4.3rem',
      }
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#2251FF',
      padding: 0,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#2251FF',
      backgroundColor: '#white',
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  }

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: 'auto',
      fontSize: '1rem',
      order: 99,
      color: '#2251FF',
    }

    const title = items.join(', ')
    const length = items.length
    const label = `+ ${length}`

    return (
      <div style={style} title={title}>
        {label}
      </div>
    )
  }

  const Option = (otherProps) => {
    return (
      <components.Option {...otherProps}>
        <OptionsContainer>
          <Checkbox checked={otherProps.isSelected} size={SIZE_MEDIUM} value="medium" />
          <label>{otherProps.label}</label>
        </OptionsContainer>
      </components.Option>
    )
  }

  const MultiValue = ({ index, getValue, ...otherProps }) => {
    const maxToShow = 2
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label)

    return index < maxToShow ? (
      <Tooltip content={otherProps.data.label}>
        <Wrapper>
          <components.MultiValue {...otherProps} />
        </Wrapper>
      </Tooltip>
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null
  }

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) => {
    return !isDisabled ? (
      <>
        <CustomDropdownMenu>
          <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
            {children}
            <button
                    className="btn btn-primary btn-sm btn-block"
                    onClick={applyFiltersHandler}
                >Apply</button>
          </div>
        </CustomDropdownMenu>
      </>
    ) : null
  }


  return options ? (
    <SelectStyled
      onMenuOpen={selectMenuHandler}
      autoFocus={!Loading ? selectMenuOpen : false}
      menuIsOpen={!Loading ? selectMenuOpen : false}
      onBlur={onBlurHandler}
      closeMenuOnSelect={false}
      styles={colourStyles}
      isMulti
      isLoading={Loading}
      hideSelectedOptions={false}
      components={{
        Menu: CustomMenu,
        IndicatorSeparator: () => null,
        Option,
        MultiValue
      }}
      filterOption={createFilter({ ignoreAccents: false })}
      placeholder={filterTitle}
      value={value}
      options={options.sort((a, b) => {
        return a && a.label && a.label.localeCompare(b.label)
      })}
      onChange={onSelect}
    />
  ) : (
    <></>
  )
}

const IFrame = (props) => {
  return (
    <iframe 
    title="reports"
    id="iframe"
    srcDoc={props.src}
    width="100%"
    height="1620"
    marginHeight="0"
    marginWidth="0"
    frameBorder="0"
    ></iframe>
  )
}

const ReportsIFrame = (props) => {
  const { clientSubscribedReport, embedDetails, history, userInfo, role, customEmbedFilters, loading } = props
  const dispatch = useDispatch()
  const generateInitialState = (numberOfSelects) => numberOfSelects.map(() => null)
  const [filterValues, setFilterValues] = useState([])
  const [removedFilterValues, setRemovedFilterValues] = useState([])
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [menuOpen, setMenuOpen] = useState([false, false, false, false])
  const [applyIframeFilters, setApplyIframeFilters] = useState(false)
  const [iframeSource, setIframeSource] = useState()
  const [selectedFilterValues, setSelectedFilterValues] = useState([
    {
      label: '*Custom Field 1',
      values: [],
    },
    {
      label: '*Custom Field 2',
      values: [],
    },
    {
      label: '*Custom Field 3',
      values: [],
    },
    {
      label: '*Custom Field 4',
      values: [],
    },
  ])
  const [selectedReport, setSelectedReport] = useState()
  const [selectState, setSelectState] = useState(() =>
    // increase the array elements to add more select fields
    generateInitialState(['*Custom Field 1', '*Custom Field 2', '*Custom Field 3', '*Custom Field 4']),
  )

  const fetchReports = () => {
    if (
      (role && role === INTERNAL_USER) ||
      (role && role === EXTERNAL_USER) ||
      (role && role === ADMIN_USER_MANAGEMENT)
    ) {
      userInfo && dispatch(getClientSubscribedReport(getDefaultHeaders()['x-tenant'], userInfo?.email))
    }
  }

  useEffect(() => {
    dispatch(setLoader())
    if (userInfo && !clientSubscribedReport && !selectedReport) {
      fetchReports()
    }
    if (clientSubscribedReport) {
      dispatch(getCustomEmbedFilters(selectedReport?.id || clientSubscribedReport.reports[0].id))
      dispatch(
        getEmbedToken(
          selectedReport?.value || clientSubscribedReport.reports[0].global_report_name,
          userInfo?.id,
          userInfo?.email,
          history,
        ),
      )
    }
  }, [clientSubscribedReport, selectedReport, userInfo])


  const applyFilters = () => {
    setApplyIframeFilters(true)
    setMenuOpen(false)
    const removeUndefinedValues = filterValues.filter(filterValue => filterValue !== undefined)
    setIframeSource(`${embedDetails?.embedUrl}?pfilters=` + encodeURIComponent(JSON.stringify(removeUndefinedValues)))
  }


  useEffect(() => {
    if(loading) {
      setFilterValues(true)
    } else {
      setFiltersLoading(loading)
    }
  }, [customEmbedFilters])


useEffect(() => {
  if(removedFilterValues?.length > 0) {
    const removeUndefinedValues = removedFilterValues.filter(filterValue => filterValue !== undefined || null)
    setApplyIframeFilters(true)
    setIframeSource(`${embedDetails?.embedUrl}?pfilters=` + encodeURIComponent(JSON.stringify(removeUndefinedValues)))
  }
  else {
    setIframeSource(embedDetails?.embedUrl)
  }
}, [embedDetails, removedFilterValues])

  const TypographyStyled = styled(Typography)`
    margin: 1rem 0 0 1rem !important;
  `
  const DropdownButtonStyled = styled(DropdownButton)`
    background-color: white !important;
    color: black !important;
    font-weight: 500;
    font-size: 1.75rem !important;
    margin-top: 1rem;
  `

  const DropdownItemStyled = styled(DropdownItem)`
    width: 18rem;
  `


  const reportsNavigate = (value, id) => {
    setSelectedReport({ value, id })
  }


  const onSelectValues = (value, index, option, triggeredAction) => {
    const clonedSelectState = JSON.parse(JSON.stringify(selectState))

    clonedSelectState[index] = value
    setSelectState(clonedSelectState)
    const selectValues = value.map((selectedValues) => selectedValues.value)

    setSelectedFilterValues(() => {
      let newFields = [...selectedFilterValues]
      newFields[index] = {
        ...selectedFilterValues[index],
        label: option,
        values: selectValues,
      }
      return newFields
    })

    setFilterValues(() => {
      let newFields = [...filterValues]
      newFields[index] = {
        ...filterValues[index],
        column: option,
        values: selectValues,
        filterType: 'LEGACY',
        operand: 'IN',
      }
      return newFields
    })
    if(triggeredAction.action === 'remove-value' || triggeredAction.action === 'clear') {
      const removedFilters = value.filter((selectedValue) => 
      selectedValue.value !== triggeredAction.removedValue.value)
      const mappedRemovedValues = removedFilters.map((selectedValues) => selectedValues.value)
        setRemovedFilterValues(() => {
          let newFields = [...filterValues]
          newFields[index] = {
            ...filterValues[index],
            column: option,
            values: mappedRemovedValues,
            filterType: 'LEGACY',
            operand: 'IN',
          }
          return newFields
        })
        setApplyIframeFilters(true)
    }
  }

  const onSelectHandler = (menuOpenValue, index, option) => {
    let removeArray = {
      label: option,
      values: []
    };

    let filteredRequest = selectedFilterValues.filter(element => element.label !== removeArray.label);

    const menuSelectedState = []
    if(!menuOpen[index]) {
      menuSelectedState[index] = menuOpenValue
      setFiltersLoading(true);
      dispatch(postCustomEmbedFilters(selectedReport?.id || clientSubscribedReport.reports[0].id, filteredRequest))
    }
    else {
      menuSelectedState[index] = false
    }
    setMenuOpen(menuSelectedState);
  }

  const onMenuBlur = () => {
    setMenuOpen(false)
    setApplyIframeFilters(false)
  }


    const source =
    embedDetails &&
    '' +
      '<html>\n' +
      '   <body>\n' +
      '        <form id="form" action="' +
    iframeSource +
      '" method="post">\n' +
      '        <input type="hidden" name="embedToken" value="' +
      embedDetails?.embedToken +
      '">\n' +
      '        </form>\n' +
      '        <script>\n' +
      '        document.getElementById("form").submit();\n' +
      '        </script>\n' +
      '    </body>\n' +
      '</html>\n'

  return (
    <UILoader>
      <Container maxWidth="xl" responsive>
        {clientSubscribedReport && clientSubscribedReport?.reports && clientSubscribedReport?.reports?.length > 0 ? (
          <div
            style={{
              backgroundImage: 'url(' + Loadergif + ')',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center',
              backgroundSize: '400px 300px',
            }}
          >
            <DropdownButtonStyled
              label={selectedReport ? selectedReport?.value : clientSubscribedReport?.reports[0].global_report_name}
            >
              {clientSubscribedReport &&
                clientSubscribedReport?.reports
                  ?.filter(
                    (assignedReport) =>
                      assignedReport?.id !== (selectedReport?.id || clientSubscribedReport?.reports[0]?.id),
                  )
                  .map(({ global_report_name, id }) => (
                    <DropdownItemStyled key={id} onClick={() => reportsNavigate(global_report_name, id)}>
                      {global_report_name}
                    </DropdownItemStyled>
                  ))}
            </DropdownButtonStyled>
            {customEmbedFilters?.length > 0 && <TypographyStyled type={HEADING_SIX}>Filters</TypographyStyled>}
            <SelectFieldContainer>
              {customEmbedFilters?.map((option, index) => {
                return (
                  <CustomSelectComponent
                    filterTitle={option?.filterName}
                    value={selectState[index]}
                    options={option?.filterValues}
                    onSelect={(value, triggeredAction) => onSelectValues(value, index, option?.filterName, triggeredAction)}
                    key={index}
                    selectMenuHandler={() => onSelectHandler(true, index, option?.filterName)}
                    selectMenuOpen={menuOpen[index]}
                    onBlurHandler={onMenuBlur}
                    Loading= {filtersLoading}
                    applyFiltersHandler={applyFilters}
                  />
                )
              })}
            </SelectFieldContainer>
            <UILoader>
              {embedDetails && embedDetails?.embedToken ? (
              <IFrame dispatchFilters={applyIframeFilters} src={source}/>
              ) : (
                <Alert
                  id={getAlertUniqueId()}
                  type={WARNING_ALERT_CONTEXTUAL}
                  showCloseIcon={false}
                  style={{ margin: '12px' }}
                >
                  It looks like you&apos;re not yet authorized to access this page. Please contact support:
                  mcka-support-portal@mckinsey.com. error_embed_token
                </Alert>
              )}
            </UILoader>
          </div>
        ) : (
          <Alert
            id={getAlertUniqueId()}
            type={WARNING_ALERT_CONTEXTUAL}
            showCloseIcon={false}
            style={{ margin: '12px' }}
          >
            No Reports to Display
          </Alert>
        )}
      </Container>
    </UILoader>
  )
}

ReportsIFrame.propTypes = {
  history: PropTypes.object,
  mid: PropTypes.object,
}

ReportsIFrame.defaultProps = {
  history: {},
  mid: {},
}

const mapStateToProps = (state) => ({
  embedDetails: state.reducer.embedDetails,
  clientSubscribedReport: state.reducer.clientSubscribedReport,
  userInfo: state.reducer.userInfo,
  customEmbedFilters: state.reducer.customEmbedFilters,
  loading: state.reducer.loading
})

export default connect(mapStateToProps)(ReportsIFrame)
