import React from 'react'
import { connect } from 'react-redux'
import {LoaderContainer} from './UILoader.styles.js';
import { CircularProgress, SIZE_EXTRA_LARGE } from '@mds/mds-reactjs-library';

const UILoader = props => {
    const { loader } = props
    return (
        <>
            {loader ? <LoaderContainer>
                <CircularProgress size={SIZE_EXTRA_LARGE} indeterminate />
            </LoaderContainer> :
                <>{props.children}</>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader
    }
}

export default connect(mapStateToProps)(UILoader)