import styled from '@emotion/styled';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types'

import React from 'react'

import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SideNav from '../../components/SideNavBar';
import UserTable from './user-management-table/user-management-table';
import UIAlert from '../../components/ui-alert/UIAlert';
import { getUserList, setLoader, setSelectedUser } from '../../store/actions';


const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const UserManagement = (props) => {
  const { logout, role, history, userInfo } = props;
  const { useEffect } = React;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader());
    userInfo && dispatch(getUserList(history));
   }, [props.editStatus, userInfo]);
   const handleSelectedUser=(data)=>{
     dispatch(setSelectedUser(data))
    }
    const tenantName = JSON.parse(sessionStorage.getItem('_mid-tenant'))?.displayName
  return (
    <DIV id="user-management-container">
        {tenantName.startsWith('Default') && <SideNav logout={logout} role={role} />}
        <ThemeProvider>
          <Container maxWidth={1250}>
          <UIAlert />
          <ClientTableDiv>
           <UserTable history={history} selectRecord={handleSelectedUser} fromFilter={false}></UserTable>
          </ClientTableDiv>
          </Container>
        </ThemeProvider>
      </DIV>
  );
  }

  UserManagement.propTypes = {
    history: PropTypes.object,
    editStatus: PropTypes.string,
    logout: PropTypes.func,
    role: PropTypes.string
  }
  
  UserManagement.defaultProps = {
    history: {},
    editStatus: "",
    logout: () => { },
    role: ""
  }
  
  
  const mapStateToProps = (state) => ({
    userList: state.reducer.userList,
    editStatus: state.reducer.editStatus,
    mid: state.mid,
    role: state.reducer.role,
    loading: state.reducer.loading,
    userInfo: state.reducer.userInfo
  })
  
  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout())
  })
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserManagement))
