import React from 'react'
import styled from '@emotion/styled'
import { ThemeProvider, Button, PRIMARY_BUTTON, Container, DISPLAY_SIX, Typography } from '@mds/mds-reactjs-library'
import { HeroStyles, HeroBodyStyles, ImageStyles } from './welcome-page.styles'

const Welcomepage = (props) => {
  const { onLoginHandler, errorMessage } = props
  const ButtonStyled = styled(Button)`
    width: 14rem;
    margin-top: 2rem; ;
  `

  return (
    <ThemeProvider>
      <HeroStyles>
        <HeroBodyStyles>
          <Container maxWidth={850}>
            <Typography type={DISPLAY_SIX} mobile>
              {' '}
              McKinsey Academy Client Portal
            </Typography>
            <div>
              {errorMessage && <h6>{errorMessage}</h6>}
              <ButtonStyled onClick={onLoginHandler} appearance={PRIMARY_BUTTON}>
                Login
              </ButtonStyled>
            </div>
          </Container>
        </HeroBodyStyles>
        <ImageStyles></ImageStyles>
      </HeroStyles>
    </ThemeProvider>
  )
}

export default Welcomepage
