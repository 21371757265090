import axios from 'axios';
import merge from 'lodash/merge';

import { getDefaultHeaders } from './UserHelper';

const request = (options) => {
  const headers = getDefaultHeaders();
  const newOptions = merge(options, { headers }, {timeout: 50000});
  const response = axios.request(newOptions);
  return response;
};

export default request;