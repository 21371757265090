import styled from '@emotion/styled';
import { Container, ThemeProvider } from '@mds/mds-reactjs-library';
import { actions as midActions } from '@nvt/mid-react-saga'
import PropTypes from 'prop-types'

import React from 'react';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SideNav from '../../../../components/SideNavBar';
import EditClient from '../../components/edit-client/EditClient';
import UIAlert from '../../../../components/ui-alert/UIAlert';
import { getData } from '../../../../store/actions';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const EditClientManagement = (props) => {
  const { logout, role, history} = props;
  return (
    <DIV>
        <SideNav logout={logout} role={role} />
        <Container maxWidth="xl">
            <ThemeProvider>
              <UIAlert />
              <ClientTableDiv>
                <EditClient history={history}></EditClient>
              </ClientTableDiv>
            </ThemeProvider>
          </Container>
      </DIV>
  );
  }

  EditClientManagement.propTypes = {
    history: PropTypes.object,
    logout: PropTypes.func,
    role: PropTypes.string
  }
  
  EditClientManagement.defaultProps = {
    history: {},
    logout: () => { },
    role: ""
  }
  
  const mapStateToProps = (state) => ({
    mid: state.mid,
    role: state.reducer.role,
    cardData: state.reducer.cardData
  })
  
  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout()),
    getData: ({history}) => dispatch(getData({history}))
  })
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditClientManagement))
