import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components';
import PropTypes from 'prop-types'
import UserToolbar from '../components/add-user-button/UserToolbar'

import UILoader from "../../../components/ui-loader/UILoader";

import { Styles, ActionBar } from './styles'
import {
  ThemeProvider,
  Table,
  Container,
  DropdownItem,
  DropdownButton,
  Radio,
  SIZE_SMALL,
  WARNING_ALERT_CONTEXTUAL
} from '@mds/mds-reactjs-library';
import { setLoader, resetPassword, deleteUser } from '../../../store/actions';
import UIAlert from '../../../components/ui-alert/UIAlert';
import RemoveReportDialog from '../components/delete-user/delete-user'


const DropdownButtonStyled = styled(DropdownButton)(() => ({
  marginLeft: 'auto',
  marginTop: '0.5rem',
  zIndex: 0
}))

const CheckboxDiv = styled.div`
  justify-content: center;
  cursor: pointer;
  flex: 1;
  display: flex;
  margin-top: 10px;
`

const ActionBarStyled = styled(ActionBar)`
  margin-bottom: 0
`
const DropdownStyled = styled(DropdownButton)`
  margin: 0.5rem 0 20px auto;
  z-Index: 0
`

const UserTable = (props) => {
  const [ischecked, setIsChecked] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [removeDialog, showRemoveDialog]=useState(false)
  const [resetDialog, showResetDialog]=useState(false)
  const history = props.history;
  const dispatch = useDispatch();
  let rowData = [];
  let domain = props?.selectedUser?.email.substring(props?.selectedUser?.email.lastIndexOf("@") +1);
  let resetPasswordNotAllowedDomains = ['mckinsey.com', 'external.mckinsey.com', 'admin.mckinsey.id'];

  const RadioButtonChecked = (args) => {
    setIsChecked(true)
    setSelectedRow({...selectedRow, data: args });
    props.selectRecord(args?.rowData)
  }

  const editUser = () => {
    props.history.push({
      pathname: `/admin/user/editUser`,
      state: selectedRow.data.rowData
    });
  }
  
  const editUserFilter = () => {
    props.history.push({
      pathname: `/admin/client/userFilter`,
      state: selectedRow.data.rowData
    });
  }

  const editUserReport = () => {
    props.history.push({
      pathname: `/admin/client/userReport`,
      state: selectedRow.data.rowData
    });
  }

  const editRole = () => {
    props.history.push({
      pathname: `/admin/user/editRole`,
      state: selectedRow.data.rowData.id
    });
  }

  const handleRemoveOk =()=>{
    dispatch(deleteUser(selectedRow.data.rowData, history))
    setIsChecked(false)
    showRemoveDialog(false)
    dispatch(setLoader());
  }

  const handleResetOk =()=>{
    let rowId = selectedRow?.data?.rowData?.id
    let resetPasswordBody = {rowId, userData:{"email": props?.selectedUser?.email}}
    dispatch(resetPassword(resetPasswordBody))
    showResetDialog(false)
  }

  const CheckboxCellRenderer = (args) => {
    const { value } = args
    return (
      <CheckboxDiv>
        <Radio
          size={SIZE_SMALL}
          onChange={() => RadioButtonChecked(args)}
          checked={value}
          value="small"
          label="Small radio"
          name="RadioButtonSizeExample"
        />
      </CheckboxDiv>
    )
  }

  const column = React.useMemo(
    () => [
      {
        dataKey: 'checked',
        label: '',
        width: 40,
        CellRenderer: CheckboxCellRenderer,
      },
      { dataKey: 'last_name', label: 'Last Name',width: 175},
      { dataKey: 'first_name', label: 'First Name',width: 175},
      { dataKey: 'email', label: 'Email', width: 400},
      { dataKey: 'email_verified', label: 'Verified', width: 150},
      { dataKey: 'blocked', label: 'Status', width: 150},
      { dataKey: 'last_login', label: 'Last login', width: 250},
    ],
    []
  )

  function timeDifference(current, previous) {

    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return '';   
    }
}

let current= new Date();

  if (props.userList && Array.isArray(props.userList.data)) {
    rowData = props.userList && props.userList.data.map((row) => {
      return {
        "id": row.id,
        "last_name": row.last_name,
        "first_name": row.first_name,
        "email": row.email,
        "email_verified": (row.email_verified === true) ? 'Yes' : 'No',
        "blocked": (row.blocked === false) ? 'Active' : 'Blocked',
        "last_login": timeDifference(current,  new Date(row.last_login)),
        "authId": row.authId
      }
    })
  }

  return (
      <UILoader>
            <Styles>
            <ThemeProvider>
          <Container maxWidth="xl">
            <ActionBarStyled>
              {!props.fromFilter && !props.fromSubscription && <UserToolbar history={history} />}
              {!props.fromFilter && !props.fromSubscription &&<DropdownButtonStyled disabled={!ischecked} label="Actions">
              {!resetPasswordNotAllowedDomains.includes(domain) ? <DropdownItem onClick={() => { showResetDialog(true) }} > Reset Password</DropdownItem> : <></>}
                {props?.selectedUser?.id ? <DropdownItem onClick={() => editUser()} > Edit User</DropdownItem> : <></>}
                {props?.selectedUser?.id ? <DropdownItem onClick={() => editRole()} > Edit Role</DropdownItem>: <></>}
                <DropdownItem onClick={() => { showRemoveDialog(true) }} > Remove User</DropdownItem>
                </DropdownButtonStyled>}
                {props.fromFilter && <DropdownStyled disabled={!ischecked} label="Actions">
                <DropdownItem onClick={() => editUserFilter()} > Edit User Filters</DropdownItem>
                </DropdownStyled>}
                {props.fromSubscription && <DropdownStyled disabled={!ischecked} label="Actions">
                <DropdownItem onClick={() => editUserReport()} > Edit User Report</DropdownItem>
                </DropdownStyled>}
            </ActionBarStyled>
            {rowData.length > 0 &&
            <Table height={550} dark striped columns={column} rows={rowData.sort(( a, b) => {return a && a.last_name && a.last_name.localeCompare(b.last_name)})} width={1200}/>}
            {rowData.length === 0 &&
            <UIAlert text="No entries to display" type={WARNING_ALERT_CONTEXTUAL}></UIAlert>}
          </Container>
          {removeDialog ?(<RemoveReportDialog dialog="remove" userName={selectedRow} onOk={()=> handleRemoveOk()} onCancel={()=>showRemoveDialog(false)} />):null}
          {resetDialog ?(<RemoveReportDialog  dialog="reset" userName={selectedRow} onOk={()=> handleResetOk()} onCancel={()=>showResetDialog(false)} />):null}
        </ThemeProvider>
      </Styles>
      </UILoader>
  )
}

UserTable.propTypes = {
  history: PropTypes.object,
  userList: PropTypes.object,
}

UserTable.defaultProps = {
  history: {},
  userList: {},
}

const mapStateToProps = (state) => ({
  userList: state.reducer.userList,
  role: state.reducer.role,
  selectedUser: state.reducer.selectedUser
})


export default connect(mapStateToProps)(UserTable) 
