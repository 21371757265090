import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, connect } from 'react-redux'
import BackButton from '../../../../components/back-button/BackButton'
import UILoader from '../../../../components/ui-loader/UILoader'
import {
  ThemeProvider,
  Container,
  Button,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  FormElementWrapper,
  Input
} from '@mds/mds-reactjs-library'
import { withRouter } from 'react-router-dom';
import { hideLoader, addUser, setLoader } from '../../../../store/actions'
import PropTypes from 'prop-types'

const FreeSpaceHeading = styled.div`
  width: 100%;
  background-color: #e6e6e6;
  margin-top: 20px;
`
const FreeSpaceBackground = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`
const HeadingClientName = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 16px 0 16px 20px;
`
const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `
const FormElementWrapperStyle = styled(FormElementWrapper)`
  .mds-input {
    width: 400px;
  }
`
const ButtonStyled = styled(Button)`
  margin: 20px 20px 20px 0;
`
const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
`
const InputStyledCntr = styled.div`
  width: 400px;
  @media (min-width: 768px) {
    margin: 60px 0 0 20px;
  }
  @media (max-width: 767px) {
    margin: 40px 0 40px 20px;
  }
`
const DescriptionSpan = styled.div`
  font-size: 16px;
  font-style: italic;
  margin: -10px 0 10px 20px;
`

const AddUser = (props) => {
  const {history, userList} = props
  const dispatch = useDispatch()
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    dispatch(hideLoader())
    if (
      first_name !== '' &&
      first_name.length > 0 &&
      last_name !== '' &&
      last_name.length > 0 &&
      email !== '' &&
      email.length > 0 &&
      !userList?.data?.some(user => user?.email?.toLowerCase() === email?.toLowerCase())
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [first_name, last_name, email])

  const handleAdd = () => {
    const tenantName = JSON.parse(sessionStorage.getItem('_mid-tenant'))?.displayName
      let newUser = {
        history,
        userDetails: {
          email: email,
          first_name: first_name,
          last_name: last_name
        },
      }
      dispatch(addUser(newUser, tenantName))
      dispatch(setLoader())
  }
  return (
    <>
      <UILoader>
        <ThemeProvider>
          <Container>
            <BackButton text="Return to User Management" />
            <FreeSpaceHeading>
              <Container>
                <DIV>
                  <HeadingClientName>User Creation</HeadingClientName>
                </DIV>
                <DIV>
                  <DescriptionSpan>All fields are required.</DescriptionSpan>
                </DIV>
              </Container>
            </FreeSpaceHeading>
            <FreeSpaceBackground>
              <Container>
                <DIV>
                  <InputStyledCntr>
                    <FormElementWrapperStyle
                      component={Input}
                      label="First Name"
                      inputProps={{
                        placeholder: 'Enter First Name',
                        name: 'first_name',
                        value: first_name,
                        onChange: (e) => setFirstName(e.target.value),
                        htmlSize: 30,
                      }}
                      validationFunc={(val) => {
                        return !val || val.length < 1 ? 'Please enter the first name' : ''
                      }}
                    />
                  </InputStyledCntr>
                  <InputStyledCntr>
                    <FormElementWrapperStyle
                      component={Input}
                      label="Last Name"
                      inputProps={{
                        placeholder: 'Enter Last Name',
                        name: 'last_name',
                        value: last_name,
                        onChange: (e) => setLastName(e.target.value),
                        htmlSize: 30,
                      }}
                      validationFunc={(val) => {
                        return !val || val.length < 1 ? 'Please enter the last name' : ''
                      }}
                    />
                  </InputStyledCntr>
                </DIV>
                <InputStyledContainer>
                  <FormElementWrapperStyle
                    component={Input}
                    label="Email"
                    inputProps={{
                      placeholder: 'Enter Email',
                      name: 'email',
                      value: email,
                      onChange: (e) => setEmail(e.target.value),
                      htmlSize: 30,
                    }}
                    validationFunc={(val) => {
                      return !val || val.length < 1
                        ? 'Please enter the email'
                        : userList?.data?.some(user => user?.email?.toLowerCase() === val.toLowerCase())
                        ? 'Email already exists'
                        : ''
                    }}
                  />
                </InputStyledContainer>
              </Container>
            </FreeSpaceBackground>
            <ButtonStyled id="user-creation-add-btn" appearance={PRIMARY_BUTTON} onClick={handleAdd} disabled={!isValid}>
              Add
            </ButtonStyled>
            <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/user`)}>
              Cancel
            </ButtonStyled>
          </Container>
        </ThemeProvider>
      </UILoader>
    </>
  )
}

AddUser.propTypes = {
  history: PropTypes.object,
  userList: PropTypes.object,
}

AddUser.defaultProps = {
  history: {},
  userList: {}
}

const mapStateToProps = (state) => ({
  userList: state.reducer.userList,
})

export default withRouter(connect(mapStateToProps)(AddUser))
