import React from 'react';
import { ThemeProvider, InfoModal, INFO_MODAL, SECONDARY_BUTTON } from '@mds/mds-reactjs-library';
import './remove-secret-modal.css';



function RemoveSecretModal ({onOk, onCancel}){
  return (
    <ThemeProvider >
      <InfoModal
        id='remove-report-modal'
        type={INFO_MODAL}
        className="remove-modal"
        body="Are you sure that you want to clear data policy details, you can't undo once cleared."
        okButton={{
          label: 'Yes, Clear',
          action: onOk
        }}
        cancelButton={{
            label: 'No, Cancel',
            action: onCancel,
            appearance: SECONDARY_BUTTON
          }}
          onClose={onCancel}
      />
    </ThemeProvider>
  );
}
export default RemoveSecretModal;