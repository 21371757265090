import React, { useEffect, useState } from 'react';
import { actions as midActions } from '@nvt/mid-react-saga'
import styled from '@emotion/styled';
import {
    Container, ThemeProvider,  Typography, Input, Button,
    PRIMARY_BUTTON, Tooltip,
    SECONDARY_BUTTON, FormElementWrapper, HEADING_SIX
} from '@mds/mds-reactjs-library';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SideNav from '../../../../components/SideNavBar';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoader, addReport } from '../../../../store/actions';
import BackButton from '../../../../components/back-button/BackButton';
import UIAlert from '../../../../components/ui-alert/UIAlert';
import { makeStyles } from '@material-ui/core/styles';
import CustomToggle from '../../../../components/custom-toggle/CustomToggle';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
.mds-form-el {
    margin-top:1rem!important;
}
`;

const ClientTableDiv = styled.div`
    background-color:#f6f6f6;
    padding:1rem;
`;

const ClientTableHeader = styled.div`
    padding:10px;
    background-color:#e6e6e6;
    font-weight:bold;
    
`;
const SubDescriptionSpan = styled.div`
    padding-top:10px;
    font-weight:normal;
    dispaly:block;
    font-style: italic;
    font-size: 16px;
`;

const Label = styled.div`
  margin-bottom:5px;
`;

const InputElementStyled = styled.div`
  width: 50%;
`;

const CustomToggleStyled = styled(CustomToggle)`
margin-right: 0.5rem;
`

const useStyles = makeStyles(() => ({
    formcontrollabel: {
      marginLeft: 'auto',
      flexDirection: 'row',
    }
  }));

const AddReports = ({ role, logout, createReport, history ,report}) => {

    const [info, setInfo] = useState('');
    const [domo_embed_id, setDOMO] = useState('')
    const [global_report_name, setGlobalReportName] = useState('');
    const [domo_report_name, setDomoReportName] = useState('');
    const [custom_dataset_id, setDomoDatasetId] = useState('');
    const [report_state, setReportState] = useState(true);
    const [isValid,setIsValid] = useState(false);
    const classes = useStyles();

    const ButtonStyled = styled(Button)`   
        margin-right: 12px;
        margin-bottom: 12px;
    `;

    useEffect(()=>{
        if(report && !report?.length < 0) {
            const rp = JSON?.parse(report);
            if(rp && rp?.id)
            { 
                history.push('/admin/report');
            }
        }
    },[report]);

    useEffect(()=>{
        if(global_report_name!=='' 
            && global_report_name.length > 0 
            && global_report_name !==''
            && global_report_name.length > 0
            && domo_embed_id!==''
            && domo_embed_id.length> 0
            && info!==''
            && info.length> 0){
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    },[global_report_name,global_report_name,domo_embed_id,info]);

    const handleCreate = async () => {        
        await createReport({history,reportData:{
            metadata_program:info,
            domo_embed_id,
            domo_report_name,
            global_report_name,
            custom_dataset_id,
            is_visible:report_state
        }});
        history.push('/admin/report');
    }
    return (
        <DIV>
            <SideNav role={role} logout={logout} />

            <ThemeProvider>
                <Container maxWidth="xl" responsive>
                <UIAlert />
                <BackButton id="report-mgmt-back-btn" text="Return to Report Management" />
                    <ClientTableHeader>
                    <Typography type={HEADING_SIX}> Add Reports </Typography>
                        <SubDescriptionSpan>All fields are required.</SubDescriptionSpan>
                    </ClientTableHeader>
                    <ClientTableDiv>
                        <InputElementStyled className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                helperText="Required"
                                label="Global Report Name"
                                inputProps={{
                                    placeholder: 'Enter Global Report Name',
                                    name: 'global_report_name',
                                    value: global_report_name,
                                    onChange: e => setGlobalReportName(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Global Report Name' : '';
                                }}

                            />
                        </InputElementStyled>
                        <InputElementStyled className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                helperText="Required"
                                label="Domo Report Name"
                                inputProps={{
                                    placeholder: 'Enter Domo Report Name',
                                    name: 'domo_report_name',
                                    value: domo_report_name,
                                    onChange: e => setDomoReportName(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please enter Domo Report Name' : '';
                                }}
                            />

                        </InputElementStyled>
                        <InputElementStyled className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                label="Domo Embed Id"
                                helperText="Required"
                                inputProps={{
                                    placeholder: 'Add Domo Embed Id',
                                    name: 'domo_embed_id',
                                    value: domo_embed_id,
                                    onChange: e => setDOMO(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter Domo Embed ID' : '';
                                }}
                            />
                        </InputElementStyled>
                        <InputElementStyled className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                label="Domo Dataset Id"
                                inputProps={{
                                    placeholder: 'Domo Dataset Id',
                                    name: 'custom_dataset_id',
                                    value: custom_dataset_id,
                                    onChange: e => setDomoDatasetId(e.target.value),
                                    htmlSize: 30
                                }}
                            />

                        </InputElementStyled>
                        <InputElementStyled className="mt-4">
                            <FormElementWrapper
                                component={Input}
                                label="Info"
                                helperText="Required"
                                inputProps={{
                                    placeholder: 'Info',
                                    name: 'info',
                                    value: info,
                                    onChange: e => setInfo(e.target.value),
                                    htmlSize: 30
                                }}
                                validationFunc={val => {
                                    return !val || val.length < 1 ? 'Please Enter the Info' : '';
                                }}
                            />
                        </InputElementStyled>
                         <div className="mt-4">
                         
                            <Label>Report State</Label>
                            <Tooltip content={report_state ? "Visible" : "Not Visible"}>
                                <FormControlLabel
                                className={classes.formcontrollabel}
                                value="top"
                                control={<CustomToggleStyled
                                checked={report_state}
                                onChange={()=> setReportState(!report_state)}
                                name="report_state"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />}
                                label="Visible"
                                labelPlacement="start"
                                />
                            </Tooltip>
                        </div>

                    </ClientTableDiv>
                    <div className="mds-form-el mt-4">
                        <ButtonStyled id="report-mgmt-add-report-btn" appearance={PRIMARY_BUTTON} onClick={handleCreate} disabled={!isValid}>Add</ButtonStyled>
                        <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/report`)}>Cancel</ButtonStyled>
                    </div>
                </Container>
            </ThemeProvider>

        </DIV>
    );
}

AddReports.propTypes = {
    logout: PropTypes.any.isRequired,
    createReport: PropTypes.any.isRequired,
    role: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    report: PropTypes.any.isRequired
  }

AddReports.defaultProps = {
    createReport: () => { },
    logout: () => { },
    role: [],
    report: []
} 

const mapStateToProps = (state) => ({

    mid: state.mid,
    role: state.reducer.role,
    report:state.reducer.report,
})

const mapDispatchToProps = (dispatch) => ({
    createReport: ( payload ) => {
        dispatch(setLoader())
        dispatch(addReport(payload))
        },
    logout: () => dispatch(midActions.logout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddReports))


