import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { Styles } from '../../client-configuration-table/styles';
import CustomToggle from '../../../../components/custom-toggle/CustomToggle';
// Table
import {
  ThemeProvider,
  Table,
  Tooltip,
  Badge,
  Button,
  BADGE_TYPE_NEUTRAL_LIGHT,
  BADGE_TYPE_SUCCESS_LIGHT,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON
} from '@mds/mds-reactjs-library'
import UILoader from '../../../../components/ui-loader/UILoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { editUserSubscription, setLoader } from '../../../../store/actions';

const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const PARENTDIV = styled.div`

`;

const HEADDIV = styled.div`

`;

const ALTDIV = styled.div`
border : none;
padding : 0;
`;

const CDIV = styled.div`
  border: 3px solid blue;
  padding: 20px;
  height: 30rem;
  overflow: scroll;
`;

const Description = styled.div`
  width: 60%;
  font-size: 16px;
  padding-bottom: 30px;
`;

const ButtonDiv = styled.div`
  width: 40%;
`;

const ButtonStyled = styled(Button)`
   margin: 0 20px 20px 0;
   float: right;
`;

const ButtonStyle = styled(Button)`
   margin: 0 0 20px 0;
   float: right;
`;

const HeaderMain = styled.h5`
  margin: 19px 0 4px 0;
`;

const EditUserSubscrition = (props) => {
  
  const dispatch = useDispatch();
  const [myReport, setMyReport] = useState([]);
  const [showButton, setShowButton] = useState(false)
  sessionStorage.setItem('fromUserLevel', 1)
  let CONDIV = showButton ? CDIV  : ALTDIV

  useEffect(() => {
   if (props.userReport && Array.isArray(props.userReport)){  
      setMyReport(props.userReport)
    }
  }, [props.userReport]); 
  
  const handleChange = (event, id, i) => {
    let newArr = [...myReport]; 
    newArr[i][event.target.name] = event.target.checked;
    setMyReport(newArr);
    setShowButton(true)
  }

  const handleSave = () => {
    let payload = {history, subscriptionData: myReport}
    let clientId = props?.selectedClient ? props?.selectedClient.tenant_id : JSON.parse(sessionStorage.getItem('selectedClient'))?.tenant_id
    dispatch(editUserSubscription(payload,props.userId,clientId))
    dispatch(setLoader())
    setShowButton(false)
  }

  const handleCancel = () => {
    let initialValue = JSON.parse(sessionStorage.getItem('initialUserReport'))
    setMyReport(initialValue)
    setShowButton(false)
  }

  const BadgeRenderer = (args) => {
    const { value } = args
    return (
    value ? <Badge type={ BADGE_TYPE_SUCCESS_LIGHT}>Visible</Badge> :
    <Badge type={BADGE_TYPE_NEUTRAL_LIGHT}>Not Visible</Badge>)
  }

  const column = React.useMemo(
    () => [
        { dataKey: 'global_report_name', label: 'Report Name', width: 350},
        { dataKey: 'domo_report_name', label: 'Domo Report Name', width: 350},
        { dataKey: 'is_subscribed', label: 'Subscribed', width: 275},
        { dataKey: 'is_visible', label: 'Visibility', width: 250, CellRenderer: BadgeRenderer},
      ],
    []
  )
  let rowData=[]
  if (props.userReport && Array.isArray(props.userReport)){
    rowData = myReport.map((row, i) => {
      return {
        "id": row.id,
        "global_report_name": row.global_report_name,
        "domo_report_name":  row.domo_report_name,
        "is_subscribed": <Tooltip content={(row.subscribed) ? "Subscribed" : "Not Subscribed"}><FormControlLabel
        value="top"
        control={<CustomToggle
          key={i}
          checked={row.subscribed}
          onChange={(event) => handleChange(event, row.id, i)}
          name="subscribed"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
        label=""
        labelPlacement="start"
        /></Tooltip>,
        "is_visible": row.is_visible,
      }})
    } 
  
   
  return (
  <Styles>
    <UILoader>
      <ThemeProvider>
      <PARENTDIV>
        <HEADDIV>
        <HeaderMain>User Subscription</HeaderMain>
           <DIV><Description>You can manage the report subscriptions for users by enabling or disabling the reports from the list below. 
             Only subscribed reports will be available to the users.</Description>
           {showButton && <ButtonDiv>
           <ButtonStyle appearance={PRIMARY_BUTTON} onClick={handleSave}>Save</ButtonStyle>
           <ButtonStyled appearance={SECONDARY_BUTTON} onClick={handleCancel}>Cancel</ButtonStyled>
           </ButtonDiv>}</DIV>
        </HEADDIV>
        
          <CONDIV>
          <Table height={550} dark striped columns={column} rows={rowData} />
          </CONDIV>
      </PARENTDIV>
      </ThemeProvider>
    </UILoader>
  </Styles>
  )
}

EditUserSubscrition.propTypes = {
  userReport: PropTypes.object,
  history: PropTypes.object,
  tenant: PropTypes.object
}

EditUserSubscrition.defaultProps = {
  userReport: {},
  history: {},
  tenant: {}
}

const mapStateToProps = (state) => ({
    userReport: state.reducer.userReport,
})

export default connect(mapStateToProps)(EditUserSubscrition)
