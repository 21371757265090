import React from 'react';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import {
    ThemeProvider,
    Button,
    TERTIARY_BUTTON,
  } from '@mds/mds-reactjs-library';

const BackButton = props => {
    const {text, backTo, id} = props
    let history = useHistory();
    const ButtonStyled = styled(Button)`
        margin: 20px 12px 12px 0;
    `;

    return (
        <ThemeProvider>
            <ButtonStyled id={id} onClick={() => backTo ? history.push(backTo) : history.goBack()} appearance={TERTIARY_BUTTON}>{text}</ButtonStyled>
         </ThemeProvider>
    )
}

export default BackButton;