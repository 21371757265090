import { watcher as midWatcher, midfetch } from '@nvt/mid-react-saga'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import Config from '../config'
import { USER_INFO_KEY } from '../constants'
import request from '../helpers/ApiHelper'
import { setInLocalStorage }  from '../helpers/UserHelper';
import * as actions from './actions';
import * as constants from './constants'


export function* getRoles(action) {
  const history = action.payload.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/roles`})
    const res = yield response.data
    if (res === []) {
      throw new Error;
    }
    setInLocalStorage(USER_INFO_KEY, res)
    yield put({ type: constants.GET_ROLES_SUCCESS, payload: { res } })
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_roles", apiError: true}))
  }
}

export function* getTenantDetails(action) {
  const history = action.payload.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/tenants`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_TENANT_DETAILS_SUCCESS, payload: { res }  })
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_tenants", apiError: true}))
  }


}

export function* getEmbedToken({payload}) {
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/reports/${payload.reportName}/clients/${payload.clientId}/users/${payload.userId}/embedToken`})
    if (!response.statusText === "OK") {
      const resp = yield response.data
      throw new Error('Unable to fetch embedtoken: ' + resp['message'])
    }
    const res = yield response.data
    yield put({ type: constants.GET_EMBED_TOKEN_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    yield put(actions.hideLoader())
    yield put({ type: constants.GET_EMBED_TOKEN_FAILURE, payload: { }  })
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_embed_token", apiError: true}))
  }
}

export function* getCSV() {
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/getCSV`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    alert('File downloaded successfully!');
  } catch (err) {
    // throw new Error('Something failed during test fetch', err)
  }
}

export function* getData(action) {
  const history = action.payload.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/getDataset`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_DATA_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_domo_dataset", apiError: true}))
  }
}

// Admin/report/get
export function* getReportData(action) {
  const history = action.payload.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/reports`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_reports", apiError: true}))
  }
}

// Admin/report/add
export function* addReportData({ payload }) {
  const history = payload.history
  try {
    const report = payload.reportData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/reports`,
    {
      method: 'post',
      body: JSON.stringify(report),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add reports')
    }
    const res = yield response.text()
    yield put(actions.getReports({history}))
    yield put({ type: constants.ADD_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Added Report Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error While adding report. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

// Admin/report/edit
export function* editReportData({ payload }) {
  const history = payload.history
  try {
    const report = payload.reportData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/reports`,
    {
      method: 'put',
      body: JSON.stringify(report),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add reports')
    }
    const res = yield response.text()
    yield put(actions.getReports({history}))
    yield put({ type: constants.EDIT_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Report Edited Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while editing. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

// Admin/report/delete
export function* deleteReport({id, payload}) {
  const history = payload.history
  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/reports/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to delete reports')
    }
    const res = yield response.text()
    yield put(actions.getReports({history}))
    yield put({ type: constants.DELETE_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Report Deleted Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while deleting Report. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/client/get
export function* getClientData(action) {
  const history = action && action.payload && action.payload.history

  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put(actions.hideLoader())
    yield put({ type: constants.GET_CLIENT_SUCCESS, payload: { res }})
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_clients", apiError: true}))
  }
}
// Admin/client/report
export function* getClientReportData(action) {
  const history = action.payload
  
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients/${action.id}/reports`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    sessionStorage.setItem('initialReports', JSON.stringify(res))
    yield put({ type: constants.GET_CLIENT_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_client_reports", apiError: true}))
  } 
}
export function* getSubscribedReports(action) {
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/clients/${action.clientId}/users/${action.userId}/reports/subscribed`})
    if (!response.statusText === "OK") {
      throw new Error("It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_subscribed_reports")
    }
    else if(!response?.data?.reports.length) {
      throw new Error('No Reports to Display')
    }
    const res = yield response.data
    yield put({ type: constants.GET_SUBSCRIBE_REPORT_SUCCESS, payload: { res }  })
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setApiFailed({message: err.message, apiError: true}))
  }  
}

export function* getClientReportRankingData({clientId}) {
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients/${clientId}/reportsRanking`})
    if (!response.statusText === "OK") {
      throw new Error("Unable to fetch")
    }

    const res = yield response.data
    sessionStorage.setItem('initialRankReports', JSON.stringify(res))
    yield put({ type: constants.GET_CLIENT_RANKING_REPORT_SUCCESS, payload: { res }  })
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setApiFailed({message: err.message, apiError: true}))
  }  
}
export function* saveClientReportRankingData({ payload, clientId }) {
  try {
    const client = payload.client
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${clientId}/reportsRanking`,
    {
      method: 'post',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to save the rank for report')
    }
    const res = yield response.text()
    let id = clientId
    let history = payload.history
    yield put(actions.getClientReport(id, history))
    yield put(actions.getClientReportRanking(clientId))
    yield put({ type: constants.SAVE_CLIENT_RANKING_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Report rankings have been saved successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.getClientReportRanking(clientId))
    yield put(actions.setAlertAction({
      text: 'Error while saving the report rank. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

export function* getCustomEmbedFilters(payload) {
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/reports/${payload.selectedReportId}/embedFilters`})
    const res = yield response.data
    yield put({ type: constants.GET_CUSTOM_EMBED_FILTERS_SUCCESS, payload: { res }  })
  } catch (err) {
    yield put(actions.setApiFailed({message: err.message, apiError: true}))
    yield put(actions.setAlertAction({
      text: 'Error while getting the filters. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  } 
}

export function* saveCustomEmbedFilters(payload) {
  try {
    const filterData = payload?.selectedFilters
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/domo/reports/${payload.selectedReportId}/embedFilters`,
    {
      method: 'post',
      body: JSON.stringify(filterData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to save the filters')
    }
    const res = yield response.json()
    yield put({ type: constants.POST_CUSTOM_EMBED_FILTERS_SUCCESS, payload: { res, isLoading: false } })
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Error while saving the filters. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

// Admin/client/report/subscribe
export function* subscribeReportData({ payload, id }) {
  try {
    const client = payload.client
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${id}/reports`,
    {
      method: 'post',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to subscribe report')
    }
    const res = yield response.text()
    yield put(actions.getClientReport(id,payload.history))
    yield put({ type: constants.SUBSCRIBE_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Changes have been successfully saved',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.getClientReport(id,payload.history))
    yield put(actions.setAlertAction({
      text: 'Error while subscribing the report. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/user/report/subscribe
export function* userSubscribeReportData(action) {
  const history = action.history
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients/${action.clientId}/users/${action.userId}/reports`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put(actions.hideLoader())
    sessionStorage.setItem('initialUserReport', JSON.stringify(res))
    yield put({ type: constants.GET_USER_SUBSCRIBE_REPORT_SUCCESS, payload: { res }})
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_clients", apiError: true}))
  }
  }

export function* saveUserSubscription({ payload, userId, clientId }) {
  try {
    const subscriptionData = payload.subscriptionData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${clientId}/users/${userId}/reports`,
    {
      method: 'post',
      body: JSON.stringify(subscriptionData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 409) {
      throw new Error('Error occured while saving filters. Filter Limit Exceeded')
    }
    else if (!response.status === 409 && !response.ok ) {
      throw new Error('Something went wrong while managing user filter. Please try again later')
    }
    const res = yield response.text()
    let history = payload.history
    yield put(actions.userSubscribeReport(clientId,userId,history))
    yield put({ type: constants.EDIT_USER_SUBSCRIBE_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Changes have been successfully saved',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.userSubscribeReport(clientId,userId,history))
    yield put(actions.setAlertAction({
      text: err.message,
      type: constants.ERROR_ALERT_CONTEXTUAL
    }))
  }
}

// Admin/client/report/subscribe
export function* unSubscribeReportData(action) {
  try {
    const client = action.payload
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${action.payload.client_id}/reports/unsubscribe`,
    {
      method: 'put',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to subscribe report')
    }
    const res = yield response.text()
    yield put(actions.getClientReport(action.payload.client_id,action.payload.history))
    yield put({ type: constants.UNSUBSCRIBE_REPORT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: '',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Error While unsubscribing the report. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  yield put(actions.getClientReport(action.payload.client_id,action.payload.history))
  }
}
// Admin/client/add
export function* addClientData({payload}) {
  const history = payload.history
  try {
    const client = payload
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients`,
    {
      method: 'post',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add client');
    }
    const res = yield response.text()
    yield put(actions.getClientDetails({history}))
    yield put({ type: constants.ADD_CLIENT_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Added Client Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error While adding client. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/client/add
export function* editClientData({ payload }) {
  const history = payload.history
  try {
    const client = payload
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients`,
    {
      method: 'put',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add client')
    }
    const res = yield response.text()
    yield put(actions.getClientDetails({history}))
    yield put({ type: constants.EDIT_CLIENT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Client Information Successfully Edited',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Error While editing client information. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/client/delete
export function* deleteClient({id, payload}) {
  const history = payload.history
  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to delete client')
    }
    const res = yield response.text()
    yield put(actions.getClientDetails({history}))
    yield put({ type: constants.DELETE_CLIENT_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Client deleted successfuly',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while deleting the client. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

//Admin/client/filter
export function* getClientDataFilter(action) {
  const history = action.history

  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients/${action.id}/filter`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put(actions.hideLoader())
    sessionStorage.setItem('initialOffer', JSON.stringify(res))
    yield put({ type: constants.GET_CLIENT_DATA_FILTER_SUCCESS, payload: { res }})
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_clients", apiError: true}))
  }
}
//Admin/client/savefilter 
export function* saveFilter({ payload, clientId }) {
  try {
    const filterData = payload.filterData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${clientId}/filter`,
    {
      method: 'post',
      body: JSON.stringify(filterData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 409) {
      throw new Error('Error occured while saving filters. Filter Limit Exceeded')
    }
    else if (!response.status === 409 && !response.ok ) {
      throw new Error('Something went wrong while managing data filter. Please try again later')
    }
    const res = yield response.text()
    yield put(actions.getDataFilter(clientId, payload.history))
    yield put({ type: constants.EDIT_CLIENT_DATA_FILTER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Data Filter has been saved successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.getDataFilter(clientId, payload.history))
    yield put(actions.setAlertAction({
      text: err.message,
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
//Admin/user/filter
export function* getUserDataFilter(action) {
  const history = action.history

  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/clients/${action.clientId}/users/${action.userId}/filter`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put(actions.hideLoader())
    sessionStorage.setItem('initialUserOffer', JSON.stringify(res))
    yield put({ type: constants.GET_USER_DATA_FILTER_SUCCESS, payload: { res }})
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_clients", apiError: true}))
  }
}
//Admin/user/savefilter 
export function* saveUserFilter({ payload, userId, clientId }) {
  try {
    const filterData = payload.filterData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/clients/${clientId}/users/${userId}/filter`,
    {
      method: 'post',
      body: JSON.stringify(filterData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 409) {
      throw new Error('Error occured while saving filters. Filter Limit Exceeded')
    }
    else if (!response.status === 409 && !response.ok ) {
      throw new Error('Something went wrong while managing user filter. Please try again later')
    }
    const res = yield response.text()
    let history = payload.history
    yield put(actions.getUserFilter(clientId,userId,history))
    yield put({ type: constants.EDIT_USER_DATA_FILTER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'User Filter has been saved successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.getUserFilter(clientId,userId,history))
    yield put(actions.setAlertAction({
      text: err.message,
      type: constants.ERROR_ALERT_CONTEXTUAL
    }))
  }
}

export function* getClientUserListData(action) {
  const history = action.history
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/domo/clients/${action.clientId}/users`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_CLIENT_USER_DATA_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_userlist", apiError: true}))
  } 
}

//Admin/defaultTeam
export function* getDefaultTeamData(action) {
  const history = action && action.payload && action.payload.history
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/defaultTeam`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_DEFAULT_TEAM_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_default_team", apiError: true}))
  } 
}
//Admin/defaultTeam/add
export function* addDefaultUserData({payload}) {
  const history = payload.history
  try {
    const newUser = payload.userData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/defaultTeam`,
    {
      method: 'post',
      body: JSON.stringify(newUser),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  
    const res = yield response.text()
    yield put(actions.getDefaultTeam({history}))
    yield put({ type: constants.ADD_DEFAULT_TEAM_USER_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
    if (response.status === 409) {
      //throw new Error('Sorry, User is already exist in the default team');
      yield put(actions.setAlertAction({
        text: 'Sorry, user is already present in the Core Team',
        type: constants.ERROR_ALERT_CONTEXTUAL
      }))
    }
    else {
    yield put(actions.setAlertAction({
      text: 'Added User Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
      }))
    }
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error While adding user. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/defaultTeam/edit
export function* editDefaultUserData({ payload }) {
  const history = payload.history
  try {
    const editUser = payload.userData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/defaultTeam`,
    {
      method: 'put',
      body: JSON.stringify(editUser),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to edit User')
    }
    const res = yield response.text()
    yield put(actions.getDefaultTeam({history}))
    yield put({ type: constants.EDIT_DEFAULT_TEAM_USER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'User Edited Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while editing. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/defaultTeam/delete
export function* deleteDefaultUserData({id, payload}) {

  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/defaultTeam/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to delete user')
    }
    const res = yield response.text()
    yield put(actions.hideLoader())
    yield put(actions.getDefaultTeam({payload}))
    yield put({ type: constants.DELETE_DEFAULT_TEAM_USER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'User deleted Successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while deleting User. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
//Admin/UserMgmt
export function* getUserListData(action) {
  const history = action.payload
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/users`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_USER_MGMT_LIST_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_userlist", apiError: true}))
  } 
}
//Admin/GetRoleList
export function* getRoleListData(action) {
  const history = action.payload
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/authorization/teams`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_ROLES_LIST_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_role_list", apiError: true}))
  } 
}
//Admin/GetUserRole
export function* getUserRoleData(action) {
  const history = action.payload
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/authorization/users/${action.id}`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_USER_ROLE_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_user_rolelist", apiError: true}))
  } 
}
export function* getAuthorizationUser(action) {
  const history = action.payload
  const userInfo = JSON.parse(action.userAdd)
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/authorization/users/${userInfo.id}`})
    if (!response.statusText === "OK") {
      throw new Error('Unable to fetch')
    }
    const res = yield response.data
    yield put({ type: constants.GET_AUTORIZATION_USER_SUCCESS, payload: { res }  })
    // yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_authorization_users", apiError: true}))
  } 
}
export function* addUserRoleAuthorization(action) {
  const history = action.payload
  const userInfo = JSON.parse(action.userAdd)
  let teams = []
  teams.push(action.role)
  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/authorization/users/${userInfo.id}/teams`,
    {
      method: 'post',
      body: JSON.stringify({teams}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add reports')
    }
    const res = yield response.text()
    yield put({ type: constants.ADD_USER_ROLE_AUTORIZATION_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'Role assigned successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  yield put(actions.hideLoader())
  history.push('/admin/user');
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error while assigning role. Try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
export function* addUser({ payload, tenantName }) {
  const history = payload.history;
  try {
    const user = payload.userDetails;
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/users`,
    {
      method: 'post',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const res = yield response.text()
    yield put({ type: constants.ADD_USER_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
  if (response.status === 422) {
    // history.push('/admin/user')
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Email domain for this user is invalid, user may not be created',
      type: constants.ERROR_ALERT_CONTEXTUAL
    }))
  }
  else if(response.status === 400) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error while creating user. Try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
    }))
  }
  else {
    yield put(actions.setAlertAction({
      text: `New User ${payload?.userDetails?.first_name} ${payload?.userDetails?.last_name} has been successfully added to ${tenantName}`,
      type: constants.SUCCESS_ALERT_CONTEXTUAL
    }))
    yield put(actions.hideLoader())
    history.push({
      pathname: '/admin/user/addUserRole',
      userInfo: payload?.userDetails
    })
}
} catch (err) {
    history.push('/admin/user')
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error while creating user. Try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/UserMgmt/edit
export function* editUserData({ payload }) {
  const history = payload.history
  try {
    const editUser = payload.userData
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/users/${payload.id}`,
    {
      method: 'put',
      body: JSON.stringify(editUser),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to edit User')
    }
   
    const res = yield response.text()
    yield put(actions.hideLoader())
    yield put({ type: constants.EDIT_USER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'User edited successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
    }))
    yield put(actions.getUserInfo(history))
    history.push('/admin/user');
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Something went wrong while editing. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/UserMgmt/resetPwdUser
export function* resetPwdUser({ payload }) {
  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/users/${payload.rowId}/password-reset`,
    {
      method: 'post',
      body: JSON.stringify(payload?.userData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to reset the password')
    }
   
    const res = yield response.text()
    yield put({ type: constants.USER_RESET_PWD_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'A link has been sent successfully to the registered email to reset the password',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
    }))
    
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while sending the mail. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
// Admin/UserMgmt/delete
export function* deleteUserData({userInfo, payload}) {

  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/users?internal=${userInfo.id}&external=${userInfo.id ? '' : userInfo.authId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to delete user')
    }
    const res = yield response.text()
    yield put(actions.getUserList({payload}))
    // yield put(actions.hideLoader())
    yield put({ type: constants.DELETE_USER_SUCCESS, payload: { res }  })
    yield put(actions.setAlertAction({
      text: 'User deleted successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Something went wrong while deleting User. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}
export function* filterDashboard({payload}) {
  const history = payload.history;
  try {
    const response = yield call(
      request,
      {url :`${Config.API_SERVER}/domo/${payload.reportName}/fiterDashboard/${payload.filterValue}`}
    )

    if (!response.statusText === "OK") {
      throw new Error('Unable to get reports')
    }

    // const embedToken = yield embedTokenResponse.json()
    const res = yield response.data
    yield put({ type: constants.FILTER_DATA_SUCCESS, payload: { res }  })
  
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_filter_dashboard", apiError: true}))
  }
}

export function* antivirusCheck({ payload }) {
  try {
    const fetch = yield midfetch()
    const endpoint = `${Config.API_SERVER}/scan`
    const response = yield call(fetch, endpoint, {
      method: 'post',
      body: JSON.stringify({ url: payload }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to fetch')
    }
    const res = yield response.text()
    yield put({ type: constants.ANTIVIRUS_CHECK_SUCCESS, payload: { res } })
  } catch (err) {
    throw new Error('Something failed during test fetch', err)
  }
}

export function* getVaultSecret(action) {
  const history = action.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/vault/${action.tenantId}`})
    const res = yield response.data
    if (res === []) {
      throw new Error;
    }
    yield put({ type: constants.GET_VAULT_SECRET_SUCCESS, payload: { res } })
    yield put(actions.hideLoader())
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_secret", apiError: true}))
  }
}

export function* getVaultHistory(action) {
  const history = action.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/admin/vault/${action.tenantId}/history`})
    const res = yield response.data
    if (res === []) {
      throw new Error;
    }
    yield put({ type: constants.GET_VAULT_HISTORY_SUCCESS, payload: { res } })
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_get_secret", apiError: true}))
  }
}

export function* getUserInfo(action) {
  const history = action.history;
  try {
    const response = yield call(request, {url: `${Config.API_SERVER}/userInfo`})
    const res = yield response.data
    if (res === []) {
      throw new Error;
    }
    yield put({ type: constants.GET_USER_INFO_SUCCESS, payload: { res } })
  } catch (err) {
    history.push("/error")
    yield put(actions.setApiFailed({message: "It looks like you're not yet authorized to access this page. Please contact support: mcka-support-portal@mckinsey.com. error_user_info", apiError: true}))
  }
}


export function* deleteVaultSecret(action) {
  const history = action.history
  try {
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/vault/${action.tenantId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to delete vault secret')
    }
    const res = yield response.text()
    yield put({ type: constants.DELETE_VAULT_SECRET_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
    yield put(actions.getVaultSecret(history, action?.tenantId))
    yield put(actions.getVaultHistory(history, action?.tenantId))
    yield put(actions.setAlertAction({
      text: 'Data policy details deleted successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.setAlertAction({
      text: 'Something went wrong while deleting Secret. Please try again later',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}


export function* saveVaultSecret(action) {

  const history = action.history
  try {
    const secretPayload = {"domoClientId": action.clientId, "domoClientSecret": action.clientSecret}
    const fetch = yield midfetch()
    const response = yield call(fetch, `${Config.API_SERVER}/admin/vault/${action.tenantId}`,
    {
      method: 'post',
      body: JSON.stringify(secretPayload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Unable to add reports')
    }
    const res = yield response.text()
    yield put({ type: constants.SAVE_VAULT_SECRET_SUCCESS, payload: { res }  })
    yield put(actions.hideLoader())
    yield put(actions.getVaultSecret(history, action?.tenantId))
    yield put(actions.getVaultHistory(history, action?.tenantId))
    yield put(actions.setAlertAction({
      text: 'Data policy details saved successfully',
      type: constants.SUCCESS_ALERT_CONTEXTUAL
  }))
  } catch (err) {
    yield put(actions.hideLoader())
    yield put(actions.setAlertAction({
      text: 'Error while saving Data policy details. Please try again',
      type: constants.ERROR_ALERT_CONTEXTUAL
  }))
  }
}

function* watcher() {
  yield takeLatest(constants.GET_EMBED_TOKEN, getEmbedToken)
  yield takeLatest(constants.ANTIVIRUS_CHECK, antivirusCheck)
  yield takeLatest(constants.FILTER_DATA, filterDashboard)
  yield takeLatest(constants.GET_CSV, getCSV)
  yield takeLatest(constants.GET_DATA, getData)
  yield takeLatest(constants.GET_ROLES, getRoles)
  yield takeLatest(constants.GET_TENANT_DETAILS, getTenantDetails)
  yield takeLatest(constants.GET_REPORT, getReportData)
  yield takeLatest(constants.ADD_REPORT, addReportData)
  yield takeLatest(constants.EDIT_REPORT, editReportData)
  yield takeLatest(constants.DELETE_REPORT, deleteReport)
  yield takeLatest(constants.GET_CLIENT, getClientData)
  yield takeLatest(constants.GET_CLIENT_REPORT, getClientReportData)
  yield takeLatest(constants.SUBSCRIBE_REPORT, subscribeReportData)
  yield takeLatest(constants.GET_USER_SUBSCRIBED_REPORT, userSubscribeReportData)
  yield takeLatest(constants.EDIT_USER_SUBSCRIBED_REPORT, saveUserSubscription)
  yield takeLatest(constants.UNSUBSCRIBE_REPORT, unSubscribeReportData)
  yield takeLatest(constants.GET_CLIENT_DATA_FILTER, getClientDataFilter)
  yield takeLatest(constants.EDIT_CLIENT_DATA_FILTER, saveFilter)
  yield takeLatest(constants.EDIT_USER_DATA_FILTER, saveUserFilter)
  yield takeLatest(constants.GET_USER_DATA_FILTER, getUserDataFilter)
  yield takeLatest(constants.GET_CLIENT_USER_DATA, getClientUserListData)
  yield takeLatest(constants.ADD_CLIENT, addClientData)
  yield takeLatest(constants.EDIT_CLIENT, editClientData)
  yield takeLatest(constants.DELETE_CLIENT, deleteClient)
  yield takeLatest(constants.GET_CLIENT_SUBSCRIBED_REPORT, getSubscribedReports)
  yield takeLatest(constants.GET_DEFAULT_TEAM, getDefaultTeamData)
  yield takeLatest(constants.GET_CLIENT_RANKING_REPORT, getClientReportRankingData)
  yield takeLatest(constants.SAVE_CLIENT_RANKING_REPORT, saveClientReportRankingData)
  yield takeLatest(constants.ADD_DEFAULT_TEAM_USER, addDefaultUserData)
  yield takeLatest(constants.EDIT_DEFAULT_TEAM_USER, editDefaultUserData)
  yield takeLatest(constants.DELETE_DEFAULT_TEAM_USER, deleteDefaultUserData)
  yield takeLatest(constants.GET_USER_MGMT_LIST, getUserListData)
  yield takeLatest(constants.GET_ROLES_LIST, getRoleListData)
  yield takeLatest(constants.GET_USER_ROLE, getUserRoleData)
  yield takeLatest(constants.GET_AUTORIZATION_USER, getAuthorizationUser)
  yield takeLatest(constants.ADD_USER_ROLE_AUTORIZATION, addUserRoleAuthorization)
  yield takeLatest(constants.ADD_USER, addUser)
  yield takeLatest(constants.EDIT_USER, editUserData)
  yield takeLatest(constants.USER_RESET_PWD, resetPwdUser)
  yield takeLatest(constants.DELETE_USER, deleteUserData)
  yield takeLatest(constants.GET_VAULT_SECRET, getVaultSecret)
  yield takeLatest(constants.DELETE_VAULT_SECRET, deleteVaultSecret)
  yield takeLatest(constants.SAVE_VAULT_SECRET, saveVaultSecret)
  yield takeLatest(constants.GET_VAULT_HISTORY, getVaultHistory)
  yield takeLatest(constants.GET_USER_INFO, getUserInfo)
  yield takeLatest(constants.GET_CUSTOM_EMBED_FILTERS, getCustomEmbedFilters)
  yield takeLatest(constants.POST_CUSTOM_EMBED_FILTERS, saveCustomEmbedFilters)
}
export default function* rootSaga() {
  yield all([fork(midWatcher), fork(watcher)])
}