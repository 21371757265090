// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "section._3ri65 h1{color:#fff}", "",{"version":3,"sources":["main.scss"],"names":[],"mappings":"AAAA,kBACE,UAAc","file":"main.scss","sourcesContent":["section.mid-login-component h1 {\n  color: white; }\n"]}]);
// Exports
exports.locals = {
	"mid-login-component": "_3ri65"
};
module.exports = exports;
