import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import AppHeader from './components/AppHeader'
import SwipeableTemporaryDrawer from './components/SwipeableDrawer'
import CustomFooter from './components/Footer'
import { ADMIN_CONFIGURATION } from './constants'
import CustomRouter from './router'
import { getRoles } from './store/actions'

const BootUp = ({ store, history, role, mid }) => {
  useEffect(() => {
    const getRolesAsync = async () => {
      await store.dispatch(getRoles({ history }))
    }
    getRolesAsync()
  }, [history, store])

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }
  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  return (
    <>
          <AppHeader onSidebarOpen={handleSidebarOpen} role={role} roles={[ADMIN_CONFIGURATION]} />
          <SwipeableTemporaryDrawer open={openSidebar} mid={mid} onOpen={handleSidebarOpen} onClose={handleSidebarClose} />
          <CustomRouter store={store} role={role} mid={mid} history={history} />
          <CustomFooter />
          </>
  )
}

BootUp.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  role: PropTypes.any.isRequired,
  mid: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  role: state.reducer.role
})


export default connect(mapStateToProps)(BootUp)