import React, { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {getClientDetails, getTenants, setLoader } from '../../../store/actions';
import UILoader from "../../../components/ui-loader/UILoader";
import dateFormat from 'dateformat';
import { Styles, ActionBar } from './styles'
import {
  ThemeProvider,
  Table,
  Container,
  DropdownItem,
  DropdownButton,
  Radio,
  SIZE_SMALL,
  Badge,
  BADGE_TYPE_NEUTRAL_LIGHT,
  BADGE_TYPE_SUCCESS_LIGHT,
} from '@mds/mds-reactjs-library';
import {
  VAULT_SECRET_PATH,
  EDIT_CLIENT_PATH,
  EDIT_DATA_FILTER_PATH,
  SUBSCRIPTION_REPORTS_PATH
} from '../../../constants'


const DropdownButtonStyled = styled(DropdownButton)(() => ({
  marginLeft: 'auto',
  marginTop: '0.5rem'
}))

const CheckboxDiv = styled.div`
  justify-content: center;
  cursor: pointer;
  flex: 1;
  display: flex;
  margin-top: 10px;
`

const ClientTable = (props) => {
  const [ischecked, setIsChecked] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const history = props.history;
  const dispatch = useDispatch()
  let rowData = [];
  sessionStorage.setItem('fromUser', 0)
  sessionStorage.setItem('fromUserLevel', 0)

  useEffect(() => {
    dispatch(getClientDetails({history}))
    dispatch(getTenants({history}))
    dispatch(setLoader())
  }, []);

  let tenant_lookup = {}
  if (props.tenants && Array.isArray(props.tenants.tenants)) {
    tenant_lookup = props.tenants.tenants.reduce(function (acc, cur) {
      let id = cur.schemaName
      acc[id] = cur.name;
      return acc;
    }, {});
  }

  const RadioButtonChecked = (args) => {
    setIsChecked(true)
    setSelectedRow({...selectedRow, data: args });
    props.selectClient(args?.rowData)
  }

  const goToEditClient = () => {
    props.history.push({
      pathname: EDIT_CLIENT_PATH,
      state: selectedRow
    });
  }

  const goToClientFilter = () => {
    props.history.push({
      pathname: EDIT_DATA_FILTER_PATH,
    });
  }

  const goToReportAccess = () => {
    props.history.push({
      pathname: SUBSCRIPTION_REPORTS_PATH
    })
  }


  const goToSecret = () => {
    props.history.push({
      pathname: VAULT_SECRET_PATH,
      state: selectedRow
    });
  }

  const CheckboxCellRenderer = (args) => {
    const { value } = args

    return (
      <CheckboxDiv>
        <Radio
          size={SIZE_SMALL}
          onChange={() => RadioButtonChecked(args)}
          checked={value}
          value="small"
          label="Small radio"
          name="RadioButtonSizeExample"
        />
      </CheckboxDiv>
    )
  }

  const BadgeRenderer = (args) => {
    const { value } = args
    return (
    value ? <Badge type={ BADGE_TYPE_SUCCESS_LIGHT}>Required</Badge> :
    <Badge type={BADGE_TYPE_NEUTRAL_LIGHT}>Not Required</Badge>)
  }

  const column = React.useMemo(
    () => [
      {
        dataKey: 'checked',
        label: '',
        width: 40,
        CellRenderer: CheckboxCellRenderer,
      },
      {
        dataKey: 'tenant_name', label: 'Tenant Name',width: 150},
      { dataKey: 'domo_client_name', label: 'Domo Client Name', width: 200},
      { dataKey: 'is_pdp_enabled', label: 'PDP Requirement', type: 'boolean', width: 100, CellRenderer: BadgeRenderer},
      { dataKey: 'org_domains', label: 'Org Domain', width: 200},
      { dataKey: 'updated_by', label: 'Updated by',width: 120},
      { dataKey: 'updated_at', label: 'Updated at',width: 150},
      { dataKey: 'created_by', label: 'Created by',width: 120},
      { dataKey: 'created_at', label: 'Created at',width: 150},
    ],
    []
  )


  if (props.clients && Array.isArray(props.clients.clients)) {
    rowData = props.clients && props.clients.clients.map((row) => {
      return {
        "id": row.id,
        "tenant_id": row.tenant_id,
        "tenant_name": tenant_lookup[row.tenant_id],
        "domo_client_name": row.domo_client_name,
        "is_pdp_enabled": row.is_pdp_enabled,
        "org_domains": row.org_domains,
        "clientData": props && props.cardData,
        "created_by": row.created_by,
        "created_at": dateFormat(row.created_at,"mmmm dS, yyyy"),
        "updated_by": row.updated_by,
        "updated_at": dateFormat(row.updated_at,"mmmm dS, yyyy"),
      }
    })
  }

  return (
      <UILoader>
            <Styles>
            <ThemeProvider>
          <Container maxWidth="xl">
            <ActionBar>
              <DropdownButtonStyled disabled={!ischecked} label="Actions">
                <DropdownItem id="edit-client-item" onClick={goToEditClient}> Edit Client</DropdownItem>
                {props?.selectedClient?.tenant_name !== "Default Tenant" ? <DropdownItem  id="report-subscription-item" onClick={goToReportAccess}> Report Subscription</DropdownItem> : <></>}
                {props?.selectedClient?.tenant_name !== "Default Tenant" ? <DropdownItem id="manage-data-filters-item"  onClick={goToClientFilter}> Manage Data Filters</DropdownItem> : <></>}
                <DropdownItem id="data-policy-item" onClick={goToSecret}>Data Policy</DropdownItem>
              </DropdownButtonStyled>
            </ActionBar>
            <Table height={550} dark striped columns={column} rows={rowData.sort(( a, b) => {return a && a.tenant_name && a.tenant_name.localeCompare(b.tenant_name)})} width={1200}/>
          </Container>
        </ThemeProvider>
      </Styles>
      </UILoader>
  )
}

ClientTable.propTypes = {
  tenants: PropTypes.object,
  history: PropTypes.object,
  clients: PropTypes.object,
  cardData: PropTypes.array,
  selectedClient: PropTypes.object,
  selectClient: PropTypes.func
}

ClientTable.defaultProps = {
  tenants: {},
  history: {},
  clients: {},
  cardData: [],
  selectedClient: {},
  selectClient: () => { },
}

const mapStateToProps = (state) => ({
  clients: state.reducer.clients,
  tenants: state.reducer.tenants,
  cardData: state.reducer.cardData,
  loading: state.reducer.loading,
  selectedClient: state.reducer.selectedClient
})


export default connect(mapStateToProps)(ClientTable)