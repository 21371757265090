import styled from 'styled-components'
import mck_academy_login from '../../../assets/img/mck_academy_login.png'


const HeroStyles = styled.div`
height: 90vh;
background: linear-gradient(135deg,#051C2C,#2251FF);
overflow: hidden;
padding: 10px 10px;
position: relative;
width: 100%;
`

const HeroBodyStyles = styled.div `
position: absolute;
top: 30%;
left: 50%;
transform: translate(-50%, -50%);
color: black;
background-color: rgb(255,255,255);
text-align: center;
padding: 3rem 6rem;
z-index: 1;
@media only screen and (max-width:900px) { {
    position: absolute;
    top: 70%;
    padding: 3rem;
  }
`

const HeroBodyStylesMobile = styled.div `
text-align: center;
`

const ImageStyles = styled.div `
position: absolute;
top: -38%;
left: 20%;
width: 83%;
height: 60rem;
background-image: url(${mck_academy_login});
background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (max-width:900px) { {
    position: relative;
    top: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: bottom;
    left: 0;
  }
`

export {ImageStyles, HeroStyles, HeroBodyStyles, HeroBodyStylesMobile};