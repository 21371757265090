import * as constants from './constants'

const initialState = {
    text: '',
    color: ''
}

const initialFetching = {
    isFetching: false
}

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALERT:
            return action.msg

        case constants.RESET_ALERT:
            return initialState

        default:
            return state
    }
}

export const loaderReducer = (state = initialFetching, action) => {
    switch (action.type) {
        case constants.SET_LOADER:
            return action.isLoading;
        case constants.HIDE_LOADER:
            return action.isLoading
        default:
            return state
    }
}