import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { actions as midActions } from '@nvt/mid-react';
import UserTable from '../../../user-management/user-management-table/user-management-table';
import PropTypes from 'prop-types'
import { setLoader, getClientUserList, setSelectedUser } from '../../../../store/actions';


const ManageUserFilter = (props) => {
  let history = props.history;
  const dispatch = useDispatch();
  sessionStorage.setItem('fromUser', 1)
  sessionStorage.setItem('fromUserLevel', 1)

  useEffect(() => {
    let selectedClient = props?.selectedClient ? props?.selectedClient : JSON.parse(sessionStorage.getItem('selectedClient'))
    sessionStorage.setItem('selectedClient', JSON.stringify(selectedClient))
    let id = selectedClient?.tenant_id
    dispatch(getClientUserList(id,history))
    dispatch(setLoader());
  }, []);

  const handleSelectedUser=(data)=>{
    dispatch(setSelectedUser(data))
  }

  return (
    <>
    {!props.fromSubscription && <UserTable history={history} selectRecord={handleSelectedUser} fromFilter={true}></UserTable>
    }
    {props.fromSubscription && <UserTable history={history} selectRecord={handleSelectedUser} fromSubscription={true}></UserTable>
    }
    </>
  )
}

ManageUserFilter.propTypes = {
    history: PropTypes.object
  }

  ManageUserFilter.defaultProps = {
    history: {}
  }

  const mapStateToProps = (state) => ({
    mid: state.mid,
    role: state.reducer.role,
    selectedClient: state.reducer.selectedClient
  })

  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout()),
  })

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageUserFilter))