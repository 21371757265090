import React from 'react'
import { connect } from 'react-redux'
import { resetAlertAction } from '../../store/actions'
import {
  ThemeProvider,
  Alert,
  getAlertUniqueId,
} from '@mds/mds-reactjs-library';

const UIAlert = props => {
  const { alert, dispatch } = props;
    const { text, type } = alert
    
    setTimeout(() => dispatch(resetAlertAction()), 3000)


      if (text === '') {
        return <></>
    }

    return (
      <ThemeProvider>
     <Alert id={getAlertUniqueId()} type={type} showCloseIcon={false} style={{ marginBottom: '12px' }}>
        {text}
      </Alert>
    </ThemeProvider>

    )
}

const mapStateToProps = state => {
    return {
        alert: state.alert
    }
}

export default connect(mapStateToProps)(UIAlert)