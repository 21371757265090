import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux'
import { actions as midActions } from '@nvt/mid-react';
import BackButton from '../../../components/back-button/BackButton';
import { Container, ThemeProvider, Tabs, Tab } from '@mds/mds-reactjs-library';
import SideNav from '../../../components/SideNavBar';
import EditDataFilter from '../components/edit-data-filter/EditDataFilter';
// import UserTable from '../../user-management/user-management-table/user-management-table';
import UIAlert from '../../../components/ui-alert/UIAlert';
import PropTypes from 'prop-types'
import ManageUserFilter from '../components/manage-user-filter/ManageUserFilter';
import { setLoader, getDataFilter } from '../../../store/actions';


const DIV = styled.div`
display: flex;
@media only screen and (max-width: 767px) { {
  flex-direction: column;
}
`;

const HeadingReportName = styled.h2`
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
`;

const ClientTableDiv = styled.div`
@media only screen and (max-width: 767px) { {
  margin-top: 50px;
}
`;

const DataFilterManagement = (props) => {
  const { logout, role, history} = props;
  let activeTab = +sessionStorage.getItem('fromUser');
  const dispatch = useDispatch();
  const { useEffect } = React;
  let tenant_name = props?.selectedClient?.tenant_name ? props?.selectedClient?.tenant_name : JSON.parse(sessionStorage.getItem('selectedClient'))?.tenant_name;

  useEffect(() => {
    let selectedClient = props?.selectedClient ? props?.selectedClient : JSON.parse(sessionStorage.getItem('selectedClient'))
    sessionStorage.setItem('selectedClient', JSON.stringify(selectedClient))
    let id = selectedClient?.tenant_id
    dispatch(getDataFilter(id,history));
    dispatch(setLoader());
  }, []);


  return (
    <DIV>
        <SideNav logout={logout} role={role} />
        <Container maxWidth="xl">
            <ThemeProvider>
            <BackButton text="Return to Client Configuration" />
                <DIV>
                <HeadingReportName>Manage {tenant_name}</HeadingReportName>
                </DIV>
              <Tabs selectedValue={activeTab}>
                <Tab label="Manage Client Data" disabled={props?.loader && activeTab !== 0}>
                    <ClientTableDiv>
                    <UIAlert />
                    <EditDataFilter history={history}></EditDataFilter>
                    </ClientTableDiv>
                </Tab>
                <Tab label="Manage User Data" disabled={props?.loader && activeTab !== 1}>
                    <ClientTableDiv>
                    <UIAlert />
                      <ManageUserFilter history={history}></ManageUserFilter>
                    </ClientTableDiv>
                </Tab>
              </Tabs>

            </ThemeProvider>
          </Container>
      </DIV>
  );
  }

  DataFilterManagement.propTypes = {
    history: PropTypes.object,
    logout: PropTypes.func,
    role: PropTypes.string
  }

  DataFilterManagement.defaultProps = {
    history: {},
    logout: () => { },
    role: ""
  }

  const mapStateToProps = (state) => ({
    offers: state.reducer.offers,
    mid: state.mid,
    role: state.reducer.role,
    loader: state.loader,
    selectedClient: state.reducer.selectedClient
  })

  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(midActions.logout()),
  })

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataFilterManagement))
