import { Alert, Container, getAlertUniqueId, ThemeProvider, WARNING_ALERT_CONTEXTUAL } from '@mds/mds-reactjs-library';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ErrorPage = (props) => {
  return (
    <ThemeProvider>
      <Container maxWidth="lg">
      <Alert id={getAlertUniqueId()} showCloseIcon={false} type={WARNING_ALERT_CONTEXTUAL} style={{ margin: '12px' }}>
      <div>{props?.apiError.apiError ? props.apiError.message : "It looks like you're not yet authorized to access this application. Please contact support: mcka-support-portal@mckinsey.com"}</div>
      </Alert>
      </Container>
      </ThemeProvider>
  );
};

ErrorPage.propTypes = {}

ErrorPage.defaultProps = {}

const mapStateToProps = (state) => ({
  apiError: state.reducer.apiError
});


export default withRouter(connect(mapStateToProps)(ErrorPage))