// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._33mX7 ._1ipxH{color:#fff}", "",{"version":3,"sources":["remove-secret-modal.css"],"names":[],"mappings":"AAAA,gBACE,UAAa","file":"remove-secret-modal.css","sourcesContent":[".remove-modal .mds-modal-body {\n  color: #fff; }\n"]}]);
// Exports
exports.locals = {
	"remove-modal": "_33mX7",
	"mds-modal-body": "_1ipxH"
};
module.exports = exports;
