// import { MdDragHandle } from "react-icons/md"

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import { useHistory } from 'react-router-dom';
import React from "react";
import styled from 'styled-components';
import {useState} from "react";
import {
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON
  } from '@mds/mds-reactjs-library';
  import { connect, useDispatch } from 'react-redux'
  import { saveClientReportRanking, getClientReportRanking, setLoader, hideLoader } from '../../../../store/actions';

const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
   float: right;
  `;
  const ButtonStyle = styled(Button)`
  margin: 20px 0 20px 0;
  float: right;
 `;
 const Spacer = styled.tr`
    width: 100%;
    height: 10px;
`;

const BoxSized = styled.tr`
    width: 100%;
    height: 70px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const Item = styled.td`
padding: 25px 8px 8px 8px; 
borderBottom : none
`

const DragDrop = (props) => {
    const dispatch = useDispatch();
    const { useEffect } = React;
    const [reports, setReport] = useState([]);
    const history = useHistory();
    
    let selectedClient = props?.selectedClient ? props?.selectedClient : JSON.parse(sessionStorage.getItem('selectedClient'))
    
    useEffect(() => {
        let id = selectedClient.tenant_id;
        dispatch(getClientReportRanking(id));
        dispatch(setLoader());  
      }, []);

    useEffect(() => {
        dispatch(hideLoader());
        if (props.reportRanking && Array.isArray(props.reportRanking.clientReports)){  
            setReport(props.reportRanking.clientReports)
         }
       }, [props.reportRanking]); 
    const handleDragEnd = (results) => {
        if(!results.destination) return
        let tempUser = [...reports]
        let [selectedRow] = tempUser.splice(results.source.index, 1)
        tempUser.splice(results.destination.index, 0, selectedRow)
        setReport(tempUser)
    }

    const handleSave = () => {
        let clientId = props?.selectedClient ? props?.selectedClient.tenant_id : JSON.parse(sessionStorage.getItem('selectedClient'))?.tenant_id
       let report = reports.map((item, index) => {
            Object.assign(item, item.report_rank=index+1) 
            return item
        })
        let payload = {history: history,client: report}
        dispatch(saveClientReportRanking(payload, clientId))
       props.onCancel();
    }
    

    return (
        <>
        {props.reportRanking && <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
        <table className="table">
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Report Name</th>
                    <th>Domo Report Name</th>
                
                </tr>
                <Spacer />
            </thead>
            <Droppable droppableId="tbody">
                {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {props.reportRanking && reports.map((report, index) => (
                    <Draggable draggableId={report.global_report_name} key={report.global_report_name} index={index}>
                    {
                        (provides) => (
                        <>
                        <BoxSized ref={provides.innerRef} {...provides.draggableProps} {...provides.dragHandleProps}>
                        <Item style={{"padding": "25px 8px 8px 8px", "borderBottom" : "none"}}>{index < 10 && 0}{index+1} {index === 0 && "(Default)"}</Item>
                        <Item style={{"padding": "25px 8px 8px 8px", "borderBottom" : "none"}}>{report.global_report_name}</Item>
                        <Item style={{"padding": "25px 8px 8px 8px", "borderBottom" : "none"}}>{report.domo_report_name}</Item></BoxSized>
                        <Spacer /></>
                        

                        )
                    }
                    
                    </Draggable>
                ))

                }
            
            </tbody>
                )}
            </Droppable>
        </table>
        </DragDropContext>}
        <ButtonStyle appearance={SECONDARY_BUTTON} onClick={props.onCancel}>Cancel</ButtonStyle>
        <ButtonStyled id='edit-client-save-btn' appearance={PRIMARY_BUTTON} onClick={handleSave}>Save</ButtonStyled>
        
        </>
    )
}
  
  const mapStateToProps = (state) => ({
      selectedClient: state.reducer.selectedClient,
      reportRanking: state.reducer.reportRanking,
  })
  
  export default connect(mapStateToProps)(DragDrop)
  
