import React from 'react'
import CustomToggle from '../../../components/custom-toggle/CustomToggle';
import styled from 'styled-components'
import PropTypes from 'prop-types';
// Table
import {
  Table,
  Tooltip
} from '@mds/mds-reactjs-library'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const TableStyled = styled(Table)`
  padding-bottom: 10px;
`;

const CustomTable = ({offerId , data, handleConfig}) => {

    const column = React.useMemo(
        () => [
            { dataKey: 'program', label: 'Program', width: 400},
            { dataKey: 'isChecked', label: 'Permission', width: 700},
          ],
        []
      )

      let rowData=[]
      if (data && Array.isArray(data)){
        rowData = data.map((row, i) => {
          return {
            "id": row.id,
            "program": row.name,
            "isChecked": <Tooltip content={(row.enabled) ? "Enabled" : "Disabled"}><FormControlLabel
            value="top"
            control={<CustomToggle
              key={i}
              disabled={row.locked}
              checked={row.enabled}
              onChange={(event) => handleConfig(event, offerId, i)}
              name="permission"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              />}
            label=""
            labelPlacement="start"
            /></Tooltip>
          }})
        } 
        return (
        <TableStyled width={1100} dark striped columns={column} rows={rowData.sort(( a, b) => {return a.program.localeCompare(b.program)})} />
        )  
}

CustomTable.propTypes = {
  offerId: PropTypes.number,
  data: PropTypes.array,
  handleConfig: PropTypes.func
}

CustomTable.defaultProps = {
  offerId: 0,
  data: [],
  handleConfig: () => { },
}

export default CustomTable