import styled from 'styled-components'

const LoaderContainer = styled.div`
display: block;
position: absolute;
z-index: 1031;
top: 50%;
left: 50%;
`;

export { LoaderContainer }
