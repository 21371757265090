import styled from 'styled-components'

const Styles = styled.div`
  padding-top: 3rem;

  .user {
    background-color: blue;
    color: white;
  }

  table {
    border-spacing: 0;

    th {
      background: #051c2c;
      z-index: 0;
      color: #ffffff;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px grey;

      :last-child {
        border-right: 0;
      }
    }
  }
  /* Collection default theme */

  .ReactVirtualized__Collection {
  }

  .ReactVirtualized__Collection__innerScrollContainer {
  }

  /* Grid default theme */

  .ReactVirtualized__Grid {
  }

  .ReactVirtualized__Grid__innerScrollContainer {
  }

  /* Table default theme */

  .ReactVirtualized__Table {
  }

  .ReactVirtualized__Table__Grid {
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: 700 !important;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    // margin-right: 10px;
    min-width: 0px;
  }
  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    // margin-left: 10px;
  }
  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
  }

  .ReactVirtualized__Table__sortableHeaderIconContainer {
    display: flex;
    align-items: center;
  }
  .ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  /* List default theme */

  .ReactVirtualized__List {
  }
`

const ActionBar = styled.div`
display: flex;
`
export {Styles, ActionBar};