import React from 'react';
import { ThemeProvider, InfoModal, INFO_MODAL, SECONDARY_BUTTON } from '@mds/mds-reactjs-library';
import './RemoveReport.css';



function RemoveReportDialog ({onOk, onCancel}){
  return (
    <ThemeProvider >
      <InfoModal
        id='remove-report-modal'
        type={INFO_MODAL}
        className="remove-modal"
        // style={{backgroundColor:'#ef4f4f',color:'#fff'}}
        // header="Informational message"
        body="Are you sure that you want to remove Record, you can't undo once removed."
        okButton={{
          label: 'Yes, Remove',
          action: onOk
        }}
        cancelButton={{
            label: 'No, Cancel',
            action: onCancel,
            appearance: SECONDARY_BUTTON
          }}
          onClose={onCancel}
      />
    </ThemeProvider>
  );
}
export default RemoveReportDialog;