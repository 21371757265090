import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import TableToolbar from './TableToolbar'
import { setLoader } from '../../../store/actions'
import styled from 'styled-components'
import { Styles, ActionBar } from './styles'
import PropTypes from 'prop-types';
import {
  EDIT_REPORTS_PATH,
} from '../../../constants'

// Table
import {
  ThemeProvider,
  Table,
  Container,
  DropdownItem,
  DropdownButton,
  Radio,
  SIZE_SMALL,
  Badge,
  BADGE_TYPE_NEUTRAL_LIGHT,
  BADGE_TYPE_SUCCESS_LIGHT,
} from '@mds/mds-reactjs-library'
import UILoader from '../../../components/ui-loader/UILoader'
import dateFormat from 'dateformat'
import RemoveReportDialog from '../actions-buttons/remove-report/remove-report-modal'

const DropdownButtonStyled = styled(DropdownButton)(() => ({
  marginLeft: 'auto',
  marginTop: '0.5rem'
}))

const CheckboxDiv = styled.div`
  justify-content: center;
  cursor: pointer;
  flex: 1;
  display: flex;
  margin-top: 10px;
`


const ReportTable = (props) => {
  const [ischecked, setIsChecked] = useState(false)
  const [removeDialog, showRemoveDialog]=useState(false)
  const dispatch = useDispatch()
  const { useEffect } = React
  useEffect(() => {
    dispatch(setLoader())
  }, [])

  const RadioButtonChecked = (args) => {
    const { rowData } = args
    setIsChecked(true)
    props.selectRecord(rowData)
  }

  const CheckboxCellRenderer = (args) => {
    const { value } = args

    return (
      <CheckboxDiv>
        <Radio
          size={SIZE_SMALL}
          onChange={() => RadioButtonChecked(args)}
          checked={value}
          value="small"
          label="Small radio"
          name="RadioButtonSizeExample"
        />
      </CheckboxDiv>
    )
  }

  const BadgeRenderer = (args) => {
    const { value } = args
    return (
    value ? <Badge type={ BADGE_TYPE_SUCCESS_LIGHT}>Visible</Badge> :
    <Badge type={BADGE_TYPE_NEUTRAL_LIGHT}>Not Visible</Badge>)
  }

  const column = React.useMemo(
    () => [
      {
        dataKey: 'checked',
        label: '',
        width: 40,
        CellRenderer: CheckboxCellRenderer,
      },
      { dataKey: 'global_report_name', label: 'Report Name', width: 150 },
      { dataKey: 'domo_report_name', label: 'Domo Report Name', width: 200 },
      { dataKey: 'domo_embed_id', label: 'Domo Embed Id', width: 150 },
      { dataKey: 'report_state', label: 'Visibility', width: 100,  CellRenderer: BadgeRenderer },
      { dataKey: 'metadata_program', label: 'Info', width: 95 },
      { dataKey: 'updated_by', label: 'Updated by', width: 130 },
      { dataKey: 'updated_at', label: 'Updated at', width: 150 },
      { dataKey: 'created_by', label: 'Created by', width: 130 },
      { dataKey: 'created_at', label: 'Created at', width: 150 },
      { dataKey: 'subscriptionscount', label: 'Subscriber count', width: 130 },
    ],
    [],
  )
  let rowData = []
  if (Array.isArray(props.reports)) {
    rowData =
      props &&
      props.reports.map((row) => {
        return {
          id: row.id,
          global_report_name: row.global_report_name,
          domo_report_name: row.domo_report_name,
          domo_embed_id: row.domo_embed_id,
          report_state: row.is_visible,
          created_by: row.created_by,
          created_at: dateFormat(row.created_at, 'mmmm dS, yyyy'),
          updated_by: row.updated_by,
          updated_at: dateFormat(row.updated_at, 'mmmm dS, yyyy'),
          metadata_program: row.metadata_program,
          subscriptionscount:row.subscriptionscount, 
          custom_dataset_id:row.custom_dataset_id 
        }
      })
  }
  const handleRemoveOk =()=>{
    props.removeReport();
    showRemoveDialog(false)
    // setIsChecked(false)
  }

  return (
    <Styles>
      <UILoader>
        <ThemeProvider>
          <Container >
            <ActionBar>
              <TableToolbar history={props.history} />
              <DropdownButtonStyled disabled={!ischecked} label="Actions">
                <DropdownItem onClick={() => props.history.push(EDIT_REPORTS_PATH)}> Edit Report</DropdownItem>
                <DropdownItem onClick={() => { showRemoveDialog(true) }}> Remove Report </DropdownItem>

              </DropdownButtonStyled>
            </ActionBar>
            <Table dark height={550} striped columns={column} rows={rowData.sort(( a, b) => {return a.global_report_name.localeCompare(b.global_report_name)})}/>
          </Container>

          {removeDialog ?(<RemoveReportDialog  onOk={()=> handleRemoveOk()} onCancel={()=>showRemoveDialog(false)} />):null}

        </ThemeProvider>
      </UILoader>
    </Styles>
  )
}

ReportTable.propTypes = {
  history: PropTypes.object,
  reports: PropTypes.array,
}

ReportTable.defaultProps = {
  history: {},
  reports: [],
}

const mapStateToProps = (state) => ({
  reports: state.reducer.reports,
})


export default connect(mapStateToProps)(ReportTable)