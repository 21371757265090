import React from 'react';
import styled from '@emotion/styled';
import { VerticalMenu, ThemeProvider } from '@mds/mds-reactjs-library';

import { ADMIN_CONFIGURATION, ADMIN_USER_MANAGEMENT } from '../../constants'
import appConfig from '../../config';
import { useHistory, useLocation } from 'react-router-dom';

const VerticalMenuStyled = styled(VerticalMenu)(() => (
    {
        margin: '0px',
        width: '256px',
        maxWidth: '256px',
        height: '100vh',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        top: '80px',
        bottom: '0px',
        left: '0px',
        transform: 'translateX(0px)',
        transition: 'none 0s ease 0s',
        // zIndex: '1',
        padding: '0px !important',
        // '@media(max-width: 768px)': {
        //   display: 'none'
        // },
        '@media only screen and (max-width: 767px)': {
            display: 'none'
        },
    }
));

const DemoComponent = ({ role }) => {

    // Get current location
    const location = useLocation();
    const history = useHistory();
    // Keep track of activeId
    const [id, setId] = React.useState(location.pathname);
    // Set updated location
    React.useEffect(() => {
        if (location.pathname === "/admin/report") {
            setId("Report Management");
        }
        else if (location.pathname === "/admin/client") {
            setId("Client Configuration");
        }
        else if (location.pathname === "/admin/team") {
            setId("Core Team");
        }
        else if (location.pathname === "/admin/user") {
            setId("User Management");
        }
    }, [location]);

    // Just to have an opportunity in this example to switch active menu items




    const itemsProcessing = items => {
        return items.map(item => {
            const { itemsList } = item;
            const resultItem = { ...item };
            resultItem.active = (id === null && resultItem.active) || resultItem.id === id;
            resultItem.onClick = (event, params) => {
                setId(params.id);
                switch (event.target.innerText) {
                    case 'Report Management':
                        return history.push('/admin/report');
                    case 'Client Configuration':
                        return history.push('/admin/client');
                    case 'Core Team':
                        return history.push('/admin/team');
                    case 'User Management':
                        return history.push('/admin/user');
                    case 'Tenant Management':
                        return window.open(appConfig.REQUEST_NEW_TENANT_LINK, '_blank');
                    default:
                        return '/reports';
                }
            };
            if (Array.isArray(itemsList)) {
                resultItem.items = itemsProcessing(itemsList);
            }
            return resultItem;
        });
    };

    const items = [];

    if (role === ADMIN_CONFIGURATION) {
        items.push({
            id: 'Report Management',
            label: 'Report Management',
            divider: true
        })
        items.push({
            id: 'Client Configuration',
            label: 'Client Configuration',
            divider: true
        })
        items.push({
            id: 'Core Team',
            label: 'Core Team',
            divider: true
        })
    }
    if (role === ADMIN_CONFIGURATION || role === ADMIN_USER_MANAGEMENT) {
        items.push({
            id: 'User Management',
            label: 'User Management',
            divider: true
        })
    }
    if (role === ADMIN_CONFIGURATION) {
        items.push({
            id: 'Tenant Management',
            label: 'Tenant Management',
            divider: true
        })
    }
    const customTheme = {
        verticalMenu: {
            "border-bottom": "1px solid #E6E6E6",
            menu: {
                'line-height': '1'
            },
            color: {
                border: {
                    default: '#e6e6e6',
                    active: '#2251ff',
                    focus: '#333333',
                    divider: '#e6e6e6',
                },
                background: {
                    default: '#ffffff',
                    active: '#e6e6e6',
                    'sub-menu-1': '#fafafa',
                    'sub-menu-2': '#f5f5f5',
                    hover: '#f0f0f0',
                },
                text: {
                    default: '#333333',
                    'search-placeholder': '#757575',
                    active: '#333333',
                    hover: '#333333',
                    disabled: '#b3b3b3',
                },
            },
        },
    };
    return (
        <>
            <ThemeProvider customTheme={customTheme}>
                <VerticalMenuStyled items={itemsProcessing(items)} />
            </ThemeProvider>
        </>
    );
}

export default DemoComponent;