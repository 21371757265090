import React , { useState , useEffect }from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, connect } from 'react-redux';
import { Styles } from './styles';
import BackButton from '../../../../components/back-button/BackButton';
import UILoader from '../../../../components/ui-loader/UILoader';
import PropTypes from 'prop-types'
import {
    ThemeProvider,
    Container,
    Button,
    PRIMARY_BUTTON,
    SECONDARY_BUTTON,
    FormElementWrapper,
    Input
  } from '@mds/mds-reactjs-library';

  import { editUser, hideLoader, setLoader } from '../../../../store/actions';

  const FreeSpaceHeading = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    margin-top: 20px;
  `;
  const FreeSpaceBackground = styled.div`
    width: 100%;
    background-color: #F5F5F5;
  `;
  const HeadingClientName = styled.h3`
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: 'McKinsey Sans Medium', 'Helvetica Neue', Calibri, Corbel, Helvetica, Roboto, Droid, sans-serif;
  color: #000;
  margin: 16px 0 16px 20px;
 `;
  const DIV = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) { {
  flex-direction: column;
  }
  `;
  const FormElementWrapperStyled = styled(FormElementWrapper)`
  .mds-input {
    width: 500px;
  }
 `;
 const FormElementWrapperStyle = styled(FormElementWrapper)`
  .mds-input {
    width: 400px;
  }
 `;
  const ButtonStyled = styled(Button)`
   margin: 20px 20px 20px 0;
  `;
  const InputStyledContainer = styled.div`
  width: 600px;
  margin: 40px 0 40px 20px;
  `;
  const InputStyledCntr = styled.div`
      width: 400px;
      @media (min-width: 768px) {
        margin: 60px 0 0 20px;
      }
      @media (max-width: 767px) {
        margin: 40px 0 40px 20px;
      }
  `;
  const DescriptionSpan = styled.div`
    font-size: 16px;
    font-style: italic;
    margin: -10px 0 10px 20px;
  `;

  
  const EditUser = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const { state } = useLocation();
    //let username = state.user_name.split(" ")

    const [first_name, setFirstName] = useState(state.first_name)
    const [last_name, setLastName] = useState(state.last_name)
    const [email, setEmail] = useState(state.email);
    const [isValid,setIsValid] = useState(false);
    
    useEffect(() => {
      dispatch(hideLoader())
      if(first_name!=='' 
            && first_name.length > 0
            && last_name!=='' 
            && last_name.length > 0 
            && email !==''
            && email.length > 0
            && email.indexOf('@') > -1
            ){
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    },[first_name,last_name,email]);

    const handleEdit = () => {
      let id = state.id
      let editUsr = {history,id, userData:{
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
      }}
      dispatch(setLoader())
      dispatch(editUser(editUsr))
    }

  return (
    <Styles>
    <UILoader>
      <ThemeProvider>
        <Container>
          <BackButton id="edit-user-back-btn" text="Return to User Management" />
          <FreeSpaceHeading>
            <Container>
            <DIV><HeadingClientName>Edit {state.first_name} {state.last_name}</HeadingClientName></DIV>
            <DIV><DescriptionSpan>All fields are required.</DescriptionSpan></DIV>
            </Container>
          </FreeSpaceHeading>
          <FreeSpaceBackground>
              <Container>
              <DIV>
              <InputStyledCntr>    
                <FormElementWrapperStyle
                  component={Input}
                  label="First Name"
                  inputProps={{
                      placeholder: 'Enter First Name',
                      name: 'first_name',
                      value: first_name,
                      onChange: e => setFirstName(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                      return !val || val.length < 1 ? 'Please enter the first name' : '';
                  }}
                />
              </InputStyledCntr>
              <InputStyledCntr>    
                <FormElementWrapperStyle
                  component={Input}
                  label="Last Name"
                  inputProps={{
                      placeholder: 'Enter Last Name',
                      name: 'last_name',
                      value: last_name,
                      onChange: e => setLastName(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                      return !val || val.length < 1 ? 'Please enter the last name' : '';
                  }}
                />
              </InputStyledCntr>
              </DIV>
              <InputStyledContainer>    
              <FormElementWrapperStyled
                  component={Input}
                  label="Email"
                  disabled
                  inputProps={{
                      placeholder: 'Enter Email',
                      name: 'email',
                      value: email,
                      onChange: e => setEmail(e.target.value),
                      htmlSize: 30
                  }}
                  validationFunc={val => {
                      return (!val || val.length < 1) ? 'Please enter the email' : val.indexOf('@') === -1 ? 'Please enter the valid email id' : '';
                  }}
                />
              </InputStyledContainer>
              </Container>
          </FreeSpaceBackground>
          <ButtonStyled id="user-edit-save-btn" appearance={PRIMARY_BUTTON} onClick={handleEdit} disabled={!isValid}>Save</ButtonStyled>
          <ButtonStyled appearance={SECONDARY_BUTTON} onClick={() => history.push(`/admin/user`)}>Cancel</ButtonStyled>
        </Container>
      </ThemeProvider>
    </UILoader>
  </Styles>
  );
}

EditUser.propTypes = {
  history: PropTypes.object
}

EditUser.defaultProps = {
  history: {}
}

const mapStateToProps = (state) => ({
  editStatus: state.reducer.editStatus
})

export default connect(mapStateToProps)(EditUser);
